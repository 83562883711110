//This file holds most of the icons used in the dashboard.

import { SvgIcon, useTheme } from '@mui/material'
import React from 'react'

//these are the icons for Home screen
const TotalUserIcon = (props) => {
  const theme = useTheme()
  return (
    <SvgIcon {...props} viewBox="0 0 49 49">
      <g id="icon">
        <rect
          id="Rectangle 56"
          x="0.5"
          y="0.5"
          width="48"
          height="48"
          rx="24"
          fill={theme.tokens.secondary}
        />
        <g id="Vector">
          <path
            d="M29.0412 12.9051C28.015 11.7971 26.5817 11.187 24.9997 11.187C23.4092 11.187 21.9711 11.7935 20.9497 12.8946C19.9171 14.0078 19.4141 15.5207 19.5322 17.1544C19.7663 20.3776 22.219 22.9995 24.9997 22.9995C27.7804 22.9995 30.2288 20.3781 30.4667 17.1555C30.5864 15.5365 30.0801 14.0268 29.0412 12.9051Z"
            fill={theme.tokens.surfaceBright}
          />
          <path
            d="M34.2811 34.8109H15.7186C15.4756 34.814 15.235 34.763 15.0142 34.6614C14.7935 34.5599 14.5981 34.4104 14.4424 34.2239C14.0996 33.8142 13.9615 33.2547 14.0638 32.6888C14.5088 30.2198 15.8979 28.1458 18.0811 26.6898C20.0206 25.3972 22.4775 24.6859 24.9998 24.6859C27.5221 24.6859 29.979 25.3978 31.9186 26.6898C34.1018 28.1452 35.4908 30.2193 35.9359 32.6883C36.0382 33.2541 35.9 33.8136 35.5572 34.2234C35.4016 34.41 35.2062 34.5596 34.9855 34.6612C34.7647 34.7628 34.5241 34.8139 34.2811 34.8109Z"
            fill={theme.tokens.surfaceBright}
          />
        </g>
      </g>
    </SvgIcon>
  )
}

const TotalConsumerIcon = (props) => {
  const theme = useTheme()
  return (
    <SvgIcon {...props}>
      <g id="icon">
        <rect
          id="Rectangle 57"
          y="0.5"
          width="48"
          height="48"
          rx="24"
          fill={theme.tokens.customColorSignal}
        />
        <g id="Vector">
          <path
            d="M28.5412 12.9051C27.515 11.7971 26.0817 11.187 24.4997 11.187C22.9092 11.187 21.4711 11.7935 20.4497 12.8946C19.4171 14.0078 18.9141 15.5207 19.0322 17.1544C19.2663 20.3776 21.719 22.9995 24.4997 22.9995C27.2804 22.9995 29.7288 20.3781 29.9667 17.1555C30.0864 15.5365 29.5801 14.0268 28.5412 12.9051Z"
            fill={theme.tokens.surfaceBright}
          />
          <path
            d="M33.7811 34.8109H15.2186C14.9756 34.814 14.735 34.763 14.5142 34.6614C14.2935 34.5599 14.0981 34.4104 13.9424 34.2239C13.5996 33.8142 13.4615 33.2547 13.5638 32.6888C14.0088 30.2198 15.3979 28.1458 17.5811 26.6898C19.5206 25.3972 21.9775 24.6859 24.4998 24.6859C27.0221 24.6859 29.479 25.3978 31.4186 26.6898C33.6018 28.1452 34.9908 30.2193 35.4359 32.6883C35.5382 33.2541 35.4 33.8136 35.0572 34.2234C34.9016 34.41 34.7062 34.5596 34.4855 34.6612C34.2647 34.7628 34.0241 34.8139 33.7811 34.8109Z"
            fill={theme.tokens.surfaceBright}
          />
        </g>
      </g>
    </SvgIcon>
  )
}

const TotalProducerIcon = (props) => {
  const theme = useTheme()
  return (
    <SvgIcon {...props}>
      <g id="icon">
        <rect
          id="Rectangle 58"
          x="0.5"
          y="0.5"
          width="48"
          height="48"
          rx="24"
          fill={theme.tokens.primaryContainer}
        />
        <g id="Vector">
          <path
            d="M29.0412 12.9051C28.015 11.7971 26.5817 11.187 24.9997 11.187C23.4092 11.187 21.9711 11.7935 20.9497 12.8946C19.9171 14.0078 19.4141 15.5207 19.5322 17.1544C19.7663 20.3776 22.219 22.9995 24.9997 22.9995C27.7804 22.9995 30.2288 20.3781 30.4667 17.1555C30.5864 15.5365 30.0801 14.0268 29.0412 12.9051Z"
            fill={theme.tokens.surfaceBright}
          />
          <path
            d="M34.2811 34.8109H15.7186C15.4756 34.814 15.235 34.763 15.0142 34.6614C14.7935 34.5599 14.5981 34.4104 14.4424 34.2239C14.0996 33.8142 13.9615 33.2547 14.0638 32.6888C14.5088 30.2198 15.8979 28.1458 18.0811 26.6898C20.0206 25.3972 22.4775 24.6859 24.9998 24.6859C27.5221 24.6859 29.979 25.3978 31.9186 26.6898C34.1018 28.1452 35.4908 30.2193 35.9359 32.6883C36.0382 33.2541 35.9 33.8136 35.5572 34.2234C35.4016 34.41 35.2062 34.5596 34.9855 34.6612C34.7647 34.7628 34.5241 34.8139 34.2811 34.8109Z"
            fill={theme.tokens.surfaceBright}
          />
        </g>
      </g>
    </SvgIcon>
  )
}

const InformationIcon = (props) => {
  const theme = useTheme()
  return (
    <SvgIcon {...props} width="40" height="40" viewBox="0 0 40 40">
      <g id="Icon">
        <path
          id="Vector"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20 0.800049C9.40356 0.800049 0.799988 9.40362 0.799988 20C0.799988 30.5965 9.40356 39.2 20 39.2C30.5964 39.2 39.2 30.5965 39.2 20C39.2 9.40362 30.5964 0.800049 20 0.800049ZM20 3.75389C28.9659 3.75389 36.2461 11.0341 36.2461 20C36.2461 28.966 28.9659 36.2462 20 36.2462C11.0341 36.2462 3.75383 28.966 3.75383 20C3.75383 11.0341 11.0341 3.75389 20 3.75389Z"
          fill={theme.tokens.secondary}
        />
        <path
          id="Vector_2"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18.5231 19.0155V26.8924C18.5231 27.7077 19.1847 28.3693 20 28.3693C20.8153 28.3693 21.4769 27.7077 21.4769 26.8924V19.0155C21.4769 18.2002 20.8153 17.5386 20 17.5386C19.1847 17.5386 18.5231 18.2002 18.5231 19.0155Z"
          fill={theme.tokens.secondary}
        />
        <path
          id="Vector_3"
          d="M20 15.077C20.8157 15.077 21.4769 14.4158 21.4769 13.6001C21.4769 12.7844 20.8157 12.1232 20 12.1232C19.1843 12.1232 18.5231 12.7844 18.5231 13.6001C18.5231 14.4158 19.1843 15.077 20 15.077Z"
          fill={theme.tokens.secondary}
        />
      </g>
    </SvgIcon>
  )
}

const TotalCapacityIcon = (props) => {
  const theme = useTheme()
  return (
    <SvgIcon {...props}>
      <g id="icon">
        <rect
          id="Rectangle 59"
          y="0.5"
          width="48"
          height="48"
          rx="24"
          fill={theme.tokens.secondary}
        />
        <path
          id="Vector"
          d="M17.2501 26.5001H21.6669L20.7551 35.121C20.751 35.147 20.7499 35.2239 20.7499 35.2499C20.7499 35.939 21.311 36.5 22 36.5C22.392 36.5 22.7659 36.312 23.014 35.9791L31.75 23.5001C31.9109 23.2849 31.9999 23.019 31.9999 22.7501C31.9999 22.061 31.4391 21.5 30.7501 21.5H25.3441L26.244 13.8969C26.2469 13.868 26.249 13.779 26.249 13.7501C26.2501 13.061 25.689 12.5 25 12.5C24.608 12.5 24.2341 12.688 23.9781 13.031L16.2499 24.5C16.089 24.715 16 24.981 16 25.25C16 25.939 16.561 26.5001 17.2501 26.5001Z"
          fill={theme.tokens.surfaceBright}
        />
      </g>
    </SvgIcon>
  )
}

//Icons for energy share Kpis on home screen of Dashboard.
const BioMassIcon = (props) => {
  const theme = useTheme()
  return (
    <SvgIcon {...props} viewBox="0 0 32 32">
      <g id="biomasse_oli">
        <g id="Vector">
          <rect
            id="Rectangle 59"
            x="0.5"
            y="0.5"
            width="32"
            height="32"
            rx="24"
            fill={theme.tokens.surfaceBright}
          />
          <path
            d="M20.9161 15.1613C20.4071 8.72806 15.7824 3.32861 9.42834 2.01797C9.11944 1.95432 8.80007 2.06237 8.59339 2.30082C4.34864 7.19847 3.81349 14.3265 7.26014 19.8085C8.56369 21.8818 10.6129 23.1298 13.0046 23.2544C13.2184 25.2091 13.2505 27.1501 13.0975 29.0585C13.0569 29.5651 13.4567 30 13.9675 30C14.4174 30 14.799 29.654 14.8356 29.1978C15.0981 25.9225 14.8118 22.2908 13.9565 18.7996L10.9915 17.7755C10.5363 17.6183 10.2948 17.1219 10.452 16.6668C10.6092 16.2116 11.1057 15.9702 11.5607 16.1274L13.3932 16.7603C12.586 14.1345 11.453 11.4615 10.0119 8.78647C9.7835 8.36259 9.94201 7.83383 10.3659 7.60546C10.7899 7.37709 11.3186 7.5356 11.547 7.95953C12.1562 9.09036 12.7123 10.2217 13.2147 11.3505L13.5588 10.3765C13.7192 9.92253 14.2172 9.68446 14.6713 9.84493C15.1253 10.0054 15.3633 10.5034 15.2029 10.9574L14.2097 13.7686C15.2871 16.6205 16.0582 19.5526 16.4232 22.6407C19.3121 21.4004 21.1875 18.5915 20.9161 15.1613Z"
            fill={theme.tokens.primary}
          />
          <path
            d="M23.5408 2.30084C23.3341 2.0624 23.0148 1.95429 22.7058 2.01799C20.8257 2.40579 19.0388 3.17344 17.4586 4.26351C19.4734 6.1269 20.9937 8.49181 21.866 11.1278C23.7692 16.8793 22.1301 20.9469 18.8586 23.2637C21.3671 23.2238 23.5229 21.9574 24.874 19.8085C28.2887 14.3774 27.8245 7.2435 23.5408 2.30084Z"
            fill={theme.tokens.primary}
          />
        </g>
      </g>
    </SvgIcon>
  )
}

const WindEnergyIcon = (props) => {
  const theme = useTheme()
  return (
    <SvgIcon {...props} viewBox="0 0 33 32">
      <g id="wind_oli">
        <rect
          id="Rectangle 59"
          y="0.5"
          width="32"
          height="32"
          rx="24"
          fill={theme.tokens.surfaceBright}
        />
        <path
          id="Vector"
          d="M28 12.3125C28 14.8627 25.9252 16.9375 23.375 16.9375H8.6875C8.16972 16.9375 7.75 16.5178 7.75 16C7.75 15.4822 8.16972 15.0625 8.6875 15.0625H23.375C24.8913 15.0625 26.125 13.8288 26.125 12.3125C26.125 10.7961 24.8913 9.56247 23.375 9.56247C21.8586 9.56247 20.625 10.7961 20.625 12.3125C20.625 12.8303 20.2052 13.25 19.6875 13.25C19.1697 13.25 18.75 12.8303 18.75 12.3125C18.75 9.76225 20.8248 7.68747 23.375 7.68747C25.9252 7.68752 28 9.76225 28 12.3125ZM4.9375 13.25H12.9062C15.4565 13.25 17.5313 11.1752 17.5313 8.62497C17.5313 6.07478 15.4565 4 12.9062 4C10.356 4 8.28123 6.07478 8.28123 8.62502C8.28123 9.1428 8.70095 9.56252 9.21873 9.56252C9.73652 9.56252 10.1562 9.1428 10.1562 8.62502C10.1562 7.10866 11.3899 5.875 12.9062 5.875C14.4226 5.875 15.6563 7.10866 15.6563 8.62502C15.6563 10.1414 14.4226 11.375 12.9062 11.375H4.9375C4.41972 11.375 4 11.7948 4 12.3125C4 12.8303 4.41972 13.25 4.9375 13.25ZM19.6875 18.75H4.9375C4.41972 18.75 4 19.1697 4 19.6875C4 20.2053 4.41972 20.625 4.9375 20.625H19.6875C21.2039 20.625 22.4375 21.8587 22.4375 23.375C22.4375 24.8914 21.2039 26.125 19.6875 26.125C18.1712 26.125 16.9375 24.8913 16.9375 23.375C16.9375 22.8572 16.5178 22.4375 16 22.4375C15.4822 22.4375 15.0625 22.8572 15.0625 23.375C15.0625 25.9252 17.1373 28 19.6875 28C22.2377 28 24.3125 25.9252 24.3125 23.375C24.3125 20.8248 22.2377 18.75 19.6875 18.75Z"
          fill={theme.tokens.secondary}
        />
      </g>
    </SvgIcon>
  )
}

const WindIconWithoutBackground = (props) => {
  const theme = useTheme()
  return (
    <SvgIcon {...props} viewBox="0 0 33 32">
      <g id="wind_oli">
        <path
          id="Vector"
          d="M28 12.3125C28 14.8627 25.9252 16.9375 23.375 16.9375H8.6875C8.16972 16.9375 7.75 16.5178 7.75 16C7.75 15.4822 8.16972 15.0625 8.6875 15.0625H23.375C24.8913 15.0625 26.125 13.8288 26.125 12.3125C26.125 10.7961 24.8913 9.56247 23.375 9.56247C21.8586 9.56247 20.625 10.7961 20.625 12.3125C20.625 12.8303 20.2052 13.25 19.6875 13.25C19.1697 13.25 18.75 12.8303 18.75 12.3125C18.75 9.76225 20.8248 7.68747 23.375 7.68747C25.9252 7.68752 28 9.76225 28 12.3125ZM4.9375 13.25H12.9062C15.4565 13.25 17.5313 11.1752 17.5313 8.62497C17.5313 6.07478 15.4565 4 12.9062 4C10.356 4 8.28123 6.07478 8.28123 8.62502C8.28123 9.1428 8.70095 9.56252 9.21873 9.56252C9.73652 9.56252 10.1562 9.1428 10.1562 8.62502C10.1562 7.10866 11.3899 5.875 12.9062 5.875C14.4226 5.875 15.6563 7.10866 15.6563 8.62502C15.6563 10.1414 14.4226 11.375 12.9062 11.375H4.9375C4.41972 11.375 4 11.7948 4 12.3125C4 12.8303 4.41972 13.25 4.9375 13.25ZM19.6875 18.75H4.9375C4.41972 18.75 4 19.1697 4 19.6875C4 20.2053 4.41972 20.625 4.9375 20.625H19.6875C21.2039 20.625 22.4375 21.8587 22.4375 23.375C22.4375 24.8914 21.2039 26.125 19.6875 26.125C18.1712 26.125 16.9375 24.8913 16.9375 23.375C16.9375 22.8572 16.5178 22.4375 16 22.4375C15.4822 22.4375 15.0625 22.8572 15.0625 23.375C15.0625 25.9252 17.1373 28 19.6875 28C22.2377 28 24.3125 25.9252 24.3125 23.375C24.3125 20.8248 22.2377 18.75 19.6875 18.75Z"
          fill={theme.tokens.secondary}
        />
      </g>
    </SvgIcon>
  )
}

const HydroEnergyIcon = (props) => {
  const theme = useTheme()
  return (
    <SvgIcon {...props} viewBox="0 0 33 32">
      <g id="wasserkraft_oli">
        <rect
          id="Rectangle 59"
          y="0.5"
          width="33"
          height="32"
          rx="24"
          fill={theme.tokens.surfaceBright}
        />
        <path
          id="Vector"
          d="M16.6071 28C21.6251 28 25.7078 23.9185 25.7078 18.9015C25.7078 14.0175 17.3484 4.5655 16.9924 4.1655C16.7962 3.94483 16.4177 3.94483 16.2209 4.1655C15.8656 4.5655 7.50581 14.0178 7.5 18.9015C7.5 23.9185 11.5855 28 16.6071 28ZM12.1963 18.7328L17.7826 11.3984C17.8861 11.2628 18.0679 11.212 18.2267 11.2741C18.3862 11.3368 18.4844 11.4972 18.4682 11.6668L17.9859 16.6618H20.7063C20.8517 16.6618 20.9842 16.7438 21.0488 16.8739C21.1134 17.0037 21.099 17.1591 21.0118 17.2751L15.5999 24.433C15.5264 24.5297 15.4123 24.5848 15.2945 24.5848C15.2538 24.5848 15.2124 24.5784 15.1727 24.5652C15.0172 24.5126 14.9119 24.3669 14.9119 24.2022V19.3474H12.5005C12.3552 19.3474 12.2226 19.2647 12.1581 19.1347C12.0935 19.0046 12.1085 18.8482 12.1963 18.7328Z"
          fill={theme.tokens.secondaryFixedDim}
        />
      </g>
    </SvgIcon>
  )
}
const SolarEnergyIcon = (props) => {
  const theme = useTheme()
  return (
    <SvgIcon {...props} viewBox="0 0 32 32">
      <g id="solar_oli">
        <g id="Vector">
          <rect
            id="Rectangle 59"
            y="0.5"
            width="32"
            height="32"
            rx="24"
            fill={theme.tokens.surfaceBright}
          />
          <path
            d="M20.254 11.7461C19.1746 10.6667 17.6508 9.96832 16 9.96832C14.3492 9.96832 12.8254 10.635 11.746 11.7461C10.6667 12.8255 9.96825 14.3493 9.96825 16.0001C9.96825 17.6509 10.6667 19.1747 11.746 20.254C12.8254 21.3334 14.3492 22.0318 16 22.0318C17.6508 22.0318 19.1746 21.3652 20.254 20.254C21.3333 19.1747 22.0317 17.6509 22.0317 16.0001C22.0317 14.3493 21.3651 12.8255 20.254 11.7461Z"
            fill={theme.tokens.customColorSignal}
          />
          <path
            d="M16 8.09524C16.4444 8.09524 16.8254 7.71429 16.8254 7.26984V4.8254C16.8254 4.38095 16.4444 4 16 4C15.5556 4 15.1746 4.38095 15.1746 4.8254V7.26984C15.1746 7.71429 15.5556 8.09524 16 8.09524Z"
            fill={theme.tokens.customColorSignal}
          />
          <path
            d="M22.7619 10.4126L24.5079 8.6666C24.8254 8.34914 24.8254 7.8412 24.5079 7.52374C24.1905 7.20628 23.6825 7.20628 23.3651 7.52374L21.619 9.26977C21.3016 9.58723 21.3016 10.0952 21.619 10.4126C21.9048 10.7301 22.4127 10.7301 22.7619 10.4126Z"
            fill={theme.tokens.customColorSignal}
          />
          <path
            d="M27.1746 15.1746H24.7302C24.2857 15.1746 23.9048 15.5555 23.9048 16C23.9048 16.4444 24.2857 16.8254 24.7302 16.8254H27.1746C27.619 16.8254 28 16.4444 28 16C28 15.5555 27.619 15.1746 27.1746 15.1746Z"
            fill={theme.tokens.customColorSignal}
          />
          <path
            d="M22.7302 21.5873C22.4127 21.2699 21.9048 21.2699 21.5873 21.5873C21.2698 21.9048 21.2698 22.4127 21.5873 22.7302L23.3333 24.4762C23.6508 24.7937 24.1587 24.7937 24.4762 24.4762C24.7936 24.1588 24.7936 23.6508 24.4762 23.3334L22.7302 21.5873Z"
            fill={theme.tokens.customColorSignal}
          />
          <path
            d="M16 23.9048C15.5556 23.9048 15.1746 24.2858 15.1746 24.7302V27.1747C15.1746 27.6191 15.5556 28.0001 16 28.0001C16.4444 28.0001 16.8254 27.6191 16.8254 27.1747V24.7302C16.8254 24.2858 16.4444 23.9048 16 23.9048Z"
            fill={theme.tokens.customColorSignal}
          />
          <path
            d="M9.23809 21.5873L7.49206 23.3334C7.1746 23.6508 7.1746 24.1588 7.49206 24.4762C7.80952 24.7937 8.31746 24.7937 8.63492 24.4762L10.381 22.7302C10.6984 22.4127 10.6984 21.9048 10.381 21.5873C10.0952 21.2699 9.5873 21.2699 9.23809 21.5873Z"
            fill={theme.tokens.customColorSignal}
          />
          <path
            d="M8.09524 16C8.09524 15.5555 7.71429 15.1746 7.26984 15.1746H4.8254C4.38095 15.1746 4 15.5555 4 16C4 16.4444 4.38095 16.8254 4.8254 16.8254H7.26984C7.71429 16.8254 8.09524 16.4444 8.09524 16Z"
            fill={theme.tokens.customColorSignal}
          />
          <path
            d="M9.23809 10.4126C9.55555 10.7301 10.0635 10.7301 10.381 10.4126C10.6984 10.0952 10.6984 9.58723 10.381 9.26977L8.63492 7.52374C8.31746 7.20628 7.80952 7.20628 7.49206 7.52374C7.1746 7.8412 7.1746 8.34914 7.49206 8.6666L9.23809 10.4126Z"
            fill={theme.tokens.customColorSignal}
          />
        </g>
      </g>
    </SvgIcon>
  )
}

//icons for graph on Home Screen
const EnergySoldIcon = (props) => {
  const theme = useTheme()
  return (
    <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16">
      <path
        id="Vector"
        d="M1.19873 0H14.8013C15.1263 0 15.4108 0.00896358 15.6444 0.0285205C15.8781 0.0472626 16 0.0692639 16 0.0961548V15.9038C16 15.9299 15.8781 15.9527 15.6444 15.9715C15.4108 15.9902 15.1263 16 14.8013 16H1.19873C0.873651 16 0.589204 15.9902 0.355556 15.9715C0.121904 15.9527 0 15.9299 0 15.9038V0.0961548C0 0.0692639 0.121904 0.0472626 0.355556 0.0285205C0.589204 0.00896358 0.873651 0 1.19873 0Z"
        fill={theme.tokens.primary}
      />
    </SvgIcon>
  )
}

const EnergyPurchasedIcon = (props) => {
  const theme = useTheme()
  return (
    <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16">
      <path
        id="Vector"
        d="M1.19873 0H14.8013C15.1263 0 15.4108 0.00896358 15.6444 0.0285205C15.8781 0.0472626 16 0.0692639 16 0.0961548V15.9038C16 15.9299 15.8781 15.9527 15.6444 15.9715C15.4108 15.9902 15.1263 16 14.8013 16H1.19873C0.873651 16 0.589204 15.9902 0.355556 15.9715C0.121904 15.9527 0 15.9299 0 15.9038V0.0961548C0 0.0692639 0.121904 0.0472626 0.355556 0.0285205C0.589204 0.00896358 0.873651 0 1.19873 0Z"
        fill={theme.tokens.error}
      />
    </SvgIcon>
  )
}

// This is for abschlag screen
const PriceAdjustmentIcon = (props) => {
  const theme = useTheme()
  return (
    <SvgIcon {...props} viewBox="0 0 30 31" fill={theme.tokens.secondary}>
      <g id="icon">
        <rect id="Rectangle 3" y="0.263672" width="30" height="30" rx="8" />
        <g id="Vector">
          <path
            d="M17.6701 12.3611C14.7484 12.3611 12.3299 14.738 12.3299 17.6598C12.3299 20.5815 14.7484 23 17.6701 23C20.5919 23 22.9688 20.5815 22.9688 17.6598C22.9688 14.738 20.5919 12.3611 17.6701 12.3611ZM20.4753 18.2831H18.2935V20.5065C18.2935 20.8502 18.0136 21.1299 17.6701 21.1299C17.3265 21.1299 17.0468 20.8502 17.0468 20.5065V18.2831H14.8234C14.4797 18.2831 14.2 18.0034 14.2 17.6598C14.2 17.3161 14.4797 17.0364 14.8234 17.0364H17.0468V14.8546C17.0468 14.5109 17.3265 14.2312 17.6701 14.2312C18.0136 14.2312 18.2935 14.5109 18.2935 14.8546V17.0364H20.4753C20.8188 17.0364 21.0987 17.3161 21.0987 17.6598C21.0987 18.0034 20.8188 18.2831 20.4753 18.2831Z"
            fill={theme.tokens.surfaceContainerLowest}
          />
          <path
            d="M12.652 11.6546C15.7735 11.6546 18.304 10.6126 18.304 9.32729C18.304 8.04196 15.7735 7 12.652 7C9.53048 7 7 8.04196 7 9.32729C7 10.6126 9.53048 11.6546 12.652 11.6546Z"
            fill={theme.tokens.surfaceContainerLowest}
          />
          <path
            d="M11.9279 14.958C12.3694 14.0211 13.033 13.2119 13.8522 12.5955C13.4546 12.6294 13.0543 12.652 12.652 12.652C10.4504 12.652 8.23612 12.142 7 11.1579V12.652C7 13.8362 9.14908 14.811 11.9279 14.958Z"
            fill={theme.tokens.surfaceContainerLowest}
          />
          <path
            d="M11.3221 17.639C11.3221 17.0459 11.4099 16.4741 11.5635 15.9295C9.74554 15.7898 8.03132 15.3038 7 14.4826V15.9767C7 17.0778 8.85851 17.9974 11.3527 18.2395C11.3341 18.042 11.3221 17.8418 11.3221 17.639Z"
            fill={theme.tokens.surfaceContainerLowest}
          />
        </g>
      </g>
    </SvgIcon>
  )
}

export {
  TotalUserIcon,
  TotalConsumerIcon,
  TotalProducerIcon,
  TotalCapacityIcon,
  BioMassIcon,
  WindEnergyIcon,
  HydroEnergyIcon,
  SolarEnergyIcon,
  EnergySoldIcon,
  EnergyPurchasedIcon,
  PriceAdjustmentIcon,
  InformationIcon,
  WindIconWithoutBackground,
}
