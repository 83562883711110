import merge from 'deepmerge'
import { green, grey, red } from '@mui/material/colors'
import { THEMES } from '../constants'
/**
 * This file is main file for theme , it holds all the color tokens, brak points and other data for theme.
 */
const customBlue = {
  50: '#e9f0fb',
  100: '#c8daf4',
  200: '#a3c1ed',
  300: '#7ea8e5',
  400: '#6395e0',
  500: '#4782da',
  600: '#407ad6',
  700: '#376fd0',
  800: '#2f65cb',
  900: '#2052c2 ',
}

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
}

const defaultVariant = {
  name: THEMES.LIGHT,
  breakpoints, // Add breakpoints for different screen sizes to theme
  /** @type {import("@mui/material").ThemeOptions['palette']} */
  tokens: {
    primary: '#84BB3D',
    surfaceTint: '#84BB3D',
    onPrimary: '#FFFFFF',
    primaryContainer: '#416900',
    onPrimaryContainer: '#FFFFFF',
    secondary: '#003353',
    onSecondary: '#FFFFFF',
    secondaryContainer: '#FFFFFF',
    onSecondaryContainer: '#003353',
    tertiary: '#E8F5D6',
    onTertiary: '#141E0C',
    tertiaryContainer: '#E8F5D6',
    onTertiaryContainer: '#84BB3D',
    error: '#BA1A1A',
    onError: '#FFFFFF',
    errorContainer: '#FFDAD6',
    onErrorContainer: '#410002',
    background: '#FFFFFF',
    onBackground: '#003353',
    surface: '#84BB3D',
    onSurface: '#003353',
    surfaceVariant: '#FF9900',
    onSurfaceVariant: '#FFFFFF',
    outline: '#C9C6C5',
    outlineVariant: '#003353',
    shadow: '#1C1B1B',
    scrim: '#1C1B1B',
    inverseSurface: '#FFFFFF',
    inverseOnSurface: '#84BB3D',
    inversePrimary: '#1F3700',
    primaryFixed: '#84BB3D',
    onPrimaryFixed: '#FFFFFF',
    primaryFixedDim: '#416900',
    onPrimaryFixedVariant: '#102000',
    secondaryFixed: '#CEE5FF',
    onSecondaryFixed: '#001D33',
    secondaryFixedDim: '#9BCBFB',
    onSecondaryFixedVariant: '#204A6C',
    tertiaryFixed: '#E8F5D6',
    onTertiaryFixed: '#141E0C',
    tertiaryFixedDim: '#BECBAE',
    onTertiaryFixedVariant: '#3F4A34',
    surfaceDim: '#F4F0EF',
    surfaceBright: '#FFFFFF',
    surfaceContainerLowest: '#FFFFFF',
    surfaceContainerLow: '#F4F0EF',
    surfaceContainer: '#E8F5D6',
    surfaceContainerHigh: '#C9C6C5',
    surfaceContainerHighest: '#929090',
    secondaryItem: '#FFFFFF',
    customColorSignal: '#FF9900',
    customColorSignalContainer: '#FFDDB1',
    transparentBackground: '#FFFFFF00',
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#84BB3D',
      contrastText: '#FFF',
    },
    secondary: {
      main: green[500],
      contrastText: '#FFF',
    },
    warning: {
      main: '#e6cd0d',
    },
    success: {
      main: '#7faa40',
    },
    background: {
      default: '#F7F9FC',
      paper: '#FFF',
    },
  },
  header: {
    color: grey[500], // this is header color
    background: '#FFF',
    search: {
      color: grey[800],
    },
    indicator: {
      background: green[600],
    },
  },
  footer: {
    color: grey[500],
    background: grey[100],
    nameColor: grey[600],
  },
  sidebar: {
    color: grey[900], // for side bar item list color

    header: {
      color: '#003455',
      background: '#FFF',
      brand: {
        color: green[500],
      },
    },

    badge: {
      color: grey[900],
      background: green[900],
    },
    boxShadow: '0px 0px 100px 100px rgba(0,0,0,1)',
  },
}

const darkVariant = merge(defaultVariant, {
  name: THEMES.DARK,
  breakpoints, // Add breakpoints for different screen sizes to theme
  tokens: {
    primary: '#9FD756',
    surfaceTint: '#9FD756',
    onPrimary: '#1F3700',
    primaryContainer: '#416900',
    onPrimaryContainer: '#FFFFFF',
    secondary: '#FFFFFF',
    onSecondary: '#003353',
    secondaryContainer: '#313030',
    onSecondaryContainer: '#FFFFFF',
    tertiary: '#1C1B1B',
    onTertiary: '#FFFFFF',
    tertiaryContainer: '#84BB3D',
    onTertiaryContainer: '#FFFFFF',
    error: '#BA1A1A',
    onError: '#FFFFFF',
    errorContainer: '#FFB4AB',
    onErrorContainer: '#410002',
    background: '#000000',
    onBackground: '#FFFFFF',
    surface: '#000000',
    onSurface: '#FFFFFF',
    surfaceVariant: '#FF9900',
    onSurfaceVariant: '#FFFFFF',
    outline: '#929090',
    outlineVariant: '#FFFFFF',
    shadow: '#000000',
    scrim: '#000000',
    inverseSurface: '#E8F5D6',
    inverseOnSurface: '#84BB3D',
    inversePrimary: '#1F3700',
    primaryFixed: '#84BB3D',
    onPrimaryFixed: '#FFFFFF',
    primaryFixedDim: '#416900',
    onPrimaryFixedVariant: '#102000',
    secondaryFixed: '#CEE5FF',
    onSecondaryFixed: '#001D33',
    secondaryFixedDim: '#9BCBFB',
    onSecondaryFixedVariant: '#204A6C',
    tertiaryFixed: '#E8F5D6',
    onTertiaryFixed: '#141E0C',
    tertiaryFixedDim: '#BECBAE',
    onTertiaryFixedVariant: '#3F4A34',
    surfaceDim: '#1C1B1B',
    surfaceBright: '#313030',
    surfaceContainerLowest: '#1D1D1E',
    surfaceContainerLow: '#313030',
    surfaceContainer: '#000000',
    surfaceContainerHigh: '#929090',
    surfaceContainerHighest: '#5F5E5E',
    secondaryItem: '#003353',
    customColorSignal: '#FF9900',
    customColorSignalContainer: '#FFDDB1',
    transparentBackground: '#FFFFFF00',
  },
  palette: {
    mode: 'dark',
    primary: {
      main: customBlue[600],
      contrastText: '#FFF',
    },
    background: {
      default: '#1B2635',
      paper: '#233044',
    },
    text: {
      primary: 'rgba(255, 255, 255, 0.95)',
      secondary: 'rgba(255, 255, 255, 0.5)',
    },
  },
  header: {
    color: grey[300],
    background: '#1B2635',
    search: {
      color: grey[200],
    },
  },
  sidebar: {
    color: grey[300],
    items: {
      background: '#000',
    },
    header: {
      color: '#FFF',
      background: '#333',
      brand: {
        color: '#333',
      },
    },
    footer: {
      color: '#FFF',
      background: '#151515',
      online: {
        background: green[500],
      },
    },
  },
  footer: {
    color: grey[300],
    background: '#233044',
  },
})

const lightVariant = merge(defaultVariant, {
  name: THEMES.LIGHT,
  breakpoints, // Add breakpoints for different screen sizes to theme
  tokens: {
    primary: '#84BB3D',
    surfaceTint: '#84BB3D',
    onPrimary: '#FFFFFF',
    primaryContainer: '#416900',
    onPrimaryContainer: '#FFFFFF',
    secondary: '#003353',
    onSecondary: '#FFFFFF',
    secondaryContainer: '#FFFFFF',
    onSecondaryContainer: '#003353',
    tertiary: '#E8F5D6E',
    onTertiary: '#141E0C',
    tertiaryContainer: '#E8F5D6',
    onTertiaryContainer: '#84BB3D',
    error: '#BA1A1A',
    onError: '#FFFFFF',
    errorContainer: '#FFDAD6',
    onErrorContainer: '#410002',
    background: '#FFFFFF',
    onBackground: '#003353',
    surface: '#84BB3D',
    onSurface: '#003353',
    surfaceVariant: '#FF9900',
    onSurfaceVariant: '#FFFFFF',
    outline: '#C9C6C5',
    outlineVariant: '#003353',
    shadow: '#1C1B1B',
    scrim: '#1C1B1B',
    inverseSurface: '#FFFFFF',
    inverseOnSurface: '#84BB3D',
    inversePrimary: '#1F3700',
    primaryFixed: '#84BB3D',
    onPrimaryFixed: '#FFFFFF',
    primaryFixedDim: '#416900',
    onPrimaryFixedVariant: '#102000',
    secondaryFixed: '#CEE5FF',
    onSecondaryFixed: '#001D33',
    secondaryFixedDim: '#9BCBFB',
    onSecondaryFixedVariant: '#204A6C',
    tertiaryFixed: '#E8F5D6',
    onTertiaryFixed: '#141E0C',
    tertiaryFixedDim: '#BECBAE',
    onTertiaryFixedVariant: '#3F4A34',
    surfaceDim: '#F4F0EF',
    surfaceBright: '#FFFFFF',
    surfaceContainerLowest: '#FFFFFF',
    surfaceContainerLow: '#F4F0EF',
    surfaceContainer: '#E8F5D6',
    surfaceContainerHigh: '#C9C6C5',
    surfaceContainerHighest: '#929090',
    transparentBackground: '#FFFFFF00',
  },
  palette: {
    mode: 'light',
  },
  header: {
    color: grey[200],
    background: customBlue[800],
    search: {
      color: grey[100],
    },
    indicator: {
      background: red[700],
    },
  },
  sidebar: {
    color: '#003455',
    background: '#FFF',
    header: {
      color: '#003455',
      background: '#FFFFFF',
      brand: {
        color: '#FFFFFF',
      },
    },
    footer: {
      color: '#003455',
      background: '#F0F0F0',
      online: {
        background: green[500],
      },
    },
  },
})

const variants = [defaultVariant, lightVariant, darkVariant]

export default variants
