import { CardContent, Typography } from '@mui/material'
import { View, Spacer, Item } from './styles'
import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'
import { formatDecimal } from '../../../services/utils/format-numbers'
import { styled } from '@mui/material/styles'
import React from 'react'

const StyledView = styled(View)`
  && {
    border-radius: 28px;
    overflow: hidden;
    background-color: ${(props) => props.theme.tokens.surfaceBright};
  }
`
const StyledTypography = styled(Typography)`
  && {
    color: ${(props) => props.theme.tokens.secondary};
  }
`
/**
 * This component is the form that is used in edit workflow to get Generation Plant details in case of PRODUCER contract.
 * In case of CONSUMER contract this form is used to get the details of energy type preference and other details.
 * We are using Formik for forms validaiton in all out forms.
 *
 */
function GenerationPlantDetails({ contract }) {
  const { t } = useTranslation()

  const plantDetailConsumer = {
    preference: contract?.preference,
    addressOwner: contract?.plantDetails?.plantAddress?.addressOwner,
    startOfDelivery: contract?.startOfDelivery,
    PlantAdresse: contract?.plantDetails?.plantAddress?.street
      ? `${contract?.plantDetails?.plantAddress?.postalCode} ${contract?.plantDetails?.plantAddress?.city} `
      : null,
    MarketLocation: contract?.plantDetails?.marketLocation,
    MeterNumber: contract?.plantDetails?.meterNumber,
    LastSupplier: contract?.plantDetails?.lastSupplier,
    LastCustomerNumber: contract?.plantDetails?.costumerNumber,
    stadtWerkeKunden: contract?.stadtWerkeKunden,
    nextPossibleDate: contract?.nextPossibleDate,
  }

  const plantDetailProducer = {
    NameOfPowerPlant: contract?.plantDetails?.plantName,
    PowerPlant: contract?.plantDetails?.plantType,
    addressOwner: contract?.plantDetails?.plantAddress?.addressOwner,
    PlantAdresse: contract?.plantDetails?.plantAddress?.postalCode
      ? `${contract?.plantDetails?.plantAddress?.postalCode} ${contract?.plantDetails?.plantAddress?.city} `
      : null,
    InstalledOutput: formatDecimal(contract?.plantDetails?.installedPowerKw),
    CommissioningDate: contract?.plantDetails?.commisioningDate,
    startOfDelivery: contract?.startOfDelivery,
    MarketLocation: contract?.plantDetails?.marketLocation,
    MeterNumber: contract?.plantDetails?.meterNumber,
    PlantKey: contract?.plantDetails?.plantKey,
    Operator: contract?.plantDetails?.netOperator,
  }
  const plantDetails =
    contract?.contractType === 'CONSUMER'
      ? plantDetailConsumer
      : plantDetailProducer
  const renderKey = useCallback(
    (key) => {
      switch (key) {
        case 'NameOfPowerPlant':
          return t('LBLPlantName')
        case 'PowerPlant':
          return contract?.contractType === 'PRODUCER'
            ? t('LBLPlantType')
            : null
        case 'addressOwner':
          return t('LBLAddressOwner')
        case 'PlantAdresse':
          return t('LBLPlantAdress')
        case 'InstalledOutput':
          return contract?.contractType === 'PRODUCER'
            ? t('LBLInstalledOutput')
            : null
        case 'CommissioningDate':
          return t('LBLCommissioningDate')
        case 'MarketLocation':
          return t('LBLMarketLocation')
        case 'MeterNumber':
          return t('LBLMeterNumber')
        case 'PlantKey':
          return t('LBLPlantKey')
        case 'Operator':
          return t('LBLOperator')
        case 'LastSupplier':
          return t('LBLLastSupplier')
        case 'LastCustomerNumber':
          return t('LBLLastCustomerNum')
        case 'stadtWerkeKunden':
          return t('LBLStadtWerkeKunden')
        case 'preference':
          return t('LBLPreference')
        case 'startOfDelivery':
          return t('LBLStartOfDeliveryDate')
        case 'nextPossibleDate':
          return t('LBLNextPossibleDate')
        default:
          return key
      }
    },
    [contract, t]
  )
  return (
    <>
      <StyledView>
        <CardContent>
          <StyledTypography fontWeight="bold" variant="h4" gutterBottom>
            {t('LBLGenerationPlantDetails')}
          </StyledTypography>
          <Spacer mb={4} />
          <StyledTypography variant="body2" component="div" gutterBottom>
            {plantDetails && (
              <>
                {Object.entries(plantDetails).map(([key, value]) => {
                  return (
                    <>
                      <Item key={key}>
                        <StyledTypography fontWeight="fontWeightMedium">
                          {renderKey(key)}
                        </StyledTypography>
                        {key === 'Adresse' ? (
                          <StyledTypography
                            fontWeight="fontWeightRegular"
                            align="right"
                          >
                            {contract?.plantDetails?.plantAddress?.postalCode}{' '}
                            {contract?.plantDetails?.plantAddress?.city}
                          </StyledTypography>
                        ) : (
                          <StyledTypography
                            fontWeight="fontWeightRegular"
                            align="right"
                          >
                            {typeof value === 'boolean'
                              ? value
                                ? 'True'
                                : 'False'
                              : ['string', 'number'].includes(typeof value)
                              ? value
                              : Array.isArray(value)
                              ? value?.join(', ')
                              : null}
                          </StyledTypography>
                        )}
                      </Item>
                    </>
                  )
                })}
              </>
            )}
          </StyledTypography>
        </CardContent>
      </StyledView>
    </>
  )
}

export default GenerationPlantDetails
