import styled from 'styled-components/macro'
import React from 'react'
import { spacing } from '@mui/system'
import {
  Chip as MuiChip,
  Typography,
  Box,
  CardContent,
  Card,
  Paper,
  Button,
} from '@mui/material'
import { green, grey, orange } from '@mui/material/colors'

/**
 * This file holds most of the styles used in Nutzerverwaltung page.
 */
export const Item = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16px;
`

export const View = styled.div`
  flex: 1;
  background: white;
`

export const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-top: 16px;
`

export const Spacer = styled.div(spacing)

export const Chip = styled(MuiChip)`
  ${spacing};

  background: ${(props) => props.active && green[500]};
  background: ${(props) => props.pending && orange[700]};
  background: ${(props) => props.inactive && grey[300]};
  color: ${(props) =>
    (props.active || props.pending) && props.theme.palette.common.white};
`

// Custom radio button styles
export const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`

export const HiddenRadio = styled.input.attrs({ type: 'radio' })`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
`

export const RadioLabel = styled.label`
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  display: inline-block;
  user-select: none;
`

export const RadioCustom = styled.span`
  position: absolute;
  left: 0;
  top: 0;
  width: 15px;
  height: 15px;
  border: 2px solid ${(props) => props.theme.palette.grey[400]};
  border-radius: 50%;
  background: ${(props) => props.theme.palette.background.paper};

  ${HiddenRadio}:checked + ${RadioLabel} & {
    border-color: ${(props) => props.theme.tokens.primary};
  }
  ${HiddenRadio}:checked + ${RadioLabel} &::after {
    content: '';
    position: absolute;
    left: 2px;
    top: 2px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${(props) => props.theme.tokens.primary};
  }
`

export const StyledTypography = styled(Typography)`
  && {
    color: ${(props) => props.theme.tokens.seondary};
    font-weight: bold;
    font-size: 15px;
    padding-top: 15px;
    padding-bottom: 10px;
  }
`

export const TextContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  width: '825px',
  [theme.breakpoints.up('sm')]: {
    width: '150px',
  },
  [theme.breakpoints.up('md')]: {
    width: '250px',
  },
  [theme.breakpoints.up('lg')]: {
    width: '525px',
  },
  [theme.breakpoints.up('xl')]: {
    width: '825px',
  },
}))

export const StyledCard = ({ text, required, variant = 'default' }) => {
  const cardStyles = variant === 'outline' ? { border: '1px solid #ccc' } : {}

  return (
    <Card sx={{ ...cardStyles }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {text}
        </Typography>
        <Typography
          sx={{ marginTop: 5 }}
          variant="h5"
          color="text.secondary"
          gutterBottom
        >
          {required}
        </Typography>
      </CardContent>
    </Card>
  )
}

export const PaperItem = styled(Paper)`
  && {
    background-color: ${(props) => props.theme.tokens.surfaceBright};
    position: relative;
    border-radius: 20px;
    color: ${(props) => props.theme.palette.text.secondary};
    height: 160px;
    line-height: 60px;
    width: 92%;
  }
`
export const PaperItemBody = styled(Paper)`
&&{
  flex: 1; 
  background: ${(props) => props.theme.tokens.surfaceDim};
  position: relative; 
  width: 90%; /
  height: 60%;
  text-align: center;
  color: ${(props) => props.theme.palette.text.secondary};
  z-index: -22; /* Adjust z-index as needed */
  border-radius: 5; /* Add curved corners */
  padding: 30px; /* Add padding as needed */
}
`

export const StyledTypographyHeading = styled(Typography)`
  && {
    color: ${(props) => props.theme.palette.text.primary};
    font-weight: bold;
    font-size: 20px;
    padding-left: 25px;
    padding-top: 35px;
    padding-bottom: 10px;
  }
`

export const CustomizedButton = styled(Button)`
  && {
    background-color: ${(props) => props.theme.tokens.primary};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    position: absolute;
    right: 80px;
    border-radius: 8px;
    border: 1px solid ${(props) => props.theme.tokens.primary};
    align-items: center;
    color: ${(props) => props.theme.tokens.onSurfaceVariant};
    /* Styles for the icon */
    & > .MuiSvgIcon-root {
      color: ${(props) => props.theme.tokens.onSurfaceVariant};
      margin-right: 8px;
    }
  }
`
