import { Box, Typography } from '@mui/material'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import {
  EnergySoldIcon,
  EnergyPurchasedIcon,
} from '../../components/icons/myIcons'
import {
  Container,
  EnergyShareKpiTypography,
  EnergyShareKpiData,
  EnergySoldPurchaseBox,
  GraphContainer,
  EnergyKpiContainer,
} from './styles'
import { useTheme } from 'styled-components'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts'
import { formatDecimal } from '../../services/utils/format-numbers'
import dayjs from 'dayjs'
import React from 'react'

const HeaderTypography = styled(Typography)`
  && {
    color: ${(props) => props.theme.tokens.secondary};
    position: relative;
    margin: 10px;
  }
`
const TooltipContainer = styled.div`
  background: ${(props) => props.theme.tokens.surfaceBright};
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
`

const Label = styled.p`
  color: ${(props) => props.color};
`

//months mapping to abbreviations
const monthAbbreviations = {
  JANUARY: 'Jan',
  FEBRUARY: 'Feb',
  MARCH: 'Mär',
  APRIL: 'Apr',
  MAY: 'Mai',
  JUNE: 'Jun',
  JULY: 'Jul',
  AUGUST: 'Aug',
  SEPTEMBER: 'Sep',
  OCTOBER: 'Okt',
  NOVEMBER: 'Nov',
  DECEMBER: 'Dez',
}

// Months in German for labels
const monthNamesInGerman = {
  JANUARY: 'Januar',
  FEBRUARY: 'Februar',
  MARCH: 'März',
  APRIL: 'April',
  MAY: 'Mai',
  JUNE: 'Juni',
  JULY: 'Juli',
  AUGUST: 'August',
  SEPTEMBER: 'September',
  OCTOBER: 'Oktober',
  NOVEMBER: 'November',
  DECEMBER: 'Dezember',
}
// Function to transform the data
const transformOtherData = (data) => {
  return data.map((entry) => ({
    ...entry,
    purchasedEnergy: -entry.purchasedEnergy, // Convert purchasedEnergy to negative
  }))
}

const generateDateRange = (startDate, endDate) => {
  const dates = []
  const finalDate = dayjs(endDate)
  let currentDate = finalDate.subtract(30, 'day')

  for (let i = 0; i < 31; i++) {
    dates.push(currentDate.format('YYYY-MM-DD'))
    currentDate = currentDate.add(1, 'day')
  }

  return dates
}

// Function to transform the data and fill in missing dates
const transformData = (data, startDate, endDate) => {
  const dateRange = generateDateRange(startDate, endDate)
  const dataMap = new Map(data.map((entry) => [entry.time, entry]))

  return dateRange.map((date) => {
    const entry = dataMap.get(date) || {
      time: date,
      purchasedEnergy: 0,
      soldEnergy: 0,
    }
    return {
      ...entry,
      purchasedEnergy: -entry.purchasedEnergy,
      time: dayjs(entry.time).format('D.M'),
    }
  })
}
/**
 * The function to map the axis label for the bar graph.
 *
 * @param {string} timePeriod - The time period selection for example: Yesterday, Year or Time Period.
 * @param {string} startDate - The start date for the energy summary query, in 'YYYY-MM-DD' format.
 * @param {string} endDate - The end date for the energy summary query, in 'YYYY-MM-DD' format.
 * @returns {string}  - It returns  a label of x-axis such as 'Monat' if time period selection is based on a month ,
 * 'Jahr' if time period selected is a year and so on.
 */
const getXaxisLabel = (timePeriod, startDate, endDate) => {
  const diffInDays = dayjs(endDate).diff(dayjs(startDate), 'day')

  switch (timePeriod) {
    case 'YEAR':
      return 'Monat'
    case 'YESTERDAY':
      return 'Std.'
    case 'TIMEPERIOD':
      if (diffInDays > 31 && diffInDays <= 365) {
        return 'Monat'
      } else if (diffInDays > 365) {
        return 'Jahr'
      } else {
        return 'Tage'
      }
    default:
      return 'Monat'
  }
}
/**
 * The function used to abbreviate the names of months in to upper case to show in the bar graph.
 *
 * @param {string} month - the name of the month that is to be abbreviated and converted to uppercase
 * @returns {months[]} An array of months in short to show in the bar graph.
 */
const abbreviateMonth = (month) => {
  return monthAbbreviations[month.toUpperCase()] || month
}

/**
 * This is the component to show the sale purchased data in the form of bar graph on dashboard.
 * It holds the KPIs i.e. related to total energy produced and total energy consumed during a time selection on dashboard.
 *
 * @returns {React.ReactNode} A React component in the form of Bar graph.
 */
const EnergyBarChart = ({ graphData, timePeriod, startDate, endDate }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  let transformedData = []

  if (
    timePeriod === 'TIMEPERIOD' &&
    dayjs(endDate).diff(dayjs(startDate), 'day') <= 31
  ) {
    // Transform the data for the given date range
    transformedData = transformData(
      graphData?.data?.salesPurchasesDtoset || [],
      startDate,
      endDate
    )
  } else {
    // Transform the data for the year view or other cases
    transformedData = transformOtherData(
      graphData?.data?.salesPurchasesDtoset || []
    )
  }

  // custom tool tip for bars
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      // Replace the label with the corresponding German month name
      const translatedLabel = monthNamesInGerman[label.toUpperCase()] || label

      return (
        <TooltipContainer className="custom-tooltip">
          <Label
            className="label"
            color={theme.tokens.secondary}
          >{`${translatedLabel}`}</Label>
          <Label className="intro" color={theme.tokens.primary}>{`${t(
            'LBLSoldEnergy'
          )}: ${formatDecimal(payload[0].value)} kWh`}</Label>
          <Label className="intro" color={theme.tokens.error}>{`${t(
            'LBLPurchaseEnergy'
          )}: -${formatDecimal(Math.abs(payload[1].value))} kWh`}</Label>
        </TooltipContainer>
      )
    }

    return null
  }

  // Custom tick formatter for Y-axis
  const yAxisTickFormatter = (tick) => `${tick}`

  // Custom tick formatter for X-axis to abbreviate month names
  const xAxisTickFormatter = (tick) => abbreviateMonth(tick)
  return (
    <>
      <HeaderTypography variant="h7">
        {t('LBLEnergySalePurchase')}
      </HeaderTypography>
      <EnergyKpiContainer>
        <Box>
          <EnergySoldIcon />
          <EnergyShareKpiTypography color={theme.tokens.primary} variant="h5">
            = {t('LBLEnergySold')}
          </EnergyShareKpiTypography>
        </Box>

        <Box>
          <EnergyPurchasedIcon />
          <EnergyShareKpiTypography color={theme.tokens.error} variant="h5">
            = {t('LBLEnergyPurchased')}
          </EnergyShareKpiTypography>
        </Box>
      </EnergyKpiContainer>
      <Container>
        <EnergySoldPurchaseBox borderColor={theme.tokens.primary}>
          <EnergyShareKpiTypography variant="h5" color={theme.tokens.primary}>
            {t('LBLTotalEnergySold')}
          </EnergyShareKpiTypography>
          <EnergyShareKpiData variant="h1" color={theme.tokens.primary}>
            {graphData !== null && graphData?.data?.totalSales > 0
              ? formatDecimal(graphData?.data?.totalSales)
              : '00,00'}
          </EnergyShareKpiData>
        </EnergySoldPurchaseBox>

        <EnergySoldPurchaseBox borderColor={theme.tokens.error}>
          <EnergyShareKpiTypography variant="h5" color={theme.tokens.error}>
            {t('LBLTotalEnergyPurchased')}
          </EnergyShareKpiTypography>
          <EnergyShareKpiData variant="h1" color={theme.tokens.error}>
            {graphData !== null && graphData?.data?.totalPurchase > 0
              ? formatDecimal(graphData?.data?.totalPurchase)
              : '00,00'}
          </EnergyShareKpiData>
        </EnergySoldPurchaseBox>
      </Container>

      {/* ENERGY SOLD PURCHASE GRAPH STARTS HERE */}

      <GraphContainer>
        <ResponsiveContainer width="97%" height="90%">
          <BarChart
            data={transformedData}
            margin={{ top: 25, right: 40, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="none" />
            <XAxis
              dataKey="time"
              tick={{ fill: theme.tokens.secondary }}
              label={{
                value: getXaxisLabel(timePeriod, startDate, endDate),
                position: 'insideBottomRight', // Position at the end of X-axis
                dy: -12,
                dx: 40, // Adjust dy to position it better
                fill: theme.tokens.secondary,
                offset: 0,
              }}
              tickFormatter={xAxisTickFormatter} // Use custom tick formatter
            />
            <YAxis
              tickFormatter={yAxisTickFormatter}
              tick={{ fill: theme.tokens.secondary }}
              label={{
                value: 'kWh',
                // angle: -90,  // Rotate the label
                position: 'insideTopLeft', // Position at the end of Y-axis
                dy: -30,
                dx: 25, // Adjust dy to position it better
                fill: theme.tokens.secondary,
                offset: 5, // Adjust if needed to fine-tune position
              }}
            />
            <Tooltip content={<CustomTooltip />} />
            <ReferenceLine y={0} stroke="#000" />
            <Bar dataKey="soldEnergy" fill={theme.tokens.primary} />
            <Bar dataKey="purchasedEnergy" fill={theme.tokens.error} />
          </BarChart>
        </ResponsiveContainer>
      </GraphContainer>
    </>
  )
}

export default EnergyBarChart
