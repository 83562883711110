import { lazy, Suspense, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import { getErrorResponse } from '../../services/utils/helpers'
import {
  Stack,
  Link as MuiLink,
  IconButton,
  InputAdornment,
} from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import React from 'react'
import { setSession } from '../../services/utils/jwt'
import { userService } from '../../services/utils/axios'
import { useAuth } from '../../services/contexts/AuthContext'
import { hashPassword } from '../../services/utils/hash-password'
import LoadingButton from '../core/LoadingButton'

const ErrorFeedback = lazy(() => import('./Feedback'))

const initialValues = {
  email: '',
  password: '',
  feedback: { title: '', detail: '' },
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .max(255)
    .required('E-Mail ist erforderlich')
    .label('E-Mail'),
  password: Yup.string()
    .max(255)
    .required('Passwort wird benötigt')
    .label('Passwort'),
})

function SignIn() {
  const navigate = useNavigate()
  const { authenticate } = useAuth()
  const [loggedIn, setLoggedIn] = useState(false)

  const [showPassword, setShow] = useState(false)

  const handleClickShowPassword = () => {
    setShow(!showPassword)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  useEffect(() => {
    if (loggedIn) {
      navigate('/cockpit')
    }
  }, [loggedIn, navigate])

  /**
   * @method
   * @param {typeof initialValues} values - form values from formik
   * @param {import("formik").FormikHelpers<typeof initialValues>} actions
   */
  const handleSubmit = async (
    { email, password },
    { setStatus, setErrors, setSubmitting }
  ) => {
    try {
      const response = await userService.post('/login', {
        username: email,
        password,
        origin: 'dashboard',
      })
      const data = response.data?.data
      await hashPassword(password)
      authenticate(data?.access_token, data?.refresh_token)
      setSession(data?.access_token, data?.refresh_token)

      setStatus({ success: true, completed: true })
      setLoggedIn(true)
    } catch (error) {
      setSubmitting(false)
      setStatus({ success: false, completed: true })
      let errordata = getErrorResponse(error?.error?.errorCodeResponse)
      setErrors({ feedback: errordata })
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, status, setStatus, setFieldValue, isSubmitting }) => (
        <Form noValidate>
          <Suspense fallback={<div />}>
            <ErrorFeedback
              open={Boolean(errors.feedback?.detail || errors.feedback?.title)}
              severity="warning"
              title={errors.feedback?.title || ''}
              message={errors.feedback?.detail || ''}
              handleClose={() => {
                setStatus({ ...status, completed: false })
                setFieldValue('feedback', {
                  title: '',
                  detail: '',
                })
              }}
            />
          </Suspense>
          <Stack alignItems="center" mx="auto" maxWidth={320}>
            <Field
              name="email"
              margin="normal"
              label="E-Mail"
              component={TextField}
              sx={{ mb: 4 }}
              fullWidth
              required
            />
            <Field
              type={showPassword ? 'text' : 'password'}
              name="password"
              label="Passwort"
              margin="normal"
              component={TextField}
              sx={{ mb: 4 }}
              fullWidth
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Stack
            mt={2}
            spacing={2}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <LoadingButton
              type="submit"
              variant="contained"
              color="primary"
              sx={{ px: 12, my: 4 }}
              loading={isSubmitting}
            >
              Anmelden
            </LoadingButton>
            <MuiLink
              component={Link}
              to="/forgot-password"
              color="text.primary"
              underline="always"
              fontWeight={500}
            >
              Passwort vergessen?
            </MuiLink>
          </Stack>
        </Form>
      )}
    </Formik>
  )
}

export default SignIn
