import * as React from 'react'
import styled, { withTheme } from 'styled-components/macro'
import {
  Grid,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
} from '@mui/material'

import { Menu as MenuIcon } from '@mui/icons-material'

import NavbarUserDropdown from './NavbarUserDropdown'

const AppBar = styled(MuiAppBar)`
  background-color: ${(props) => props.theme.tokens.surface};
  z-index: -1; /* Set z-index to be behind all components */
  height: 320px;
`

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`
const CustomToolbar = styled(Toolbar)`
  && {
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 16px;
  }
`
const Navbar = ({ onDrawerToggle }) => {
  return (
    <React.Fragment>
      <Grid item>
        <NavbarUserDropdown />
      </Grid>
      <AppBar elevation={0}>
        <CustomToolbar>
          <Grid item sx={{ display: { xs: 'block', md: 'none' } }}>
            <Grid item xs={false} md={true}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item xs />
        </CustomToolbar>
      </AppBar>
    </React.Fragment>
  )
}

export default withTheme(Navbar)
