import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isBillingAddressSame: false, // Default value, you can set it based on the API response.
}

export const addressSlice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    toggleAddressSame: (state) => {
      state.isAddressSame = !state.isAddressSame
    },
    setBillingAddressSame: (state, action) => {
      state.isBillingAddressSame = action.payload
    },
  },
})

export const { toggleAddressSame, setBillingAddressSame } = addressSlice.actions
export default addressSlice.reducer
