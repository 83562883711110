import { useMemo, useEffect, useState } from 'react'
import React from 'react'
import PropTypes from 'prop-types'
import {
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  TextField as MuiTextField,
  Card,
  CardContent,
} from '@mui/material'
import shadows from '@mui/material/styles/shadows'
import Stack from '@mui/material/Stack'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { format } from 'date-fns'
import api from '../../../Middleware/api/api'
import { useAuth } from '../../../services/contexts'
import {
  RadioContainer,
  HiddenRadio,
  RadioLabel,
  RadioCustom,
  TextContainer,
} from './styles'
import ErrorFeedback from '../../../components/DataTable/Feedback'
import { useDispatch } from 'react-redux'
import { setBillingAddressSame } from '../../../redux/slices/addressSlice'

/**
 * This component is the form that is used in edit workflow to get user details.
 * We are using Formik for forms validaiton in all out forms.
 *
 */
function EditUserDetails({
  payload,
  setCanNext,
  setPayload,
  confirmPayload,
  submit,
  setSubmit,
}) {
  const { t } = useTranslation()
  const { token } = useAuth()
  const [streets, setStreets] = useState([payload?.address?.street] || [])
  const [showError, setShowError] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setBillingAddressSame(payload?.addressSame || false))
  }, [dispatch, payload?.addressSame])

  const closeFeedback = () => {
    setShowError(false)
    setStreets([null])
  }

  const card = (
    <>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {t('LBLBillingAddressMsg')}
        </Typography>
      </CardContent>
    </>
  )

  useEffect(() => {
    if (submit > 0) document.getElementById('sub').click()
    setSubmit(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit])

  const handleSelectChange = (e, setFieldValue) => {
    setFieldValue('street', e.target.value)
  }

  const handlePostalCodeChange = async (e, setFieldValue) => {
    const newPostalCode = e.target.value
    setFieldValue('postalCode', newPostalCode)

    if (newPostalCode.length === 5) {
      try {
        const { data } = await api.addressVerification(token, newPostalCode)
        if (data) {
          setStreets(data?.data?.streets || [])
          setFieldValue('city', data?.data?.city)
        } else {
          setFieldValue('postalCode', '')
          setShowError(true)
        }
      } catch (error) {
        setFieldValue('postalCode', '')
        setFieldValue('street', [null])
        setShowError(true)
      }
    }
  }
  const [firstName, ...lastNameArr] = (payload?.fullName || '').split(' ')
  const lastName = lastNameArr.join(' ')
  const initialValues = {
    salutation: payload?.salutation || '',
    firstName: firstName || '',
    lastName: lastName || '',
    firmaName: payload?.fullName || '',
    emailAddress: payload?.emailAddress || '',
    phoneNumber: payload?.phoneNumber || '',
    dateOfBirth: payload?.dateOfBirth || '',
    street: payload?.address?.street || [],
    houseNr: payload?.address?.houseNr || '',
    city: payload?.address?.city || '',
    postalCode: payload?.address?.postalCode || '',
    registeredCourt: payload?.registeredCourt || '',
    registrationNumber: payload?.registrationNumber || '',
    addressSame: payload?.addressSame || false,
  }

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        salutation: Yup.string()
          .required(t('LBLsalutationreq'))
          .label(t('LBLSalutation')),
        firstName: Yup.string().required(t('LBLFullNameReq')),
        lastName: Yup.string().when('salutation', {
          is: (val) => val !== 'Firma',
          then: () => Yup.string().required(t('LBLFullNameReq')),
        }),
        firmaName: Yup.string().when('salutation', {
          is: (val) => val === 'Firma',
          then: () => Yup.string().required(t('LBLFirmaNameReq')),
        }),
        emailAddress: Yup.string()
          .email(t('wrongEmail'))
          .required(t('LBLEmailReq'))
          .label('E-Mail'),
        street: Yup.string().when('salutation', {
          is: (val) => val !== 'Firma',
          then: () =>
            Yup.string()
              .required(t('LBLStNameReq'))
              .typeError(t('LBLStreetError'))
              .label('LBLStName'),
        }),
        city: Yup.string().when('salutation', {
          is: (val) => val !== 'Firma',
          then: () => Yup.string().required('LBLLocReq'),
        }),
        postalCode: Yup.number().when('salutation', {
          is: (val) => val !== 'Firma',
          then: () =>
            Yup.number()
              .required(t('LBLPostalCodeError'))
              .label(t('PostalCode')),
        }),
        dateOfBirth: Yup.date().when('salutation', {
          is: (val) => val !== 'Firma',
          then: () =>
            Yup.date().required(t('LBLBirthDate')).label(t('PostalCode')),
        }),
        phoneNumber: Yup.string().label(t('LBLHandy')),
        registeredCourt: Yup.string().when('salutation', {
          is: (val) => val === 'Firma',
          then: () => Yup.string().required(t('LBLRegCourtReq')),
        }),
        registrationNumber: Yup.string().when('salutation', {
          is: (val) => val === 'Firma',
          then: () => Yup.string().required(t('LBLRegNumReq')),
        }),
        addressSame: Yup.bool().label('LBLAddressSame'),
      }),
    [t]
  )

  /** @type {import("formik").FormikConfig<typeof initialValues>['onSubmit']} */
  const handleSubmit = async (values, actions) => {
    const payload = {
      salutation: values?.salutation,
      fullName:
        values?.salutation === 'Firma'
          ? values?.firmaName
          : `${values?.firstName} ${values?.lastName}`,
      registeredCourt: values?.registeredCourt || '',
      registrationNumber: values?.registrationNumber || '',
      emailAddress: values?.emailAddress,
      phoneNumber: values?.phoneNumber,
      dateOfBirth: format(new Date(values?.dateOfBirth), 'yyyy-MM-dd'),
      address: {
        city: values?.city,
        street: values?.street,
        houseNr: values?.houseNr,
        postalCode: values?.postalCode,
      },
      addressSame: values?.addressSame,
    }

    setPayload(payload)
    confirmPayload()
    actions.setSubmitting(false)
    actions.setTouched({}, false)
    actions.setErrors({})
    actions.setFieldError({})
    actions.setFieldTouched({}, false, false)
  }

  return (
    <>
      <Formik
        //enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount={true}
        onSubmit={handleSubmit}
        validateOnChange={true}
        validateOnBlur={true}
      >
        {({
          errors,
          touched,
          values,
          setFieldValue,
          setFieldError,
          setFieldTouched,
          isValid,
          handleChange,
          validateField,
        }) => (
          <Form noValidate>
            <>
              {setCanNext(touched && isValid)}
              <Stack my={5} direction="row" alignItems="center" spacing={8}>
                <Field name="salutation">
                  {({ field }) => (
                    <>
                      <RadioContainer>
                        <HiddenRadio
                          {...field}
                          id="male"
                          value="Mr"
                          checked={field.value === 'Mr'}
                          name="salutation"
                          onChange={() => setFieldValue('salutation', 'Mr')}
                        />
                        <RadioLabel htmlFor="male">
                          <RadioCustom />
                          Herr
                        </RadioLabel>
                      </RadioContainer>

                      <RadioContainer>
                        <HiddenRadio
                          {...field}
                          id="female"
                          value="Ms"
                          checked={field.value === 'Ms'}
                          name="salutation"
                          onChange={() => setFieldValue('salutation', 'Ms')}
                        />
                        <RadioLabel htmlFor="female">
                          <RadioCustom />
                          Frau
                        </RadioLabel>
                      </RadioContainer>

                      <RadioContainer>
                        <HiddenRadio
                          {...field}
                          id="firma"
                          value="Firma"
                          checked={field.value === 'Firma'}
                          name="salutation"
                          onChange={() => setFieldValue('salutation', 'Firma')}
                        />
                        <RadioLabel htmlFor="firma">
                          <RadioCustom />
                          Firma
                        </RadioLabel>
                      </RadioContainer>
                    </>
                  )}
                </Field>
              </Stack>
              {/* If Selection is firma */}
              {values.salutation === 'Firma' ? (
                <>
                  <Stack my={5} direction="row" alignItems="center" spacing={8}>
                    <Field
                      fullWidth
                      margin="none"
                      component={TextField}
                      id="firmaName"
                      name="firmaName"
                      label={t('LBLFirmaName')}
                      required
                      InputProps={{
                        title: 'Bitte füllen Sie dieses Feld aus',
                      }}
                      // style={{ width: "400px" }}
                    />

                    <Field
                      fullWidth
                      margin="none"
                      component={TextField}
                      id="registeredCourt"
                      name="registeredCourt"
                      label={t('LBLRegisterCourt')}
                      required
                      InputProps={{
                        title: 'Bitte füllen Sie dieses Feld aus',
                      }}
                      error={errors.registeredCourt && touched.registeredCourt}
                      helperText={
                        touched.registeredCourt && errors.registeredCourt
                      }
                      // style={{ width: "400px" }}
                    />
                  </Stack>
                  <Stack mb={5} direction="row" alignItems="center" spacing={8}>
                    <Field
                      fullWidth
                      margin="none"
                      component={TextField}
                      id="registrationNumber"
                      name="registrationNumber"
                      label={t('LBLRegisterNumber')}
                      InputProps={{
                        title: 'Bitte füllen Sie dieses Feld aus',
                      }}
                      required
                      error={
                        errors.registrationNumber && touched.registrationNumber
                      }
                      helperText={
                        touched.registrationNumber && errors.registrationNumber
                      }
                    />
                    <Field
                      fullWidth
                      margin="none"
                      component={TextField}
                      id="phoneNumber"
                      name="phoneNumber"
                      label={t('LBLHandy')}
                      InputProps={{
                        title: 'Bitte füllen Sie dieses Feld aus',
                      }}
                      required
                    />
                  </Stack>
                  <Stack mb={5} direction="row" alignItems="center" spacing={8}>
                    <Field
                      fullWidth
                      margin="none"
                      component={TextField}
                      id="emailAddress"
                      name="emailAddress"
                      label="E-Mail"
                      InputProps={{
                        title: 'Bitte füllen Sie dieses Feld aus',
                      }}
                      required
                    />
                  </Stack>
                </>
              ) : (
                <>
                  <Stack my={5} direction="row" alignItems="center" spacing={8}>
                    <Field
                      fullWidth
                      margin="none"
                      component={TextField}
                      id="firstName"
                      name="firstName"
                      label={t('LBLFirstName')}
                      required
                      InputProps={{
                        title: 'Bitte füllen Sie dieses Feld aus',
                      }}
                      // style={{ width: "400px" }}
                    />

                    <Field
                      fullWidth
                      margin="none"
                      component={TextField}
                      id="lastName"
                      name="lastName"
                      label={t('LBLLastName')}
                      required
                      InputProps={{
                        title: 'Bitte füllen Sie dieses Feld aus',
                      }}
                      // style={{ width: "400px" }}
                    />
                  </Stack>
                  <Stack mb={5} direction="row" spacing={8}>
                    <Field
                      fullWidth
                      required
                      margin="none"
                      component={TextField}
                      id="postalCode"
                      name="postalCode"
                      label={t('PostalCode')}
                      value={values.postalCode}
                      InputProps={{
                        title: 'Bitte füllen Sie dieses Feld aus',
                      }}
                      onChange={(e) => handlePostalCodeChange(e, setFieldValue)}
                    />
                    <MuiTextField
                      select
                      fullWidth
                      margin="none"
                      id="street"
                      name="street"
                      label={t('lblStName')}
                      required
                      InputProps={{
                        title: 'Bitte füllen Sie dieses Feld aus',
                      }}
                      error={Boolean(errors.street && touched.street)}
                      helperText={touched.street && errors.street}
                      defaultValue={payload?.address?.street}
                      value={values.street}
                      onChange={(e) => handleSelectChange(e, setFieldValue)}
                    >
                      {streets.map((street) => (
                        <MenuItem key={street} value={street}>
                          {street}
                        </MenuItem>
                      ))}
                    </MuiTextField>
                  </Stack>
                  <Stack mb={5} direction="row" spacing={8}>
                    <Field
                      fullWidth
                      margin="none"
                      component={TextField}
                      id="houseNr"
                      name="houseNr"
                      label={t('LBLHouseNr')}
                      InputProps={{
                        title: 'Bitte füllen Sie dieses Feld aus',
                      }}
                    />
                    <Field
                      required
                      fullWidth
                      margin="none"
                      component={TextField}
                      id="city"
                      name="city"
                      label={t('city')}
                      InputProps={{
                        title: 'Bitte füllen Sie dieses Feld aus',
                      }}
                      onChange={handleChange}
                      value={values.city}
                    />
                  </Stack>
                  <Stack mb={5} direction="row" spacing={8}>
                    <Field
                      fullWidth
                      margin="none"
                      component={TextField}
                      id="phoneNumber"
                      name="phoneNumber"
                      label={t('LBLHandy')}
                      InputProps={{
                        title: 'Bitte füllen Sie dieses Feld aus',
                      }}
                      required
                      sx={{ width: '500px' }}
                    />

                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale="de"
                    >
                      <DatePicker
                        label={t('LBLBirthDate')}
                        //minDate={dayjs(minStartDate)}
                        PaperProps={{
                          sx: {
                            boxShadow: shadows[3],
                          },
                        }}
                        value={values.dateOfBirth}
                        id={'dateOfBirth'}
                        onChange={(newValue) => {
                          setFieldValue(
                            'dateOfBirth',
                            newValue ? newValue.toDate() : ''
                          )
                        }}
                        renderInput={(params) => (
                          <Field
                            component={TextField}
                            {...params}
                            required
                            name="dateOfBirth"
                            margin="none"
                            autoComplete="off"
                            helperText={
                              touched.dateOfBirth && errors.dateOfBirth
                            }
                            error={errors.dateOfBirth && touched.dateOfBirth}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: 'TT.MM.JJJJ',
                            }}
                            sx={{ width: '500px' }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Stack>
                  <Stack mb={5} direction="row" spacing={8}>
                    <Field
                      fullWidth
                      margin="none"
                      component={TextField}
                      id="emailAddress"
                      name="emailAddress"
                      label="E-Mail"
                      InputProps={{
                        title: 'Bitte füllen Sie dieses Feld aus',
                      }}
                      required
                    />
                  </Stack>
                </>
              )}

              <Stack my={2} direction="row" alignItems="center" spacing={1}>
                <FormControlLabel
                  control={
                    <Field
                      component={Checkbox}
                      type="checkbox"
                      id="addressSame"
                      name="addressSame"
                      onChange={(event) => {
                        const isChecked = event.target.checked
                        setFieldValue('addressSame', isChecked)
                        dispatch(setBillingAddressSame(isChecked))
                      }}
                      checked={values.addressSame}
                    />
                  }
                />
                <TextContainer>
                  <Card variant="outlined">{card}</Card>
                </TextContainer>
              </Stack>
              <Button sx={{ display: 'none' }} id="sub" type="submit"></Button>
            </>
          </Form>
        )}
      </Formik>
      <ErrorFeedback
        open={showError}
        title={t('LBLErrorTitle')}
        severity="warning"
        message={
          <Typography variant="subtitle1" sx={{ width: 400, mx: 10 }}>
            {t('LBLAuwAreaError')}
          </Typography>
        }
        handleClose={closeFeedback}
      />
    </>
  )
}
EditUserDetails.propTypes = {
  payload: PropTypes.objectOf(PropTypes.any),
  setPayload: PropTypes.func.isRequired,
  confirmPayload: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
}

export default EditUserDetails
