import UserInformation from './UserInformation'
import React from 'react'
import BankInformation from './BankInformation'
import OtherData from './OtherData'
import GenerationPlantDetails from './GenerationPlantDetails'
import { Grid } from '@mui/material'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import LogoLoader from '../../../components/LogoLoader'

const PaperItem = styled(Paper)`
  && {
    background-color: transparent;
    margin-top: 30px;
  }
`

/**
 * This components holds four different components that are used to show the details of contract of a user.
 * components are: UserInformation, GenerationPlantDetails, OtherData and BankInformation
 *
 * @param {contract} param0 - this contract has the data before editing or after updating the details.
 * @returns {React.ReactNode} - this component return the data cards that presents the details of the contract, on user details (Nutzerdetails) page.
 */
const UserDetails = ({ contract }) => {
  return (
    <>
      {contract ? (
        <>
          <PaperItem>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} sm={5.5}>
                <UserInformation contract={contract} />
              </Grid>
              <Grid item xs={12} sm={5.5}>
                <GenerationPlantDetails contract={contract} />
              </Grid>
              <Grid item xs={12} sm={5.5}>
                <OtherData contract={contract} />
              </Grid>
              <Grid item xs={12} sm={5.5}>
                <BankInformation contract={contract} />
              </Grid>
            </Grid>
          </PaperItem>
        </>
      ) : (
        <LogoLoader loading={true} />
      )}
    </>
  )
}

export default UserDetails
