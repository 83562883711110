import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import BarChart from './BarChart'
import { HeaderTypography, WelcomeTypography } from './styles'
import { useUser } from '../../services/contexts'

/**
 * This is the entry point for dashboard front page.
 * It holds all the KPIs and grpah.
 *
 * @returns {React.ReactNode} A React component that renders all the KPIs.
 */
function Default() {
  const { t } = useTranslation()
  const { user } = useUser()

  return (
    <React.Fragment>
      <Helmet title="Default Dashboard" />
      <Grid container spacing={1}>
        {/* Welcome message and page title */}
        <Grid item xs={12} lg={12}>
          <WelcomeTypography variant="h6" gutterBottom display="inline">
            {t('LBLCockpitTitle')},{' '}
            {user?.operator === null ? '' : user?.operator}
          </WelcomeTypography>
          <HeaderTypography variant="h2" gutterBottom display="inline">
            {t('LBLCockpit')}
          </HeaderTypography>
        </Grid>
        <div style={{ height: '100px' }} />
        {/* BarChart component */}
        <Grid item xs={12} lg={12}>
          <BarChart />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
export default Default
