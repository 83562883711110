import { useState } from 'react'
import React from 'react'
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts'
import { useTheme } from 'styled-components'
import biomassImg from './Assets/biomasse_oli.svg'
import networkImg from './Assets/netzstrom_oli.svg'
import solarImg from './Assets/solar_oli.svg'
import wasserImg from './Assets/wasserkraft_oli.svg'
import windImg from './Assets/wind_oli.svg'
import { formatDecimal } from '../../services/utils/format-numbers'

/**
 * This component holds all the Pie Charts that are shown on Energy Mix page.
 *
 * @param {energyData} param0 - this is the energy data that is fetched to show on the Pie Charts in Energy Mix Page.
 * @returns {React.ReactNode} - It returned the Pie Charts to show on Energy Mix Page.
 */

const ChartPie = ({ energyData }) => {
  const theme = useTheme()
  const [allZeroData] = useState([
    { label: 'BIO_MASS', value: 0.0000001, image: biomassImg },
    { label: 'SOLAR_POWER', value: 0.0000001, image: solarImg },
    { label: 'HYDRO_POWER', value: 0.0000001, image: wasserImg },
    { label: 'WIND_ENERGY', value: 0.0000001, image: windImg },
    { label: 'NETWORK', value: 0.0000001, image: networkImg },
  ])

  //  This is to transform the data to show in pie charts.
  const transformedData = energyData?.data?.totalConsumptionByEnergySources
    ? [
        {
          label: 'BIO_MASS',
          value:
            (energyData?.data?.totalConsumptionByEnergySources?.BIO_MASS /
              energyData?.data?.totalDemand) *
            100,
          image: biomassImg,
        },
        {
          label: 'SOLAR_POWER',
          value:
            (energyData?.data?.totalConsumptionByEnergySources?.SOLAR_POWER /
              energyData?.data?.totalDemand) *
            100,
          image: solarImg,
        },
        {
          label: 'HYDRO_POWER',
          value:
            (energyData?.data?.totalConsumptionByEnergySources?.HYDRO_POWER /
              energyData?.data?.totalDemand) *
            100,
          image: wasserImg,
        },
        {
          label: 'WIND_ENERGY',
          value:
            (energyData?.data?.totalConsumptionByEnergySources?.WIND_ENERGY /
              energyData?.data?.totalDemand) *
            100,
          image: windImg,
        },
        {
          label: 'NETWORK',
          value:
            (energyData?.data?.totalConsumptionByEnergySources?.GRID /
              energyData?.data?.totalDemand) *
            100,
          image: networkImg,
        },
      ]
    : allZeroData

  const allZero = transformedData.every((item) => item.value === 0)

  const filteredData =
    allZero || !transformedData
      ? allZeroData
      : transformedData.filter((item) => item.value > 0)
  const dataToUse =
    energyData?.data?.totalConsumptionByEnergySources != null
      ? filteredData
      : allZeroData
  const CustomLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    value,
    index,
  }) => {
    const RADIAN = Math.PI / 180
    const radius = innerRadius + (outerRadius - innerRadius) * 1.7
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)
    return (
      <g>
        <image
          href={dataToUse[index].image}
          x={x - 30}
          y={y - 10}
          width={24}
          height={24}
        />
        <text
          x={x + 20}
          y={y}
          fill={theme.tokens.secondary}
          textAnchor="middle"
          dominantBaseline="central"
        >
          {formatDecimal(value)}%
        </text>
      </g>
    )
  }

  const COLORS = {
    BIO_MASS: theme.tokens.primary,
    SOLAR_POWER: theme.tokens.customColorSignal,
    HYDRO_POWER: theme.tokens.secondaryFixedDim,
    WIND_ENERGY: theme.tokens.secondary,
    NETWORK: theme.tokens.outline,
  }

  return (
    <>
      <ResponsiveContainer width="90%" height={400}>
        <PieChart>
          <Pie
            data={dataToUse}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={<CustomLabel />}
            outerRadius={110}
            innerRadius={60}
            fill="#8884d8"
            dataKey="value"
          >
            {dataToUse.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={
                  dataToUse === allZeroData
                    ? theme.tokens.outline
                    : COLORS[entry.label]
                }
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </>
  )
}

export default ChartPie
