import React, { useState, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { Helmet } from 'react-helmet-async'
import shadows from '@mui/material/styles/shadows'
import { Grid, Box, Typography } from '@mui/material'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import CheckIcon from '@mui/icons-material/Check'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { DatePicker, TimePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import format from 'date-fns/format'
import { formatDecimal } from '../../services/utils/format-numbers'
import { useAuth } from '../../services/contexts'
import api from '../../Middleware/api/api'
import {
  HeaderTypography,
  StyledMenuButton,
  StyledButton,
  StyledDiv,
  StyledLabel,
  StyledLabelNonBold,
  StyledTextField,
  StyledTimePicker,
  OuterSurface,
  ButtonGroup,
  StyledText,
  ChartBox,
  ChartDiv,
} from './style'
import ChartPie from './ChartPie'
import Feedback from '../../components/Feedback'
import ActiveUsersTable from './ActiveUsersTable'
import ErrorFeedback from '../../components/DataTable/Feedback'
import biomassImg from './Assets/biomasse_oli.svg'
import networkImg from './Assets/netzstrom_oli.svg'
import solarImg from './Assets/solar_oli.svg'
import wasserImg from './Assets/wasserkraft_oli.svg'
import windImg from './Assets/wind_oli.svg'
import ChartPieProducer from './ChartPieProducer'

function calculatedates(days) {
  let date = new Date()
  let last = new Date(date.getTime() - days * 24 * 60 * 60 * 1000)
  return (
    last.getFullYear() +
    '-' +
    last.toLocaleString('en-US', { month: '2-digit' }) +
    '-' +
    last.toLocaleString('en-US', { day: '2-digit' })
  )
}

const ListOfEnergySourceBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  padding: '24px 0px 0px 0px',
  position: 'relative',
  alignItems: 'end',
  marginLeft: 'auto',
  marginRight: '80px',
  '& > *': {
    marginRight: '10px',
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  [theme.breakpoints.up('xs')]: {
    top: '10px',
  },
  [theme.breakpoints.up('sm')]: {
    top: '20px',
  },
  [theme.breakpoints.up('md')]: {
    top: '40px',
  },
  [theme.breakpoints.up('lg')]: {
    top: '20px',
    marginRight: '20px',
  },
  [theme.breakpoints.up('xl')]: {
    top: '20px',
  },
}))
/**
 * It is the main component that holds all other components on Energy Mix page.
 *
 * @returns (React.ReactNode) - It returns all the react component on the Energy Mix screen.
 */
const EnergyMix = () => {
  const { t, i18n } = useTranslation()
  const { token } = useAuth()
  const [selected, setSelected] = useState('YEAR')
  const [startDate, setStartDate] = useState(calculatedates(1))
  const [endDate, setEndDate] = useState(calculatedates(0))
  const [endTime, setEndTime] = useState(new Date())
  const [startTime, setStartTime] = useState(new Date())
  const [energyData, setEnergyData] = useState([])
  const [error, setError] = useState(false)
  const [showError, setShowError] = useState(false)
  const [timePeriod, setTimePeriod] = useState(['YEAR'])
  const [activeUserEnergyData, setActiveUserEnergyData] = useState([])

  const handleButtonClick = (buttonType) => {
    setSelected(buttonType)
    setTimePeriod(buttonType)
  }

  const closeFeedback = () => {
    setError(false)
    setShowError(false)
  }

  useEffect(() => {
    fetchEnergySummaryData(
      startDate,
      endDate,
      format(startTime, 'HH:mm'),
      format(endTime, 'HH:mm')
    )
    fetchActiveUserEnergyData(
      startDate,
      endDate,
      format(startTime, 'HH:mm'),
      format(endTime, 'HH:mm')
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timePeriod])

  /**
   * This is an API call to get the data for Pie Chart on Energy Mix page.
   *
   * Following are the params for fetching the data for a particular time period on the basis of dates and time.
   * @param {string} startDate -
   * @param {string} endDate -
   * @param {string} startTime -
   * @param {string} endTime -
   *
   * * @example
   * For detail reponses you can visit the following file:
   * [API Respnse Documentation](./src/docs/apiResponse.md)
   */

  const fetchEnergySummaryData = (startDate, endDate, startTime, endTime) => {
    try {
      const params = new URLSearchParams({
        dateUnit: timePeriod,
        startDate: startDate,
        endDate: endDate,
        startTime: startTime,
        endTime: endTime,
      })

      api.getEnergySummaryKpi(token, params).then((response) => {
        if (response.data.data !== null) {
          setEnergyData(response.data)
        } else if (response.data.data == null) {
          setError(true)
          setEnergyData(null)
        }
      })
    } catch (exception) {
      setEnergyData(null)
      setShowError(true)
    }
  }
  /**
   * This is an API call to get the data for the table of Active Users on Energy Mix page.
   *
   * Following are the params for fetching the data for a particular time period on the basis of dates and time.
   * @param {string} startDate -
   * @param {string} endDate -
   * @param {string} startTime -
   * @param {string} endTime -
   *
   * * @example
   * For detail reponses you can visit the following file:
   * [API Respnse Documentation](./src/docs/apiResponse.md)
   */
  const fetchActiveUserEnergyData = (
    startDate,
    endDate,
    startTime,
    endTime
  ) => {
    try {
      const params = new URLSearchParams({
        dateUnit: timePeriod,
        startDate: startDate,
        endDate: endDate,
        startTime: startTime,
        endTime: endTime,
      })

      api.getActiveUsersEnergyDetails(token, params).then((response) => {
        if (response.data.data !== null) {
          setActiveUserEnergyData(response?.data?.data)
        } else if (response.data.data == null) {
          setActiveUserEnergyData([])
        }
      })
    } catch (exception) {
      setActiveUserEnergyData([])
      setShowError(true)
    }
  }

  const initialValues = {
    startDate: startDate,
    endDate: endDate,
    startTime: startTime,
    endTime: endTime,
  }
  const validationSchema = useMemo(
    () =>
      Yup.object({
        startDate: Yup.date().required(i18n.t('LBLStartDateReq')),
        endDate: Yup.date()
          .min(Yup.ref('startDate'), i18n.t('LBLValidDateError'))
          .required(i18n.t('LBLEndDateReq')),
      }),
    [i18n]
  )

  const handleSubmit = (values, actions) => {
    setStartDate(values.startDate)
    setEndDate(values.endDate)
    setStartTime(values.startTime)
    setEndTime(values.endTime)
    fetchEnergySummaryData(
      values.startDate,
      values.endDate,
      format(values.startTime, 'HH:mm'),
      format(values.endTime, 'HH:mm')
    )
    fetchActiveUserEnergyData(
      values.startDate,
      values.endDate,
      format(values.startTime, 'HH:mm'),
      format(values.endTime, 'HH:mm')
    )

    actions.setSubmitting(false)
    actions.setTouched({}, true)
    actions.setErrors({})
    actions.setFieldError({})
    actions.setFieldTouched({}, true, true)
  }
  return (
    <React.Fragment>
      <Helmet title="Nutzers" />

      <Grid
        justifyContent="space-between"
        container
        spacing={10}
        sx={{ visibility: 'transparent' }}
      >
        <Grid item>
          <HeaderTypography variant="h2" gutterBottom display="inline">
            {t('LBLEnergyMix')}
          </HeaderTypography>
        </Grid>
      </Grid>
      <OuterSurface>
        <ButtonGroup>
          <StyledMenuButton
            variant={selected === 'YESTERDAY' ? 'contained' : 'outlined'}
            className={selected === 'YESTERDAY' ? 'selected' : ''}
            onClick={() => handleButtonClick('YESTERDAY')}
          >
            {selected === 'YESTERDAY' && <CheckIcon />} {t('LBLYesterday')}
          </StyledMenuButton>
          <StyledMenuButton
            variant={selected === 'YEAR' ? 'contained' : 'outlined'}
            className={selected === 'YEAR' ? 'selected' : ''}
            onClick={() => handleButtonClick('YEAR')}
          >
            {selected === 'YEAR' && <CheckIcon />} {t('LBLYear')}
          </StyledMenuButton>
          <StyledMenuButton
            variant={selected === 'TIMEPERIOD' ? 'contained' : 'outlined'}
            className={selected === 'TIMEPERIOD' ? 'selected' : ''}
            onClick={() => handleButtonClick('TIMEPERIOD')}
          >
            {selected === 'TIMEPERIOD' && <CheckIcon />} {t('LBLTimePeriod')}
          </StyledMenuButton>
        </ButtonGroup>

        {/* selection of time frame */}

        {selected === 'TIMEPERIOD' && (
          <>
            <StyledDiv>
              <Box display="flex" alignItems="center" marginTop={2}>
                <Formik
                  enableReinitialize={true}
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  validateOnMount={true}
                  onSubmit={handleSubmit}
                  validateOnChange={true}
                >
                  {({ errors, touched, values, setFieldValue }) => (
                    <Form noValidate>
                      <>
                        <Grid container spacing={18} alignItems="center">
                          <Grid item xs={12} sm={6} md={5} lg={4}>
                            <StyledLabel variant="h5">
                              {t('LBLStartDate')}
                            </StyledLabel>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                  adapterLocale="de"
                                >
                                  <DatePicker
                                    id={'startDate'}
                                    value={values.startDate}
                                    maxDate={dayjs()}
                                    onChange={(newValue) => {
                                      setFieldValue(
                                        'startDate',
                                        newValue
                                          ? format(
                                              newValue.toDate(),
                                              'yyyy-MM-dd'
                                            )
                                          : ''
                                      )
                                    }}
                                    renderInput={(params) => (
                                      <StyledTextField
                                        {...params}
                                        required
                                        name="startDate"
                                        margin="none"
                                        autoComplete="off"
                                        helperText={
                                          touched.startDate &&
                                          t(errors.startDate)
                                        }
                                        error={
                                          t(errors.startDate) &&
                                          touched.startDate
                                        }
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              </Grid>
                              <Grid item xs={6}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                  adapterLocale="de"
                                >
                                  <TimePicker
                                    PaperProps={{
                                      sx: {
                                        boxShadow: shadows[3],
                                      },
                                    }}
                                    value={values.startTime}
                                    id={'startTime'}
                                    minutesStep={15}
                                    onChange={(newValue) => {
                                      setFieldValue(
                                        'startTime',
                                        newValue.toDate()
                                      )
                                    }}
                                    renderInput={(params) => (
                                      <StyledTimePicker
                                        {...params}
                                        name={'startTime'}
                                        margin="none"
                                        autoComplete="off"
                                        helperText={
                                          touched.startTime &&
                                          t(errors.startTime)
                                        }
                                        error={
                                          t(errors.startTime) &&
                                          touched.startTime
                                        }
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <StyledLabel variant="h5">
                              {t('LBLEndDate')}
                            </StyledLabel>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                  adapterLocale="de"
                                >
                                  <DatePicker
                                    id={'endDate'}
                                    value={values.endDate}
                                    maxDate={dayjs()}
                                    onChange={(newValue) => {
                                      setFieldValue(
                                        'endDate',
                                        newValue
                                          ? format(
                                              newValue.toDate(),
                                              'yyyy-MM-dd'
                                            )
                                          : ''
                                      )
                                    }}
                                    renderInput={(params) => (
                                      <StyledTextField
                                        {...params}
                                        required
                                        name="endDate"
                                        margin="none"
                                        autoComplete="off"
                                        helperText={
                                          touched.endDate && t(errors.endDate)
                                        }
                                        error={
                                          t(errors.endDate) && touched.endDate
                                        }
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              </Grid>
                              <Grid item xs={6}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                  adapterLocale="de"
                                >
                                  <TimePicker
                                    value={values.endTime}
                                    id={'endTime'}
                                    minutesStep={15}
                                    onChange={(newValue) => {
                                      setFieldValue(
                                        'endTime',
                                        newValue.toDate()
                                      )
                                    }}
                                    renderInput={(params) => (
                                      <StyledTimePicker
                                        {...params}
                                        name={'endTime'}
                                        margin="none"
                                        autoComplete="off"
                                        helperText={
                                          touched.endTime && t(errors.endTime)
                                        }
                                        error={
                                          t(errors.endTime) && touched.endTime
                                        }
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            sm={12}
                            md={4}
                            spacing={2}
                            sx={{
                              marginTop: 8,
                              paddingTop: 8,
                            }}
                          >
                            <Grid item>
                              <StyledButton
                                variant="contained"
                                color="primary"
                                type="submit"
                              >
                                <StyledText variant="h5">
                                  {t('LBLSubmit')}
                                </StyledText>
                              </StyledButton>
                            </Grid>
                            <Grid item>
                              <StyledButton
                                sx={{
                                  display: 'none',
                                }}
                              >
                                <StyledText variant="h5">
                                  <FileDownloadIcon
                                    sx={{
                                      verticalAlign: 'middle',
                                    }}
                                  />{' '}
                                  Export to .xls
                                </StyledText>
                              </StyledButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    </Form>
                  )}
                </Formik>
              </Box>
            </StyledDiv>
          </>
        )}

        {/* selection of YEAR or YESTERDAY */}

        {(selected === 'YEAR' || selected === 'YESTERDAY') && (
          <>
            <StyledDiv>
              <Box display="flex" alignItems="center" marginTop={2}>
                <Grid container spacing={30} alignItems="center">
                  <Grid item xs={12} sm={6} md={4}>
                    <StyledButton fullWidth sx={{ display: 'none' }}>
                      <StyledText variant="h5" align="left">
                        <FileDownloadIcon
                          sx={{
                            verticalAlign: 'middle',
                          }}
                        />{' '}
                        Export to .xls
                      </StyledText>
                    </StyledButton>
                  </Grid>
                </Grid>
              </Box>
            </StyledDiv>
          </>
        )}
        <ListOfEnergySourceBox>
          <StyledLabel>
            <img src={biomassImg} alt="Biomass" /> Biomasse
          </StyledLabel>
          <StyledLabel>
            <img src={solarImg} alt="Solar" /> Solar
          </StyledLabel>
          <StyledLabel>
            <img src={wasserImg} alt="Wasserkraft" /> Wasserkraft
          </StyledLabel>
          <StyledLabel>
            <img src={windImg} alt="Windstrom" /> Windstrom
          </StyledLabel>
          <StyledLabel>
            <img src={networkImg} alt="Fossil" /> Netzstrom
          </StyledLabel>
        </ListOfEnergySourceBox>
        {/* PIE CHART ON ENERGY MIX PAGE */}
        <ChartBox>
          <ChartDiv>
            <StyledLabelNonBold variant="h1">
              {t('LBLTotalProduction')}:{' '}
              {energyData?.data?.totalEnergyProduction
                ? formatDecimal(energyData?.data?.totalEnergyProduction) +
                  ' kWh'
                : '0,00 kWh'}
            </StyledLabelNonBold>
            <ChartPieProducer energyData={energyData} />
          </ChartDiv>
          <ChartDiv>
            <StyledLabelNonBold variant="h1">
              {t('LBLTotalConsumption')}:{' '}
              {energyData?.data?.totalDemand
                ? formatDecimal(energyData?.data?.totalDemand) + ' kWh'
                : '0,00 kWh'}
            </StyledLabelNonBold>

            <ChartPie energyData={energyData} />
          </ChartDiv>
        </ChartBox>

        {/* Table on Energy Mix page to show the active user contracts */}
        <ActiveUsersTable contract={activeUserEnergyData} />
      </OuterSurface>

      {/* Error messages */}
      <Feedback
        open={error}
        title={t('LBLDataInfo')}
        message={
          <>
            <Typography align="center">{t('LBLDataMsg')}</Typography>
          </>
        }
        handleClose={closeFeedback}
      />

      <ErrorFeedback
        open={showError}
        title={t('LBLErrorTitle')}
        severity="warning"
        message={
          <Typography variant="subtitle1" sx={{ width: 400, mx: 10 }}>
            {t('LBLErrorMsgForException')}
          </Typography>
        }
        handleClose={closeFeedback}
      />
    </React.Fragment>
  )
}

export default EnergyMix
