import React, { forwardRef } from 'react'
import styled from 'styled-components/macro'
import { NavLink } from 'react-router-dom'
import { rgba, darken } from 'polished'

import { Chip, Collapse, ListItemButton, ListItemText } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref}>
    <NavLink {...props} />
  </div>
))

const Item = styled(ListItemButton)`
  padding-top: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 2 : 3)};
  padding-bottom: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 2 : 3)};
  padding-left: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 14 : 8)};
  padding-right: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 4 : 7)};
  border-radius: 8px; /* Adding rounded corners */
  right: 10px;
  left: 10px;
  width: 220px;

  svg {
    color: ${(props) => props.theme.tokens.primary};
    width: 20px;
    height: 20px;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }
  &.${(props) => props.activeclassname} {
    background-color: ${(props) =>
      darken(0.01, props.theme.tokens.surfaceContainerLowest)};
    border-radius: 8px; /* Adding rounded corners */
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.2);
    right: 10px;
    left: 10px;
    width: 220px;

    svg {
      color: ${(props) => props.theme.tokens.secondary};
      width: 25px;
      height: 25px;
    }
  }
`

const Title = styled(ListItemText)`
  margin: 0;
  span {
    color: ${(props) =>
      rgba(
        props.theme.tokens.secondary,
        props.depth && props.depth > 0 ? 0.7 : 1
      )};
    font-size: ${(props) => props.theme.typography.navBarItems}px;
    padding: 0 ${(props) => props.theme.spacing(2)};
  }
`

const Badge = styled(Chip)`
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 26px;
  top: 12px;
  background: ${(props) => props.theme.sidebar.badge.background};
  z-index: 1;
  span.MuiChip-label,
  span.MuiChip-label:hover {
    font-size: 11px;
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)};
    padding-right: ${(props) => props.theme.spacing(2)};
  }
`

const ExpandLessIcon = styled(ExpandLess)`
  color: ${(props) => rgba(props.theme.tokens.secondary, 0.5)};
`

const ExpandMoreIcon = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.tokens.secondary, 0.5)};
`

const SidebarNavListItem = (props) => {
  const {
    title,
    href,
    depth = 0,
    children,
    icon: Icon,
    badge,
    open: openProp = false,
  } = props

  const [open, setOpen] = React.useState(openProp)

  const handleToggle = () => {
    setOpen((state) => !state)
  }

  if (children) {
    return (
      <React.Fragment>
        <Item depth={depth} onClick={handleToggle}>
          {Icon && <Icon />}
          <Title depth={depth}>
            {title}
            {badge && <Badge label={badge} />}
          </Title>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Item>
        <Collapse in={open}>{children}</Collapse>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <Item
        depth={depth}
        component={CustomRouterLink}
        to={href}
        activeclassname="active"
      >
        {Icon && <Icon />}
        <Title depth={depth}>
          {title}
          {/* {badge && <Badge label={badge} />} */}
        </Title>
      </Item>
    </React.Fragment>
  )
}

export default SidebarNavListItem
