import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components/macro'

import { WarningRounded } from '@mui/icons-material'

const WarnIcon = styled(WarningRounded)`
  width: 312px;
  height: 92px;
`
/**
 * If a route is not found then this component is used to render for user.
 *
 */
function RouteNotFound({ to, title, description, buttonText }) {
  return (
    <>
      <Dialog open={true} PaperProps={{ sx: { py: 5, px: 8 } }}>
        <DialogTitle
          sx={{
            textAlign: 'center',
            fontWeight: '600',
            fontSize: 'x-large',
            pb: 8,
          }}
        >
          {title}
        </DialogTitle>
        <WarnIcon
          color={'warning'}
          sx={{ display: 'flex', mx: 'auto', fontSize: 'xxx-large' }}
        />

        <DialogContent sx={{ textAlign: 'center', py: 5 }}>
          {description}
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: 'center',
          }}
        >
          <Button
            variant="contained"
            sx={{ px: 6 }}
            component={NavLink}
            to={to}
          >
            {buttonText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default RouteNotFound
