/**
 * This file contains all the API calls to backend. axios is the library that is used for this purpose.
 *
 */

const axios = require('axios')
// post contact us form data
const postFeedback = (accessToken, payload) => {
  const API_URL = `${window._env_.REACT_APP_BACKEND_FOR_FRONTEND_API}/mail/send`
  return axios.post(API_URL, payload, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
}
const refreshTokenRequest = (accessToken, requestBody) => {
  const API_URL = `${window._env_.REACT_APP_USER_SERVICE}/refresh-token`
  return axios.post(API_URL, requestBody, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
}
const dashboardData = (accessToken) => {
  const API_URL = `${window._env_.REACT_APP_CONTRACT_SERVICE}/dashboard`
  return axios.get(API_URL, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
}

const updateContractStatus = (accessToken, contractKey, contractStatus) => {
  const API_URL = `${window._env_.REACT_APP_CONTRACT_SERVICE}/update-status`
  const queryParams = {
    contractKey: contractKey,
    contractStatus: contractStatus,
  }
  return axios.put(API_URL, null, {
    params: queryParams,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
}

const updateContractDetails = (accessToken, contractKey, requestBody) => {
  const API_URL = `${window._env_.REACT_APP_CONTRACT_SERVICE}/update-contract/${contractKey}`
  return axios.put(API_URL, requestBody, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
}

const fetchContractData = (accessToken, contractKey) => {
  const API_URL = `${window._env_.REACT_APP_CONTRACT_SERVICE}/get-contract/${contractKey}`
  return axios.get(API_URL, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
}

const addressVerification = (accessToken, postalCode) => {
  const API_URL = `${window._env_.REACT_APP_CONTRACT_SERVICE}/address_verification/${postalCode}`
  return axios.get(API_URL, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
}

const getEnergySummaryKpi = (accessToken, params) => {
  const API_URL = `${window._env_.REACT_APP_CONTRACT_SERVICE}/energykpi`
  return axios.get(API_URL, {
    params: params,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
}

const getActiveUsersEnergyDetails = (accessToken, params) => {
  const API_URL = `${window._env_.REACT_APP_CONTRACT_SERVICE}/energykpi_users`
  return axios.get(API_URL, {
    params: params,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
}

const getSalePurchaseData = (accessToken, params) => {
  const API_URL = `${window._env_.REACT_APP_CONTRACT_SERVICE}/sales_purchases`
  return axios.get(API_URL, {
    params: params,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
}

//API to get the price adjustment overview
const getPriceOverviewData = (accessToken) => {
  const API_URL = `${window._env_.REACT_APP_CONTRACT_SERVICE}/get-price-adjustment-for-graph`
  return axios.get(API_URL, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
}

//API to adjust the prices of energy sources
const adjustingProductionPrices = (accessToken, requestBody) => {
  const API_URL = `${window._env_.REACT_APP_CONTRACT_SERVICE}/sales-price-adjustment`
  return axios.put(API_URL, requestBody, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
}

//API to get the updated price after adjustment
const getPriceAdjustment = (accessToken) => {
  const API_URL = `${window._env_.REACT_APP_CONTRACT_SERVICE}/get-price-adjustment-values-by-month`
  return axios.get(API_URL, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
}

// eslint-disable-next-line
export default {
  postFeedback: postFeedback,
  refreshTokenRequest: refreshTokenRequest,
  dashboardData: dashboardData,
  updateContractStatus: updateContractStatus,
  updateContractDetails: updateContractDetails,
  fetchContractData: fetchContractData,
  addressVerification: addressVerification,
  getEnergySummaryKpi: getEnergySummaryKpi,
  getActiveUsersEnergyDetails: getActiveUsersEnergyDetails,
  getSalePurchaseData: getSalePurchaseData,
  getPriceOverviewData: getPriceOverviewData,
  adjustingProductionPrices: adjustingProductionPrices,
  getPriceAdjustment: getPriceAdjustment,
}
