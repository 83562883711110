import styled from 'styled-components/macro'
import { Typography, Grid, Select, TextField } from '@mui/material'

export const StyledGridContainer = styled(Grid)`
  background-color: ${(props) => props.theme.tokens.tertiary};
  padding: 15px;
`
export const StyledSelect = styled(Select)`
  && {
    background-color: ${(props) => props.theme.tokens.surfaceBright};
    width: 100%;
  }
`

export const StyledLabel = styled(Typography)`
  && {
    color: ${(props) => props.theme.tokens.secondary};
    font-weight: bold;
    padding: 10px 5px;
  }
`

export const StyledText = styled(Typography)`
  && {
    color: ${(props) => props.theme.tokens.primary};
    width: 100%;
    text-align: center;
  }
`

export const StyledTextField = styled(TextField)`
  && {
    border-radius: 40px;
    background-color: ${(props) => props.theme.tokens.surfaceBright};
    height: 40px;
    align-items: center;
    display: relative;
    margin-left: 0;
    width: 80%;
    input::placeholder {
      color: ${(props) => props.theme.tokens.secondary};
      font-weight: bold;
    }
    input {
      color: ${(props) => props.theme.tokens.secondary};
      padding-left: 24px;
      padding-bottom: 4px;
    }
    @media (max-width: 959px) {
      width: 100%;
    }
  }
`
export const StyledSelectContainer = styled.div`
  display: relative;
  align-items: center;
  margin-right: 12px;
`
