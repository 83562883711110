import { SvgIcon } from '@mui/material'
import React from 'react'

const energyMatchingIcon = (props) => (
  <SvgIcon {...props}>
    <g id="data-report 1" clip-path="url(#clip0_667_11746)">
      <path
        id="Vector"
        d="M0.5 14.7637H15.5V15.7637H0.5V14.7637Z"
        fill="currentColor"
      />
      <path
        id="Vector_2"
        d="M2.75 1.26373C2.55109 1.26373 2.36032 1.34275 2.21967 1.4834C2.07902 1.62405 2 1.81482 2 2.01373V13.7637H5V2.01373C5 1.81482 4.92098 1.62405 4.78033 1.4834C4.63968 1.34275 4.44891 1.26373 4.25 1.26373H2.75Z"
        fill="currentColor"
      />
      <path
        id="Vector_3"
        d="M7.25 7.26373C7.05109 7.26373 6.86032 7.34275 6.71967 7.4834C6.57902 7.62406 6.5 7.81482 6.5 8.01373V13.7637H9.5V8.01373C9.5 7.81482 9.42098 7.62406 9.28033 7.4834C9.13968 7.34275 8.94891 7.26373 8.75 7.26373H7.25Z"
        fill="currentColor"
      />
      <path
        id="Vector_4"
        d="M11.75 11.7637C11.5511 11.7637 11.3603 11.8428 11.2197 11.9834C11.079 12.1241 11 12.3148 11 12.5137V13.7637H14V12.5137C14 12.3148 13.921 12.1241 13.7803 11.9834C13.6397 11.8428 13.4489 11.7637 13.25 11.7637H11.75Z"
        fill="currentColor"
      />
      <path
        id="flash"
        d="M13.5923 0.263963C13.5461 0.266483 13.5036 0.292996 13.4772 0.335245L10.0272 5.83495C9.95787 5.94545 10.029 6.09701 10.1503 6.097H12.5372L12.2507 10.0843C12.2395 10.2529 12.4351 10.3308 12.5228 10.1927L15.9728 4.69334C16.0421 4.58296 15.9711 4.43156 15.85 4.4313H13.4628L13.7496 0.443648C13.7568 0.343238 13.6829 0.258735 13.5923 0.263963Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_667_11746">
        <rect
          width="20"
          height="20"
          fill="currentColor"
          transform="translate(0 1)"
        />
      </clipPath>
    </defs>
  </SvgIcon>
)

//Home Scree Icon
const homeScreenIcon = (props) => (
  <SvgIcon {...props}>
    <g id="icon">
      <g id="Home">
        <path
          id="Vector"
          d="M12.5 14.3474H10.5C10.1022 14.3474 9.72065 14.1893 9.43934 13.908C9.15804 13.6267 9 13.2452 9 12.8474V10.3474C9 10.2147 8.94733 10.0876 8.85356 9.9938C8.75979 9.90003 8.63261 9.84735 8.5 9.84735H7.5C7.3674 9.84735 7.24022 9.90003 7.14645 9.9938C7.05268 10.0876 7 10.2147 7 10.3474V12.8474C7 13.2452 6.84197 13.6267 6.56066 13.908C6.27936 14.1893 5.89783 14.3474 5.5 14.3474H3.5C3.10218 14.3474 2.72065 14.1893 2.43934 13.908C2.15804 13.6267 2 13.2452 2 12.8474V8.67735C1.99942 8.34883 2.06359 8.02341 2.18885 7.7197C2.3141 7.41599 2.49799 7.13995 2.73 6.90735L6.935 2.70235C7.21625 2.42145 7.5975 2.26367 7.995 2.26367C8.39251 2.26367 8.77375 2.42145 9.055 2.70235L13.26 6.90735C13.4938 7.13912 13.6796 7.4148 13.8065 7.71854C13.9335 8.02229 13.9993 8.34813 14 8.67735V12.8474C14 13.2452 13.842 13.6267 13.5607 13.908C13.2794 14.1893 12.8978 14.3474 12.5 14.3474Z"
          fill="currentColor"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_667_11746">
        <rect
          width="20"
          height="20"
          fill="currentColor"
          transform="translate(0 1)"
        />
      </clipPath>
    </defs>
  </SvgIcon>
)

export { energyMatchingIcon, homeScreenIcon }
