import { useState, useEffect } from 'react'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Grid } from '@mui/material'
import {
  TableCellBox,
  CustomizedButton,
  HeaderTypography,
  OuterSurface,
  StyledDiv,
  SubHeadingTypography,
  MessageBox,
  IconWrapper,
  MessageDiv,
  CustomTextField,
} from './style'
import { useTranslation } from 'react-i18next'
import { InformationIcon } from '../../components/icons/myIcons'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import biomassImg from '../EnergyMix/Assets/biomasse_oli.svg'
import gridImg from '../EnergyMix/Assets/netzstrom_oli.svg'
import solarImg from '../EnergyMix/Assets/solar_oli.svg'
import wasserImg from '../EnergyMix/Assets/wasserkraft_oli.svg'
import windImg from '../EnergyMix/Assets/wind_oli.svg'
import DiscountOverview from './DiscountOverview'
import api from '../../Middleware/api/api'
import { useAuth } from '../../services/contexts'
import { formatDecimal } from '../../services/utils/format-numbers'
import { NumericFormat } from 'react-number-format'

/**
 * This is the main entry component for Aschlage screen on dashboard.
 * @returns {React.ReactNode} - It holds all the data related price adjustments, graph and table.
 * Also it holds the form to set the prices.
 *
 */
const Abschlag = () => {
  const { t } = useTranslation()
  const { token } = useAuth()
  const [biomassPrice, setBiomassPrice] = useState('')
  const [solarPrice, setSolarPrice] = useState('')
  const [windEnergyPrice, setWindEnergyPrice] = useState('')
  const [wasserEnergyPrice, setWasserEnergyPrice] = useState('')
  const [consumerBiomassPrice, setConsumerBiomassPrice] = useState('')
  const [consumerSolarPrice, setConsumerSolarPrice] = useState('')
  const [consumerWindEnergyPrice, setConsumerWindEnergyPrice] = useState('')
  const [consumerWasserEnergyPrice, setConsumerWasserEnergyPrice] = useState('')
  const [consumerGridEnergyPrice, setConsumerGridEnergyPrice] = useState('')
  const [priceData, setPriceData] = useState(null)
  const [refresh, setRefresh] = useState(false)

  // for getting the updated price adjusments
  useEffect(() => {
    api
      .getPriceAdjustment(token)
      .then((response) => {
        const data = response.data
        setRefresh(false)
        if (!data) {
        }

        setPriceData(data?.data)
      })
      .catch((err) => {
        setPriceData(null)
      })
  }, [token, refresh])

  /**
   * This is API call to post the values of prices of energy types.
   *
   * @param {string} key - It could be all the available energy type , i.e. bioMasse, solar, wasserKraft, windStrom and restStrom (restStrom is only for Consumer)
   * @param {number} value - the value of price of energy
   * @param {string} userType - It can be either PRODUCER and CONSUMER
   */
  const handleUpdate = (key, value, userType) => {
    const requestBody = {
      key: key,
      value: value,
      userType: userType,
    }
    setRefresh(true)
    api.adjustingProductionPrices(token, requestBody).catch((err) => {
      console.error('Update failed', err)
    })
  }

  // Function to get local date and time from Timestamp of data.
  const formatTimeStamp = (timeStamp) => {
    const utcDate = new Date(timeStamp)

    // Get the local time zone offset in minutes
    const timeZoneOffset = utcDate.getTimezoneOffset() * 60000
    const localDate = new Date(utcDate.getTime() - timeZoneOffset)

    const formattedDate = localDate.toLocaleDateString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })

    const formattedTime = localDate.toLocaleTimeString('de-DE', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    })

    return { date: formattedDate, time: formattedTime }
  }

  return (
    <>
      <Helmet title="Abschlag" />

      <Grid
        justifyContent="space-between"
        container
        spacing={10}
        sx={{ visibility: 'transparent' }}
      >
        <Grid item>
          <HeaderTypography variant="h2" gutterBottom display="inline">
            {t('LBLAbschlag')}
          </HeaderTypography>
        </Grid>
      </Grid>
      <OuterSurface>
        <StyledDiv>
          <SubHeadingTypography variant="h1">
            {t('LBLAbschlagAnpassen')}
          </SubHeadingTypography>
          <MessageBox>
            <IconWrapper>
              <InformationIcon style={{ fontSize: 44 }} />
            </IconWrapper>
            <MessageDiv>
              <SubHeadingTypography variant="h6">
                {t('LBLAbschlagMessage')}
              </SubHeadingTypography>
            </MessageDiv>
          </MessageBox>
          <SubHeadingTypography variant="h1">
            {t('LBLAbschlägeErzeugung')}
          </SubHeadingTypography>

          {/* table to hold the energy types for production price reduction */}
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow
                  sx={{
                    borderBottom: 2.5,
                    borderColor: (theme) => theme.tokens.secondary,
                  }}
                >
                  <TableCell>
                    <SubHeadingTypography variant="h5">
                      {t('LBLEnergieTyp')}
                    </SubHeadingTypography>
                  </TableCell>
                  <TableCell>
                    <SubHeadingTypography variant="h5">
                      {t('LBLZuletztAktualisiert')}
                    </SubHeadingTypography>
                  </TableCell>
                  <TableCell>
                    <SubHeadingTypography variant="h5">
                      {t('LBLAktuellerAbschlag')}
                    </SubHeadingTypography>
                  </TableCell>
                  <TableCell>
                    <SubHeadingTypography variant="h5">
                      {t('LBLNeuerAbschlag')}
                    </SubHeadingTypography>
                  </TableCell>
                  <TableCell>
                    <SubHeadingTypography variant="h5"></SubHeadingTypography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <>
                  <TableRow
                    style={{
                      borderTop: '1px solid',
                      color: (theme) => theme.tokens.secondary,
                    }}
                  >
                    <TableCell>
                      <TableCellBox>
                        <img src={biomassImg} alt="Biomass" />
                        <SubHeadingTypography variant="h6">
                          Biomasse
                        </SubHeadingTypography>
                      </TableCellBox>
                    </TableCell>
                    <TableCell>
                      {priceData?.producerSalesAdjustment
                        ?.bioMasseUpdateTimeStamp != null
                        ? formatTimeStamp(
                            priceData?.producerSalesAdjustment
                              ?.bioMasseUpdateTimeStamp
                          ).date +
                          ' ' +
                          formatTimeStamp(
                            priceData?.producerSalesAdjustment
                              ?.bioMasseUpdateTimeStamp
                          ).time +
                          ' Uhr'
                        : '--'}
                    </TableCell>
                    <TableCell>
                      {priceData?.producerSalesAdjustment?.bioMasse != null
                        ? formatDecimal(
                            priceData?.producerSalesAdjustment?.bioMasse
                          )
                        : '--'}
                    </TableCell>
                    <TableCell>
                      <NumericFormat
                        margin="none"
                        id="bioMasse"
                        name="bioMasse"
                        label={t('LBLProfitTextField')}
                        value={biomassPrice}
                        customInput={CustomTextField}
                        onValueChange={(value) =>
                          setBiomassPrice(value.floatValue)
                        }
                        decimalSeparator=","
                        InputProps={{
                          title: 'Bitte füllen Sie dieses Feld aus',
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <CustomizedButton
                        variant="contained"
                        onClick={() => {
                          handleUpdate('bioMasse', biomassPrice, 'PRODUCER')
                          setBiomassPrice('')
                        }}
                      >
                        {t('LBLUpdate')}
                      </CustomizedButton>
                    </TableCell>
                  </TableRow>

                  <TableRow
                    style={{
                      borderTop: '1px solid',
                      color: (theme) => theme.tokens.secondary,
                    }}
                  >
                    <TableCell>
                      <TableCellBox>
                        <img src={solarImg} alt="SolarEnergy" />
                        <SubHeadingTypography variant="h6">
                          Solar
                        </SubHeadingTypography>
                      </TableCellBox>
                    </TableCell>
                    <TableCell>
                      {priceData?.producerSalesAdjustment
                        ?.solarUpdateTimeStamp != null
                        ? formatTimeStamp(
                            priceData?.producerSalesAdjustment
                              ?.solarUpdateTimeStamp
                          ).date +
                          ' ' +
                          formatTimeStamp(
                            priceData?.producerSalesAdjustment
                              ?.solarUpdateTimeStamp
                          ).time +
                          ' Uhr'
                        : '--'}
                    </TableCell>
                    <TableCell>
                      {priceData?.producerSalesAdjustment?.solar != null
                        ? formatDecimal(
                            priceData?.producerSalesAdjustment?.solar
                          )
                        : '--'}
                    </TableCell>
                    <TableCell>
                      <NumericFormat
                        fullWidth
                        margin="none"
                        id="solar"
                        name="solar"
                        label={t('LBLProfitTextField')}
                        value={solarPrice}
                        customInput={CustomTextField}
                        onValueChange={(value) =>
                          setSolarPrice(value.floatValue)
                        }
                        decimalSeparator=","
                        InputProps={{
                          title: 'Bitte füllen Sie dieses Feld aus',
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <CustomizedButton
                        variant="contained"
                        onClick={() => {
                          handleUpdate('solar', solarPrice, 'PRODUCER')
                          setSolarPrice('')
                        }}
                      >
                        {t('LBLUpdate')}
                      </CustomizedButton>
                    </TableCell>
                  </TableRow>

                  <TableRow
                    style={{
                      borderTop: '1px solid',
                      color: (theme) => theme.tokens.secondary,
                    }}
                  >
                    <TableCell>
                      <TableCellBox>
                        <img src={wasserImg} alt="HydroPower" />
                        <SubHeadingTypography variant="h6">
                          Wasserkraft
                        </SubHeadingTypography>
                      </TableCellBox>
                    </TableCell>
                    <TableCell>
                      {priceData?.producerSalesAdjustment
                        ?.wasserKraftUpdateTimeStamp != null
                        ? formatTimeStamp(
                            priceData?.producerSalesAdjustment
                              ?.wasserKraftUpdateTimeStamp
                          ).date +
                          ' ' +
                          formatTimeStamp(
                            priceData?.producerSalesAdjustment
                              ?.wasserKraftUpdateTimeStamp
                          ).time +
                          ' Uhr'
                        : '--'}
                    </TableCell>
                    <TableCell>
                      {priceData?.producerSalesAdjustment?.wasserKraft != null
                        ? formatDecimal(
                            priceData?.producerSalesAdjustment?.wasserKraft
                          )
                        : '--'}
                    </TableCell>
                    <TableCell>
                      <NumericFormat
                        fullWidth
                        margin="none"
                        id="wasserKraft"
                        name="wasserKraft"
                        label={t('LBLProfitTextField')}
                        value={wasserEnergyPrice}
                        customInput={CustomTextField}
                        onValueChange={(value) =>
                          setWasserEnergyPrice(value.floatValue)
                        }
                        decimalSeparator=","
                        InputProps={{
                          title: 'Bitte füllen Sie dieses Feld aus',
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <CustomizedButton
                        variant="contained"
                        onClick={() => {
                          handleUpdate(
                            'wasserKraft',
                            wasserEnergyPrice,
                            'PRODUCER'
                          )
                          setWasserEnergyPrice('')
                        }}
                      >
                        {t('LBLUpdate')}
                      </CustomizedButton>
                    </TableCell>
                  </TableRow>

                  <TableRow
                    style={{
                      borderTop: '1px solid',
                      color: (theme) => theme.tokens.secondary,
                    }}
                  >
                    <TableCell>
                      <TableCellBox>
                        <img src={windImg} alt="WindEnergy" />
                        <SubHeadingTypography variant="h6">
                          Windstrom
                        </SubHeadingTypography>
                      </TableCellBox>
                    </TableCell>
                    <TableCell>
                      {priceData?.producerSalesAdjustment
                        ?.windStromUpdateTimeStamp != null
                        ? formatTimeStamp(
                            priceData?.producerSalesAdjustment
                              ?.windStromUpdateTimeStamp
                          ).date +
                          ' ' +
                          formatTimeStamp(
                            priceData?.producerSalesAdjustment
                              ?.windStromUpdateTimeStamp
                          ).time +
                          ' Uhr'
                        : '--'}
                    </TableCell>
                    <TableCell>
                      {priceData?.producerSalesAdjustment?.windStrom != null
                        ? formatDecimal(
                            priceData?.producerSalesAdjustment?.windStrom
                          )
                        : '--'}
                    </TableCell>
                    <TableCell>
                      <NumericFormat
                        fullWidth
                        margin="none"
                        id="windStrom"
                        name="windStrom"
                        label={t('LBLProfitTextField')}
                        value={windEnergyPrice}
                        customInput={CustomTextField}
                        onValueChange={(value) =>
                          setWindEnergyPrice(value.floatValue)
                        }
                        decimalSeparator=","
                        InputProps={{
                          title: 'Bitte füllen Sie dieses Feld aus',
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <CustomizedButton
                        variant="contained"
                        onClick={() => {
                          handleUpdate('windStrom', windEnergyPrice, 'PRODUCER')
                          setWindEnergyPrice('')
                        }}
                      >
                        {t('LBLUpdate')}
                      </CustomizedButton>
                    </TableCell>
                  </TableRow>
                </>
              </TableBody>
            </Table>
          </TableContainer>

          <SubHeadingTypography variant="h1">
            {t('LBLCunsumptionPrice')}
          </SubHeadingTypography>

          {/* Table to hold the energy types for Consumption price reduction */}
          <TableContainer>
            <Table size="medium">
              <TableHead>
                <TableRow
                  sx={{
                    borderBottom: 2.5,
                    borderColor: (theme) => theme.tokens.secondary,
                  }}
                >
                  <TableCell>
                    <SubHeadingTypography variant="h5">
                      {t('LBLEnergieTyp')}
                    </SubHeadingTypography>
                  </TableCell>
                  <TableCell>
                    <SubHeadingTypography variant="h5">
                      {t('LBLZuletztAktualisiert')}
                    </SubHeadingTypography>
                  </TableCell>
                  <TableCell>
                    <SubHeadingTypography variant="h5">
                      {t('LBLAktuellerAbschlag')}
                    </SubHeadingTypography>
                  </TableCell>
                  <TableCell>
                    <SubHeadingTypography variant="h5">
                      {t('LBLNeuerAbschlag')}
                    </SubHeadingTypography>
                  </TableCell>
                  <TableCell>
                    <SubHeadingTypography variant="h5"></SubHeadingTypography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <>
                  <TableRow
                    style={{
                      borderTop: '1px solid',
                      color: (theme) => theme.tokens.secondary,
                    }}
                  >
                    <TableCell>
                      <TableCellBox>
                        <img src={biomassImg} alt="Biomass" />
                        <SubHeadingTypography variant="h6">
                          Biomasse
                        </SubHeadingTypography>
                      </TableCellBox>
                    </TableCell>
                    <TableCell>
                      {priceData?.consumerSalesAdjustment
                        ?.bioMasseUpdateTimeStamp != null
                        ? formatTimeStamp(
                            priceData?.consumerSalesAdjustment
                              ?.bioMasseUpdateTimeStamp
                          ).date +
                          ' ' +
                          formatTimeStamp(
                            priceData?.consumerSalesAdjustment
                              ?.bioMasseUpdateTimeStamp
                          ).time +
                          ' Uhr'
                        : '--'}
                    </TableCell>
                    <TableCell>
                      {priceData?.consumerSalesAdjustment?.bioMasse != null
                        ? formatDecimal(
                            priceData?.consumerSalesAdjustment?.bioMasse
                          )
                        : '--'}
                    </TableCell>
                    <TableCell>
                      <NumericFormat
                        margin="none"
                        id="consumerBiomassPrice"
                        name="consumerBiomassPrice"
                        label={t('LBLProfitTextField')}
                        value={consumerBiomassPrice}
                        customInput={CustomTextField}
                        onValueChange={(value) =>
                          setConsumerBiomassPrice(value.floatValue)
                        }
                        decimalSeparator=","
                        InputProps={{
                          title: 'Bitte füllen Sie dieses Feld aus',
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <CustomizedButton
                        variant="contained"
                        onClick={() => {
                          handleUpdate(
                            'bioMasse',
                            consumerBiomassPrice,
                            'CONSUMER'
                          )
                          setConsumerBiomassPrice('')
                        }}
                      >
                        {t('LBLUpdate')}
                      </CustomizedButton>
                    </TableCell>
                  </TableRow>

                  <TableRow
                    style={{
                      borderTop: '1px solid',
                      color: (theme) => theme.tokens.secondary,
                    }}
                  >
                    <TableCell>
                      <TableCellBox>
                        <img src={solarImg} alt="SolarEnergy" />
                        <SubHeadingTypography variant="h6">
                          Solar
                        </SubHeadingTypography>
                      </TableCellBox>
                    </TableCell>
                    <TableCell>
                      {priceData?.consumerSalesAdjustment
                        ?.solareUpdateTimeStamp != null
                        ? formatTimeStamp(
                            priceData?.consumerSalesAdjustment
                              ?.solareUpdateTimeStamp
                          ).date +
                          ' ' +
                          formatTimeStamp(
                            priceData?.consumerSalesAdjustment
                              ?.solareUpdateTimeStamp
                          ).time +
                          ' Uhr'
                        : '--'}
                    </TableCell>
                    <TableCell>
                      {priceData?.consumerSalesAdjustment?.solar != null
                        ? formatDecimal(
                            priceData?.consumerSalesAdjustment?.solar
                          )
                        : '--'}
                    </TableCell>
                    <TableCell>
                      <NumericFormat
                        fullWidth
                        margin="none"
                        id="consumerSolarPrice"
                        name="consumerSolarPrice"
                        label={t('LBLProfitTextField')}
                        value={consumerSolarPrice}
                        customInput={CustomTextField}
                        onValueChange={(value) =>
                          setConsumerSolarPrice(value.floatValue)
                        }
                        decimalSeparator=","
                        InputProps={{
                          title: 'Bitte füllen Sie dieses Feld aus',
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <CustomizedButton
                        variant="contained"
                        onClick={() => {
                          handleUpdate('solar', consumerSolarPrice, 'CONSUMER')
                          setConsumerSolarPrice('')
                        }}
                      >
                        {t('LBLUpdate')}
                      </CustomizedButton>
                    </TableCell>
                  </TableRow>

                  <TableRow
                    style={{
                      borderTop: '1px solid',
                      color: (theme) => theme.tokens.secondary,
                    }}
                  >
                    <TableCell>
                      <TableCellBox>
                        <img src={wasserImg} alt="HydroPower" />
                        <SubHeadingTypography variant="h6">
                          Wasserkraft
                        </SubHeadingTypography>
                      </TableCellBox>
                    </TableCell>
                    <TableCell>
                      {priceData?.consumerSalesAdjustment
                        ?.wasserKrafUpdateTimeStamp != null
                        ? formatTimeStamp(
                            priceData?.consumerSalesAdjustment
                              ?.wasserKrafUpdateTimeStamp
                          ).date +
                          ' ' +
                          formatTimeStamp(
                            priceData?.consumerSalesAdjustment
                              ?.wasserKrafUpdateTimeStamp
                          ).time +
                          ' Uhr'
                        : '--'}
                    </TableCell>
                    <TableCell>
                      {priceData?.consumerSalesAdjustment?.wasserKraft != null
                        ? formatDecimal(
                            priceData?.consumerSalesAdjustment?.wasserKraft
                          )
                        : '--'}
                    </TableCell>
                    <TableCell>
                      <NumericFormat
                        fullWidth
                        margin="none"
                        id="consumerWasserEnergyPrice"
                        name="consumerWasserEnergyPrice"
                        label={t('LBLProfitTextField')}
                        value={consumerWasserEnergyPrice}
                        customInput={CustomTextField}
                        onValueChange={(value) =>
                          setConsumerWasserEnergyPrice(value.floatValue)
                        }
                        decimalSeparator=","
                        InputProps={{
                          title: 'Bitte füllen Sie dieses Feld aus',
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <CustomizedButton
                        variant="contained"
                        onClick={() => {
                          handleUpdate(
                            'wasserKraft',
                            consumerWasserEnergyPrice,
                            'CONSUMER'
                          )
                          setConsumerWasserEnergyPrice('')
                        }}
                      >
                        {t('LBLUpdate')}
                      </CustomizedButton>
                    </TableCell>
                  </TableRow>

                  <TableRow
                    style={{
                      borderTop: '1px solid',
                      color: (theme) => theme.tokens.secondary,
                    }}
                  >
                    <TableCell>
                      <TableCellBox>
                        <img src={windImg} alt="WindEnergy" />
                        <SubHeadingTypography variant="h6">
                          Windstrom
                        </SubHeadingTypography>
                      </TableCellBox>
                    </TableCell>
                    <TableCell>
                      {priceData?.consumerSalesAdjustment
                        ?.windStromUpdateTimeStamp != null
                        ? formatTimeStamp(
                            priceData?.consumerSalesAdjustment
                              ?.windStromUpdateTimeStamp
                          ).date +
                          ' ' +
                          formatTimeStamp(
                            priceData?.consumerSalesAdjustment
                              ?.windStromUpdateTimeStamp
                          ).time +
                          ' Uhr'
                        : '--'}
                    </TableCell>
                    <TableCell>
                      {priceData?.consumerSalesAdjustment?.windStrom != null
                        ? formatDecimal(
                            priceData?.consumerSalesAdjustment?.windStrom
                          )
                        : '--'}
                    </TableCell>
                    <TableCell>
                      <NumericFormat
                        fullWidth
                        margin="none"
                        id="consumerWindEnergyPrice"
                        name="consumerWindEnergyPrice"
                        value={consumerWindEnergyPrice}
                        label={t('LBLProfitTextField')}
                        customInput={CustomTextField}
                        onValueChange={(value) =>
                          setConsumerWindEnergyPrice(value.floatValue)
                        }
                        decimalSeparator=","
                        InputProps={{
                          title: 'Bitte füllen Sie dieses Feld aus',
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <CustomizedButton
                        variant="contained"
                        onClick={() => {
                          handleUpdate(
                            'windStrom',
                            consumerWindEnergyPrice,
                            'CONSUMER'
                          )
                          setConsumerWindEnergyPrice('')
                        }}
                      >
                        {t('LBLUpdate')}
                      </CustomizedButton>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      borderBottom: 'none',
                      borderTop: '1px solid',
                      color: (theme) => theme.tokens.secondary,
                    }}
                  >
                    <TableCell>
                      <TableCellBox>
                        <img src={gridImg} alt="GridEnergy" />
                        <SubHeadingTypography variant="h6">
                          Netzstrom
                        </SubHeadingTypography>
                      </TableCellBox>
                    </TableCell>
                    <TableCell>
                      {priceData?.consumerSalesAdjustment
                        ?.restStromUpdateTimeStamp != null
                        ? formatTimeStamp(
                            priceData?.consumerSalesAdjustment
                              ?.restStromUpdateTimeStamp
                          ).date +
                          ' ' +
                          formatTimeStamp(
                            priceData?.consumerSalesAdjustment
                              ?.restStromUpdateTimeStamp
                          ).time +
                          ' Uhr'
                        : '--'}
                    </TableCell>
                    <TableCell>
                      {priceData?.consumerSalesAdjustment?.restStrom != null
                        ? formatDecimal(
                            priceData?.consumerSalesAdjustment?.restStrom
                          )
                        : '--'}
                    </TableCell>
                    <TableCell>
                      <NumericFormat
                        fullWidth
                        margin="none"
                        id="consumerGridEnergyPrice"
                        name="consumerGridEnergyPrice"
                        value={consumerGridEnergyPrice}
                        label={t('LBLProfitTextField')}
                        customInput={CustomTextField}
                        onValueChange={(value) =>
                          setConsumerGridEnergyPrice(value.floatValue)
                        }
                        decimalSeparator=","
                        InputProps={{
                          title: 'Bitte füllen Sie dieses Feld aus',
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <CustomizedButton
                        variant="contained"
                        onClick={() => {
                          handleUpdate(
                            'restStrom',
                            consumerGridEnergyPrice,
                            'CONSUMER'
                          )
                          setConsumerGridEnergyPrice('')
                        }}
                      >
                        {t('LBLUpdate')}
                      </CustomizedButton>
                    </TableCell>
                  </TableRow>
                </>
              </TableBody>
            </Table>
          </TableContainer>
        </StyledDiv>
        {/* Here this component holds the price bar graph and also the table for showing the prices overview. */}
        <DiscountOverview />
      </OuterSurface>
    </>
  )
}

export default Abschlag
