import React from 'react'
// Layouts
import AuthLayout from './layouts/Auth'
import DashboardLayout from './layouts/Dashboard'
// Guards
import AuthGuard from './components/guards/AuthGuard'
// Views
import Users from './pages/Users'
import NutzerDetail from './pages/Users/UserDetail'
import Cockpit from './pages/Dashbord/Cockpit'
// Auth components
import SignIn from './pages/auth/SignIn'
import ForgetPassword from './pages/auth/ForgetPassword'
import RouteNotFound from './pages/RouteNotFound'
import EnergyMix from './pages/EnergyMix'
import Abschlag from './pages/Abschlag'

const routes = [
  {
    path: '/',
    element: <DashboardLayout />,
    children: [
      {
        path: '',
        element: (
          <AuthGuard>
            <Cockpit />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: 'cockpit',
    element: <DashboardLayout />,
    children: [
      {
        path: '',
        element: (
          <AuthGuard>
            <Cockpit />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: 'energymix',
    element: <DashboardLayout />,
    children: [
      {
        path: '',
        element: (
          <AuthGuard>
            <EnergyMix />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: 'priceadjustment',
    element: <DashboardLayout />,
    children: [
      {
        path: '',
        element: (
          <AuthGuard>
            <Abschlag />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: 'nutzer',
    element: <DashboardLayout />,
    children: [
      {
        path: '',
        element: (
          <AuthGuard>
            <Users />
          </AuthGuard>
        ),
      },
      {
        path: ':user',
        element: (
          <AuthGuard>
            <NutzerDetail />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: '/',
    element: <AuthLayout />,
    children: [
      {
        path: 'login',
        element: <SignIn />,
      },
      {
        path: 'einloggen',
        element: <SignIn />,
      },
      {
        path: 'forgot-password',
        element: <ForgetPassword />,
      },
      {
        path: 'passwort-vergessen',
        element: <ForgetPassword />,
      },
    ],
  },
  /*{
    path: "private",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ProtectedPage />,
      },
    ],
  },*/
  {
    path: '*',
    element: (
      <AuthLayout>
        <RouteNotFound
          title="Seite nicht gefunden"
          description="Bitte überprüfen Sie den Link oder gehen Sie zur Login-Seite"
          buttonText="zum Login"
          to="/login"
        />
      </AuthLayout>
    ),
    children: [],
  },
]

export default routes
