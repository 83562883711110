import * as React from 'react'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Brightness1RoundedIcon from '@mui/icons-material/Brightness1Rounded'
import {
  StyledText,
  StyledUserNameAndMainColumn,
  StyledTableCell,
  DetailTableCell,
} from './components'
import { useTheme } from '@mui/material/styles'

export default function UserTableBody({
  contract,
  filteredData,
  page,
  rowsPerPage,
  filterContracts,
}) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const theme = useTheme()
  const getStatusColor = (status) => {
    switch (status) {
      case 'ACTIVE':
        return theme.tokens.primary
      case 'INACTIVE':
        return theme.tokens.surfaceContainerHighest
      case 'IN_PROGRESS':
        return theme.status.inPrufung
      default:
        return 'inherit'
    }
  }

  const handleDetailsClick = (user, contractDetails) => {
    navigate(`/nutzer/${user.fullName}/`, {
      state: { user, contractDetails },
    })
  }

  const handleFullNameClick = (user) => {
    navigate(`/nutzer/${user.fullName}/`, { state: { user } })
  }

  return (
    <TableBody>
      {contract.data &&
        filteredData
          .sort((a, b) => {
            const nameA = a.fullName ? a.fullName.toLowerCase() : ''
            const nameB = b.fullName ? b.fullName.toLowerCase() : ''
            return nameA.localeCompare(nameB)
          })
          .slice(
            page * rowsPerPage,
            rowsPerPage > 0
              ? page * rowsPerPage + rowsPerPage
              : filteredData.length
          )
          .map((user, userIndex) => (
            <TableRow
              key={user.userId}
              sx={{
                borderBottom: '2px solid',
                color: (theme) => theme.tokens.secondary,
              }}
            >
              <StyledUserNameAndMainColumn
                style={{
                  borderRight: '1px solid',
                  color: (theme) => theme.tokens.secondary,
                }}
              >
                {/* Here user.fullName is username of user */}
                <Button
                  onClick={() => handleFullNameClick(user)}
                  sx={{ fontWeight: 'bold' }}
                >
                  <StyledText variant="h5">
                    {user.fullName === null ? '--' : user.fullName}
                  </StyledText>
                </Button>
              </StyledUserNameAndMainColumn>

              {user.contractDataDto ? (
                filterContracts(user.contractDataDto).map((contract) => (
                  <TableRow key={contract.contractKey}>
                    <StyledTableCell>
                      <span>
                        {contract?.contractNumber == null
                          ? '--'
                          : contract.contractNumber}
                      </span>
                    </StyledTableCell>
                    <StyledTableCell>
                      {contract.address?.city || '--'}
                    </StyledTableCell>
                    <StyledTableCell>
                      {contract.fullName || '--'}
                    </StyledTableCell>
                    <StyledTableCell>
                      {contract.contractType === 'PRODUCER'
                        ? t('LBLProducer')
                        : t('LBLConsumer')}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        color: getStatusColor(contract.contractStatus),
                      }}
                    >
                      <Brightness1RoundedIcon
                        sx={{
                          color: getStatusColor(contract.contractStatus),
                          width: 20,
                          height: 20,
                          marginRight: '5px',
                          verticalAlign: 'middle',
                        }}
                      />

                      {contract.contractStatus === 'ACTIVE'
                        ? t('LBLActive')
                        : contract.contractStatus === 'INACTIVE'
                        ? t('LBLInactive')
                        : t('LBLInProgress')}
                    </StyledTableCell>

                    <DetailTableCell>
                      <Button
                        onClick={() => handleDetailsClick(user, contract)}
                        style={{ width: '100%' }}
                      >
                        <StyledText variant="h5">Details</StyledText>
                      </Button>
                    </DetailTableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow key={userIndex}>
                  <StyledTableCell>{'--'}</StyledTableCell>
                  <StyledTableCell>{'--'}</StyledTableCell>
                  <StyledTableCell>{'--'}</StyledTableCell>
                  <StyledTableCell>{'--'}</StyledTableCell>
                  <StyledTableCell
                    style={{
                      color: getStatusColor(contract.contractStatus),
                    }}
                  >
                    {'--'}
                  </StyledTableCell>

                  <DetailTableCell>
                    <Button onClick={() => handleDetailsClick(user, contract)}>
                      <StyledText variant="h5">--</StyledText>
                    </Button>
                  </DetailTableCell>
                </TableRow>
              )}
            </TableRow>
          ))}
    </TableBody>
  )
}
