import { useMemo, useEffect } from 'react'
import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'
import Stack from '@mui/material/Stack'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
/**
 * This component is the form that is used in edit workflow to get billing address informaiton.
 * We are using Formik for forms validaiton in all out forms.
 *
 */

function BillingAddressDetails({
  payload,
  setCanNext,
  setPayload,
  confirmPayload,
  submit,
  setSubmit,
}) {
  const { t } = useTranslation()

  useEffect(() => {
    if (submit > 0) document.getElementById('subBilling').click()
    setSubmit(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit])

  const initialValues = {
    billingAddressOwner: payload?.billingAddress?.addressOwner || '',
    street: payload?.billingAddress?.street || '',
    houseNr: payload?.billingAddress?.houseNr || '',
    city: payload?.billingAddress?.city || '',
    postalCode: payload?.billingAddress?.postalCode || '',
  }
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        billingAddressOwner: Yup.string()
          .required(t('LBLBillingAddressOwnerReq'))
          .label(t('LBLBillingAddressOwner')),
        street: Yup.string().required('required').label(t('lblStName')),
        houseNr: Yup.string()
          .required(t('LBLhouseNrReq'))
          .label(t('LBLHouseNr')),
        city: Yup.string().required(t('LBLLocReq')).label(t('city')),
        postalCode: Yup.number()
          .typeError(t('NumericOnly'))
          .required(t('ZipcodeReq'))
          .label(t('PostalCode')),
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  /** @type {import("formik").FormikConfig<typeof initialValues>['onSubmit']} */
  const handleSubmit = (values, actions) => {
    const payload = {
      billingAddress: {
        addressOwner: values.billingAddressOwner,
        city: values.city,
        street: values.street,
        houseNr: values.houseNr,
        postalCode: values.postalCode,
      },
    }

    setPayload(payload)
    confirmPayload()
    actions.setSubmitting(false)
    actions.setTouched({}, false)
    actions.setErrors({})
    actions.setFieldError({})
    actions.setFieldTouched({}, false, false)
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount={true}
        onSubmit={handleSubmit}
      >
        {({ touched, isValid }) => (
          <Form noValidate>
            <>
              {setCanNext(touched && isValid)}
              <Stack mb={5} direction="row" spacing={8}>
                <Field
                  fullWidth
                  margin="none"
                  component={TextField}
                  id="billingAddressOwner"
                  name="billingAddressOwner"
                  label={t('LBLBillingAddressOwner')}
                  InputProps={{
                    title: 'Bitte füllen Sie dieses Feld aus',
                  }}
                  required
                />
              </Stack>
              <Stack my={5} direction="row" alignItems="center" spacing={8}>
                <Field
                  fullWidth
                  margin="none"
                  component={TextField}
                  id="street"
                  name="street"
                  label={t('lblStName')}
                  required
                  InputProps={{
                    title: 'Bitte füllen Sie dieses Feld aus',
                  }}
                />
                <Field
                  fullWidth
                  margin="none"
                  component={TextField}
                  id="houseNr"
                  name="houseNr"
                  label={t('LBLHouseNr')}
                  InputProps={{
                    title: 'Bitte füllen Sie dieses Feld aus',
                  }}
                />
              </Stack>
              <Stack mb={5} direction="row" spacing={8}>
                <Field
                  fullWidth
                  required
                  margin="none"
                  component={TextField}
                  id="postalCode"
                  name="postalCode"
                  label={t('PostalCode')}
                  InputProps={{
                    title: 'Bitte füllen Sie dieses Feld aus',
                  }}
                />
                <Field
                  required
                  fullWidth
                  margin="none"
                  component={TextField}
                  id="city"
                  name="city"
                  label={t('city')}
                  InputProps={{
                    title: 'Bitte füllen Sie dieses Feld aus',
                  }}
                />
              </Stack>

              <Button
                sx={{ display: 'none' }}
                id="subBilling"
                type="submit"
              ></Button>
            </>
          </Form>
        )}
      </Formik>
    </>
  )
}

BillingAddressDetails.propTypes = {
  payload: PropTypes.objectOf(PropTypes.any),
  setPayload: PropTypes.func.isRequired,
  confirmPayload: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
}

export default BillingAddressDetails
