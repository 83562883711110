import AccountBoxIcon from '@mui/icons-material/AccountBox'
import { PriceAdjustmentIcon } from '../icons/myIcons'
import { homeScreenIcon, energyMatchingIcon } from './Assets/myIcon'

const pagesSection = [
  {
    href: '/cockpit',
    icon: homeScreenIcon,
    title: 'Übersicht',
  },
  {
    href: '/energymix',
    icon: energyMatchingIcon,
    title: 'Energie-Mix',
  },
  {
    href: '/priceadjustment',
    icon: PriceAdjustmentIcon,
    title: 'Auf-/Abschlag',
  },
  {
    href: '/nutzer',
    icon: AccountBoxIcon,
    title: 'Nutzerverwaltung',
  },
]

const navItems = [
  {
    pages: pagesSection,
  },
]

export default navItems
