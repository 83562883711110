import { Helmet } from 'react-helmet-async'
import Backdrop from '@mui/material/Backdrop'
import Loader from '../vendor/logoOli.svg'
import { Stack } from '@mui/system'
import { Button, Typography } from '@mui/material'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import React from 'react'

const HeaderTypography = styled(Typography)`
  && {
    color: ${(props) => props.theme.tokens.onSurfaceVariant};
  }
`

function DetailsWrapper({
  title,
  backButtonConfig,
  children,
  helmet,
  loading,
  contract,
  fullName,
}) {
  const { t } = useTranslation()
  return (
    <>
      <Helmet title={helmet} />
      {loading && (
        <Backdrop
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={loading}
        >
          <img src={Loader} alt="loader" className="loader" />
        </Backdrop>
      )}
      <Stack alignItems="flex-start">
        <Stack>
          <HeaderTypography variant="h2" gutterBottom display="inline">
            {title}
          </HeaderTypography>
          <HeaderTypography variant="h3" gutterBottom display="inline">
            {t('LBLUser')}: {fullName}
          </HeaderTypography>

          <Button
            size="medium"
            color="inherit"
            sx={{ color: 'grey.100', marginTop: '10px' }}
            startIcon={<ArrowBackIosIcon />}
            component={NavLink}
            to={backButtonConfig.url}
          >
            <HeaderTypography variant="h4">
              {backButtonConfig.label}
            </HeaderTypography>
          </Button>
        </Stack>
        {contract}
      </Stack>
      {children}
    </>
  )
}
export default DetailsWrapper
