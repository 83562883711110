import styled from 'styled-components/macro'
import { Grid } from '@mui/material'
import MoonIcon from '../themeSwitch/icons/MoonIcon'
import Switch from '../themeSwitch/Switch'
import SunIcon from '../themeSwitch/icons/SunIcon'
import React from 'react'

//this is div that is footer of the side bar having theme switch
const Footer = styled.div`
  padding: ${(props) => props.theme.spacing(2.75)}
    ${(props) => props.theme.spacing(4)};
  background-color: ${(props) => props.theme.tokens.surfaceBright};
`

const SidebarFooter = ({ ...rest }) => {
  return (
    <Footer {...rest} style={{ marginLeft: '30px' }}>
      <Grid container spacing={6} alignItems="center" justify="center">
        <Grid item>
          <SunIcon sx={{ color: 'theme.tokens.secondary' }} />
          <Switch name="themeSwitch" />
          <MoonIcon sx={{ color: 'theme.tokens.secondary' }} />
        </Grid>
      </Grid>
    </Footer>
  )
}

export default SidebarFooter
