import { useMemo, useEffect, useState } from 'react'
import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  MenuItem,
  TextField as MuiTextField,
  FormControlLabel,
  Checkbox,
} from '@mui/material'
import Stack from '@mui/material/Stack'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { TextContainer, StyledCard } from './styles'
/**
 * This component is the form that is used in edit workflow to get other details i.e. contract status, contract number.
 * We are using Formik for forms validaiton in all out forms.
 *
 */

function OtherDataDetails({
  payload,
  setPayloadThree,
  confirmPayload,
  submit,
  setSubmit,
  setCanNext,
}) {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [openContractType, setOpenContractType] = useState(false)

  useEffect(() => {
    if (submit > 0) document.getElementById('subThree').click()
    setSubmit(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit])

  const initialValues = {
    contractNumber: payload?.contractNumber || '',
    contractType: payload?.contractType || '',
    contractStatus: payload?.contractStatus || '',
    salesTaxLiability: payload?.salesTaxLiability || '',
    marketingInformation: payload?.marketingInformation || false,
    agb: payload?.agb || false,
  }
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        contractNumber: Yup.string()
          .required(t('LBLContractNumber'))
          .label(t('LBLVertragsnummer')),
        contractType: Yup.string()
          .required(t('LBLContractTypeReq'))
          .label(t('LBLContractType')),
        contractStatus: Yup.string()
          .required(t('LBLContractStatusReq'))
          .label(t('LBLUserStatus')),
        salesTaxLiability: Yup.string()
          .required(t('LBLSalesTaxReq'))
          .label(t('LBLSalesTaxLiability')),
        marketingInformation: Yup.bool().label('LBLMarketingInformation'),
        agb: Yup.bool().oneOf([true], t('LBLAgb')).label('LBLAgbCondition'),
      }),
    [t]
  )
  /** @type {import("formik").FormikConfig<typeof initialValues>['onSubmit']} */
  const handleSubmit = (values, actions) => {
    const payload = {
      contractNumber: values.contractNumber,
      contractType: values?.contractType || '',
      contractStatus: values?.contractStatus,
      salesTaxLiability: values?.salesTaxLiability || '',
      marketingInformation: values?.marketingInformation,
      agb: values?.agb,
    }
    setPayloadThree(payload)
    confirmPayload()
    actions.setSubmitting(false)
    actions.setTouched({}, false)
    actions.setErrors({})
    actions.setFieldError({})
    actions.setFieldTouched({}, false, false)
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount={true}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, values, setFieldValue, isValid }) => (
          <Form noValidate>
            <>
              {setCanNext(touched && isValid)}

              <Stack my={5} direction="row" alignItems="center" spacing={8}>
                <Field
                  fullWidth
                  margin="none"
                  component={TextField}
                  id="contractNumber"
                  name="contractNumber"
                  label={t('Vertragsnummer')}
                  required
                  InputProps={{
                    title: 'Bitte füllen Sie dieses Feld aus',
                  }}
                  sx={{ width: '500px' }}
                />

                <MuiTextField
                  select
                  id="contractStatus"
                  name="contractStatus"
                  defaultValue={payload?.contractStatus}
                  value={values.contractStatus}
                  onChange={(event) =>
                    setFieldValue('contractStatus', event.target.value)
                  }
                  label={t('LBLContaractStatus')}
                  open={open}
                  onClose={() => setOpen(false)}
                  onOpen={() => setOpen(true)}
                  sx={{ width: '500px' }}
                >
                  <MenuItem value="ACTIVE">{t('LBLActive')}</MenuItem>
                  <MenuItem value="INACTIVE">{t('LBLExpired')}</MenuItem>
                  <MenuItem value="IN_PROGRESS">{t('LBLInreview')}</MenuItem>
                </MuiTextField>
              </Stack>

              <Stack mb={5} direction="row" spacing={8}>
                <MuiTextField
                  select
                  id="contractType"
                  name="contractType"
                  defaultValue={payload?.contractType}
                  value={values.contractType}
                  onChange={(event) =>
                    setFieldValue('contractType', event.target.value)
                  }
                  label={t('LBLContractType')}
                  open={openContractType}
                  onClose={() => setOpenContractType(false)}
                  onOpen={() => setOpenContractType(true)}
                  sx={{ width: '500px' }}
                >
                  <MenuItem value="PRODUCER">{t('LBLProducer')}</MenuItem>
                  <MenuItem value="CONSUMER">{t('LBLConsumer')}</MenuItem>
                </MuiTextField>
                <Field
                  fullWidth
                  required
                  margin="none"
                  component={TextField}
                  id="salesTaxLiability"
                  name="salesTaxLiability"
                  label={t('LBLSalesTaxLiability')}
                  InputProps={{
                    title: 'Bitte füllen Sie dieses Feld aus',
                  }}
                  sx={{ width: '500px' }}
                />
              </Stack>

              {/* this is checkbox to skip the billing Address step in edit workglow. */}
              <Stack my={2} direction="row" alignItems="center" spacing={1}>
                <FormControlLabel
                  control={
                    <Field
                      component={Checkbox}
                      type="checkbox"
                      id="marketingInformation"
                      name="marketingInformation"
                      onChange={(event) => {
                        setFieldValue(
                          'marketingInformation',
                          event.target.checked
                        )
                      }}
                      checked={values.marketingInformation}
                    />
                  }
                  // label={t('LBLBankAccountAuthorizatoin')}
                />
                <TextContainer>
                  <StyledCard variant="outline" text={t('LBLMarketingText')} />
                </TextContainer>
              </Stack>
              <Stack my={2} direction="row" alignItems="center" spacing={1}>
                <FormControlLabel
                  control={
                    <Field
                      required
                      component={Checkbox}
                      type="checkbox"
                      id="agb"
                      name="agb"
                      onChange={(event) => {
                        setFieldValue('agb', event.target.checked)
                      }}
                      checked={values.agb}
                    />
                  }
                  // label={t('LBLBankAccountAuthorizatoin')}
                />
                <TextContainer>
                  <StyledCard
                    variant="outline"
                    text={t('LBLAgbText')}
                    required={t('LBLRequired')}
                  />
                </TextContainer>
              </Stack>
              <Button
                sx={{ display: 'none' }}
                id="subThree"
                type="submit"
              ></Button>
            </>
          </Form>
        )}
      </Formik>
    </>
  )
}
OtherDataDetails.propTypes = {
  payload: PropTypes.objectOf(PropTypes.any),
  setPayloadThree: PropTypes.func.isRequired,
  confirmPayload: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
}

export default OtherDataDetails
