import { useMemo, useEffect } from 'react'
import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  FormControlLabel,
  Checkbox,
  Card,
  CardContent,
  Typography,
} from '@mui/material'
import Stack from '@mui/material/Stack'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { TextContainer } from './styles'

/**
 * This component is the form that is used in edit workflow to get payment related details.
 * We are using Formik for forms validaiton in all out forms.
 *
 */
function PaymentDetails({
  payload,
  setPayloadOne,
  confirmPayload,
  submit,
  setSubmit,
  setCanNext,
}) {
  const { t } = useTranslation()
  const card = (
    <>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {t('LBLBankAccountAuthorizatoin')}
        </Typography>
        <Typography variant="h5" component="div">
          {t('LBLNote')}
        </Typography>
        <Typography sx={{ fontSize: 14 }} component="div">
          {t('LBLBankAccountNote')}
        </Typography>
        <Typography sx={{ marginTop: 5 }} variant="h5" component="div">
          {t('LBLRequired')}
        </Typography>
      </CardContent>
    </>
  )
  useEffect(() => {
    if (submit > 0) document.getElementById('subOne').click()
    setSubmit(0)
  }, [submit, setSubmit])

  const initialValues = {
    bankName: payload?.paymentDetails?.bankName || '',
    bic: payload?.paymentDetails?.bic || '',
    iban: payload?.paymentDetails?.iban || '',
    accountHolder: payload?.paymentDetails?.accountHolder || '',
    accept: payload?.paymentDetails?.accept || false,
  }

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        bankName: Yup.string()
          .required(t('LBLBankRequired'))
          .label(t('LBLBankName')),
        bic: Yup.string().required(t('LBLbicreq')).label(t('LBLBic')),
        iban: Yup.string().required(t('LBLibanReq')).label('LBLIban'),
        accountHolder: Yup.string().label(t('LBLAccountHolder')),
        accept: Yup.bool()
          .oneOf([true], t('LBLAcceptCondition'))
          .label('LBLPaymentAuthorization'),
      }),
    [t]
  )
  /** @type {import("formik").FormikConfig<typeof initialValues>['onSubmit']} */
  const handleSubmit = (values, actions) => {
    const payload = {
      bankName: values.bankName,
      bic: values.bic,
      iban: values.iban,
      accountHolder: values.accountHolder,
      accept: values.accept,
    }

    setPayloadOne(payload)
    confirmPayload()
    actions.setSubmitting(false)
    actions.setTouched({}, false)
    actions.setErrors({})
    actions.setFieldError({})
    actions.setFieldTouched({}, false, false)
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount={true}
        onSubmit={handleSubmit}
      >
        {({ touched, isValid, setFieldValue, values }) => (
          <Form noValidate>
            <>
              {setCanNext(touched && isValid)}

              <Stack my={5} direction="row" alignItems="center" spacing={8}>
                <Field
                  fullWidth
                  margin="none"
                  component={TextField}
                  id="bankName"
                  name="bankName"
                  label={t('LBLBankName')}
                  required
                  InputProps={{
                    title: 'Bitte füllen Sie dieses Feld aus',
                  }}
                />
                <Field
                  fullWidth
                  margin="none"
                  component={TextField}
                  id="bic"
                  name="bic"
                  label={t('LBLBic')}
                  required
                  InputProps={{
                    title: 'Bitte füllen Sie dieses Feld aus',
                  }}
                />
              </Stack>
              <Stack mb={5} direction="row" spacing={8}>
                <Field
                  fullWidth
                  margin="none"
                  component={TextField}
                  id="iban"
                  name="iban"
                  label={t('LBLIban')}
                  required
                  InputProps={{
                    title: 'Bitte füllen Sie dieses Feld aus',
                  }}
                />
                <Field
                  fullWidth
                  margin="none"
                  component={TextField}
                  id="accountHolder"
                  name="accountHolder"
                  label={t('LBLAccountHolder')}
                  InputProps={{
                    title: 'Bitte füllen Sie dieses Feld aus',
                  }}
                />
              </Stack>
              {/* this is checkbox to skip the billing Address step in edit workglow. */}
              <Stack my={2} direction="row" alignItems="center" spacing={1}>
                <FormControlLabel
                  control={
                    <Field
                      required
                      component={Checkbox}
                      type="checkbox"
                      id="accept"
                      name="accept"
                      onChange={(event) => {
                        setFieldValue('accept', event.target.checked)
                      }}
                      checked={values.accept}
                    />
                  }
                  // label={t('LBLBankAccountAuthorizatoin')}
                />
                <TextContainer>
                  <Card variant="outlined">{card}</Card>
                </TextContainer>
              </Stack>

              <Button
                sx={{ display: 'none' }}
                id="subOne"
                type="submit"
              ></Button>
            </>
          </Form>
        )}
      </Formik>
    </>
  )
}
PaymentDetails.propTypes = {
  payload: PropTypes.objectOf(PropTypes.any),
  setPayloadOne: PropTypes.func.isRequired,
  confirmPayload: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
}

export default PaymentDetails
