import React from 'react'
import styled, { css } from 'styled-components/macro'
import ReactPerfectScrollbar from 'react-perfect-scrollbar'
import { List } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import SidebarNavSection from './SidebarNavSection'

import '../../vendor/perfect-scrollbar.css'

const baseScrollbar = css`
  flex-grow: 1;
`

const Scrollbar = styled.div`
  ${baseScrollbar}
`

const PerfectScrollbar = styled(ReactPerfectScrollbar)`
  ${baseScrollbar}
`

const Items = styled.div`
  color: ${(props) => props.theme.tokens.surfaceBright};
  background-color: ${(props) => props.theme.tokens.surfaceBright};
  padding-top: ${(props) => props.theme.spacing(4)};
  padding-bottom: ${(props) => props.theme.spacing(2.5)};
`

const SidebarNav = ({ items }) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  const ScrollbarComponent = matches ? PerfectScrollbar : Scrollbar
  console.log(items)
  return (
    <ScrollbarComponent>
      <List disablePadding>
        <Items>
          {items &&
            items.map((item) => (
              <SidebarNavSection
                component="div"
                key={item.title}
                pages={item.pages}
                title={item.title}
              />
            ))}
        </Items>
      </List>
    </ScrollbarComponent>
  )
}

export default SidebarNav
