import {
  SubHeadingTypography,
  ButtonGroup,
  StyledMenuButton,
  ListOfEnergySourceBox,
  StyledLabel,
  StyledBox,
  ButtonContainer,
  HeaderBox,
} from './style'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import CheckIcon from '@mui/icons-material/Check'
import { useAuth } from '../../services/contexts'
import networkImg from '../EnergyMix/Assets/netzstrom_oli.svg'
import api from '../../Middleware/api/api'
import PriceBarGraph from './PriceBarGraph'
import {
  WindIconWithoutBackground,
  HydroEnergyIcon,
  SolarEnergyIcon,
  BioMassIcon,
} from '../../components/icons/myIcons'
import PriceTable from './PriceTable'
import Feedback from '../../components/Feedback'
import { Typography } from '@mui/material'
import React from 'react'
const Divider = ({ style }) => {
  return <hr style={{ ...defaultStyle, ...style }} />
}

const defaultStyle = {
  border: 0,
  height: '1px',
  background: '#333',
  margin: '20px 0',
}
/**
 * This component holds the price bar graph and also the table for showing the prices overview. That are shown on Aschlage screen.
 *
 * @returns {React.ReactNode} - it returns the bar graph i.e. PriceBarGraph and the table i.e. PriceTable.
 */

const DiscountOverview = () => {
  const { t } = useTranslation()
  const { token } = useAuth()
  const [selected, setSelected] = useState('PRODUCER')
  const [consumerData, setConsumerData] = useState([])
  const [producerData, setProducerData] = useState([])
  const [error, setError] = useState(null)

  const closeFeedback = () => {
    setError(false)
  }

  /**
   * This is the API call to get the data for bar graph.
   * @example
   * For detail reponses you can visit the following file:
   * [API Respnse Documentation](./src/docs/apiResponse.md)
   *
   */

  useEffect(() => {
    api
      .getPriceOverviewData(token)
      .then((response) => {
        if (!response?.data) {
          setError(true)
        }

        const data = response?.data?.data

        if (!data) {
          setError(true)
        }

        setConsumerData(data?.consumerSalesResponses)
        setProducerData(data?.producerSalesResponses)
        setError(null) // Clear any previous errors
      })
      .catch((err) => {
        setError(true)
        setConsumerData(null)
        setProducerData(null)
      })
  }, [token])

  const handleButtonClick = (buttonType) => {
    setSelected(buttonType)
  }
  return (
    <>
      <div style={{ padding: '40px' }}></div>
      <HeaderBox>
        <SubHeadingTypography variant="h1">
          {t('LBLAbschlagUberblick')}
        </SubHeadingTypography>
      </HeaderBox>
      <Divider style={{ background: 'red', height: '10px' }} />
      <StyledBox>
        <ButtonContainer>
          <ButtonGroup>
            <StyledMenuButton
              variant={selected === 'PRODUCER' ? 'contained' : 'outlined'}
              className={selected === 'PRODUCER' ? 'selected' : ''}
              onClick={() => handleButtonClick('PRODUCER')}
            >
              {selected === 'PRODUCER' && <CheckIcon />} {t('LBLProducer')}
            </StyledMenuButton>
            <StyledMenuButton
              variant={selected === 'CONSUMER' ? 'contained' : 'outlined'}
              className={selected === 'CONSUMER' ? 'selected' : ''}
              onClick={() => handleButtonClick('CONSUMER')}
            >
              {selected === 'CONSUMER' && <CheckIcon />} {t('LBLConsumer')}
            </StyledMenuButton>
          </ButtonGroup>
        </ButtonContainer>
        <ListOfEnergySourceBox>
          <StyledLabel variant="h8">
            <BioMassIcon /> Biomasse
          </StyledLabel>
          <StyledLabel variant="h8">
            <SolarEnergyIcon /> Solar
          </StyledLabel>
          <StyledLabel variant="h8">
            <HydroEnergyIcon /> Wasserkraft
          </StyledLabel>
          <StyledLabel variant="h8">
            <WindIconWithoutBackground /> Windstrom
          </StyledLabel>
          {selected !== 'PRODUCER' && (
            <StyledLabel>
              <img src={networkImg} alt="Fossil" /> Netzstrom
            </StyledLabel>
          )}
        </ListOfEnergySourceBox>
      </StyledBox>
      {selected === 'PRODUCER' ? (
        <>
          <PriceBarGraph
            priceData={producerData}
            selectedType={selected}
          ></PriceBarGraph>
          <PriceTable
            priceData={producerData}
            selectedType={selected}
          ></PriceTable>
        </>
      ) : (
        <>
          <PriceBarGraph
            priceData={consumerData}
            selectedType={selected}
          ></PriceBarGraph>
          <PriceTable
            priceData={consumerData}
            selectedType={selected}
          ></PriceTable>
        </>
      )}

      {/* Error messages */}
      <Feedback
        open={error}
        title={t('LBLDataInfo')}
        message={
          <>
            <Typography align="center">{t('LBLDataMsg')}</Typography>
          </>
        }
        handleClose={closeFeedback}
      />
    </>
  )
}

export default DiscountOverview
