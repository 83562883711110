import { configureStore } from '@reduxjs/toolkit'
import themeReducer from './slices/themeSlice'
import addressReducer from './slices/addressSlice'

export const store = configureStore({
  reducer: {
    themeMode: themeReducer,
    address: addressReducer,
  },
})
