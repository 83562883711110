import './Switch.css'
import { useDispatch } from 'react-redux'
import { changeThemeMode } from '../../redux/slices/themeSlice'
import React from 'react'

function Switch({ isDarkTheme }) {
  const dispatch = useDispatch()

  return (
    <label className="toggle-switch">
      <input
        type="checkbox"
        checked={isDarkTheme}
        onChange={() => dispatch(changeThemeMode())}
      />
      <span className="switch" />
    </label>
  )
}
export default Switch
