import styled from 'styled-components/macro'
import React, { useState, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet-async'
import shadows from '@mui/material/styles/shadows'
import { Grid, Box, Typography } from '@mui/material'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import CheckIcon from '@mui/icons-material/Check'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { DatePicker, TimePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import format from 'date-fns/format'
import { formatDecimal } from '../../services/utils/format-numbers'
import { useAuth } from '../../services/contexts'
import { useTheme } from 'styled-components/macro'
import api from '../../Middleware/api/api'
import {
  StyledMenuButton,
  StyledButton,
  StyledDiv,
  StyledLabel,
  StyledTextField,
  StyledTimePicker,
  ButtonGroup,
  StyledText,
} from '../EnergyMix/style'
import {
  OuterSurface,
  BoxTypography,
  BoxData,
  BaseEnergyShareKpi,
  IconWrapper,
  EnergyMixButton,
  EnergyShareKpiTypography,
  EnergyShareKpiData,
  Container,
  EnergySummaryBox,
} from './styles'
import {
  BioMassIcon,
  WindEnergyIcon,
  HydroEnergyIcon,
  SolarEnergyIcon,
} from '../../components/icons/myIcons'
import { useNavigate } from 'react-router-dom'
import EnergyBarChart from './EnergyBarChart'
import Feedback from '../../components/Feedback'

const HeaderTypography = styled(Typography)`
  && {
    color: ${(props) => props.theme.tokens.secondary};
    position: relative;
    margin: 10px;
  }
`

/**
 * Calculates a date in the past based on the number of days provided.
 * This function returns a formatted date string representing a date that is `days` in the past
 * from the current date. The format of the returned date is 'YYYY-MM-DD'.
 *
 * @param {number} days - The number of days to subtract from the current date. For example, passing 1 will return yesterday's date.
 * @returns {string} The calculated date in the format 'YYYY-MM-DD'.
 *
 * @example
 * // Returns yesterday's date in 'YYYY-MM-DD' format
 * const yesterday = calculatedates(1);
 *
 * @example
 * // Returns the date 7 days ago
 * const lastWeek = calculatedates(7);
 */
function calculatedates(days) {
  let date = new Date()
  let last = new Date(date.getTime() - days * 24 * 60 * 60 * 1000)
  return (
    last.getFullYear() +
    '-' +
    last.toLocaleString('en-US', { month: '2-digit' }) +
    '-' +
    last.toLocaleString('en-US', { day: '2-digit' })
  )
}

/**
 * This is the component to show main KPIs and the bar graph on dashboard.
 * It holds the KPIs i.e. related to production and consumption that are updated with date selection
 * on Uberblick Energie Page of dashboardand grpah.
 *
 * @returns {React.ReactNode} A React component that renders KPIs and also the Bar graph.
 */
function EnergyKpi() {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const { token } = useAuth()
  const [selected, setSelected] = useState('YEAR')
  const [startDate, setStartDate] = useState(calculatedates(1))
  const [endDate, setEndDate] = useState(calculatedates(0))
  const [endTime, setEndTime] = useState(new Date())
  const [startTime, setStartTime] = useState(new Date())
  const [energyData, setEnergyData] = useState([])
  const [error, setError] = useState(false)
  const [timePeriod, setTimePeriod] = useState(['YEAR'])
  const [salePurchaseData, setSalePurchaseData] = useState([])
  const theme = useTheme()
  const handleButtonClick = (buttonType) => {
    setSelected(buttonType)
    setTimePeriod(buttonType)
  }

  const handleClick = () => {
    navigate('/energyMix')
  }
  const closeFeedback = () => {
    setError(false)
  }

  useEffect(() => {
    fetchEnergySummaryData(
      startDate,
      endDate,
      format(startTime, 'HH:mm'),
      format(endTime, 'HH:mm')
    )
    fetchSalePurchaseData(
      startDate,
      endDate,
      format(startTime, 'HH:mm'),
      format(endTime, 'HH:mm')
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timePeriod])

  /**
   * Fetches the energy summary data based on the provided date and time range.
   * This function makes an API call to retrieve energy KPIs (Key Performance Indicators) for a specific time period.
   * The data is fetched using the `startDate`, `endDate`, `startTime`, and `endTime` parameters, and then updates the state accordingly.
   *
   * @param {string} startDate - The start date for the energy summary query, in 'YYYY-MM-DD' format.
   * @param {string} endDate - The end date for the energy summary query, in 'YYYY-MM-DD' format.
   * @param {string} startTime - The start time for the energy summary query, in 'HH:mm' format.
   * @param {string} endTime - The end time for the energy summary query, in 'HH:mm' format.
   *
   * @returns {void} No return value. The function updates component state (e.g., energy data and error state) based on the API response.
   *
   * @example
   * // Fetch energy summary data for a specific date and time range
   * fetchEnergySummaryData('2024-09-01', '2024-09-07', '00:00', '23:59');
   *
   * @throws {Error} If the API call fails, it catches the exception and sets error states accordingly.
   */
  const fetchEnergySummaryData = (startDate, endDate, startTime, endTime) => {
    try {
      const params = new URLSearchParams({
        dateUnit: timePeriod,
        startDate: startDate,
        endDate: endDate,
        startTime: startTime,
        endTime: endTime,
      })

      api.getEnergySummaryKpi(token, params).then((response) => {
        if (response.data.data !== null) {
          setEnergyData(response.data)
        } else if (response.data.data == null) {
          setError(true)
          setEnergyData(null)
        }
      })
    } catch (exception) {
      setEnergyData(null)
    }
  }

  /**
   * Fetches the energy sale or purchased data based on the provided date and time.
   * This function makes an API call to retrieve energy data for a specific time period to show that data on dashboard cockpit.
   * The data is fetched using the `startDate`, `endDate`, `startTime`, and `endTime` parameters, and then updates the state accordingly.
   *
   * @param {string} startDate - The start date for the energy summary query, in 'YYYY-MM-DD' format.
   * @param {string} endDate - The end date for the energy summary query, in 'YYYY-MM-DD' format.
   * @param {string} startTime - The start time for the energy summary query, in 'HH:mm' format.
   * @param {string} endTime - The end time for the energy summary query, in 'HH:mm' format.
   *
   * @returns {void} No return value. The function updates component state (e.g., energy data and error state) based on the API response.
   *
   * @example
   * // Fetch energy summary data for a specific date and time range
   * fetchEnergySummaryData('2024-09-01', '2024-09-07', '00:00', '23:59');
   *
   * @throws {Error} If the API call fails, it catches the exception and sets error states accordingly.
   */
  const fetchSalePurchaseData = (startDate, endDate, startTime, endTime) => {
    try {
      const params = new URLSearchParams({
        dateUnit: timePeriod,
        startDate: startDate,
        endDate: endDate,
        startTime: startTime,
        endTime: endTime,
      })

      api.getSalePurchaseData(token, params).then((response) => {
        if (response.data.data !== null) {
          setSalePurchaseData(response.data)
        } else if (response.data.data == null) {
          setSalePurchaseData([])
          setError(true)
        }
      })
    } catch (exception) {
      setSalePurchaseData([])
    }
  }

  const initialValues = {
    startDate: startDate,
    endDate: endDate,
    startTime: startTime,
    endTime: endTime,
  }
  const validationSchema = useMemo(
    () =>
      Yup.object({
        startDate: Yup.date().required(i18n.t('LBLStartDateReq')),
        endDate: Yup.date().required(i18n.t('LBLEndDateReq')),
      }),
    [i18n]
  )
  //  This is the handle submit function that is triggered when the time selection is made on the dashboard
  const handleSubmit = (values, actions) => {
    setStartDate(values.startDate)
    setEndDate(values.endDate)
    setStartTime(values.startTime)
    setEndTime(values.endTime)
    fetchEnergySummaryData(
      values.startDate,
      values.endDate,
      format(values.startTime, 'HH:mm'),
      format(values.endTime, 'HH:mm')
    )
    fetchSalePurchaseData(
      values.startDate,
      values.endDate,
      format(values.startTime, 'HH:mm'),
      format(values.endTime, 'HH:mm')
    )

    actions.setSubmitting(false)
    actions.setTouched({}, true)
    actions.setErrors({})
    actions.setFieldError({})
    actions.setFieldTouched({}, true, true)
  }
  return (
    <React.Fragment>
      <OuterSurface>
        <Helmet title="Nutzers" />

        <Grid
          justifyContent="space-between"
          container
          spacing={10}
          sx={{ visibility: 'transparent' }}
        >
          <Grid item>
            <HeaderTypography variant="h7" gutterBottom display="inline">
              {t('LBLEnergyOverview')}
            </HeaderTypography>
          </Grid>
        </Grid>

        <ButtonGroup>
          <StyledMenuButton
            variant={selected === 'YESTERDAY' ? 'contained' : 'outlined'}
            className={selected === 'YESTERDAY' ? 'selected' : ''}
            onClick={() => handleButtonClick('YESTERDAY')}
          >
            {selected === 'YESTERDAY' && <CheckIcon />} {t('LBLYesterday')}
          </StyledMenuButton>
          <StyledMenuButton
            variant={selected === 'YEAR' ? 'contained' : 'outlined'}
            className={selected === 'YEAR' ? 'selected' : ''}
            onClick={() => handleButtonClick('YEAR')}
          >
            {selected === 'YEAR' && <CheckIcon />} {t('LBLYear')}
          </StyledMenuButton>
          <StyledMenuButton
            variant={selected === 'TIMEPERIOD' ? 'contained' : 'outlined'}
            className={selected === 'TIMEPERIOD' ? 'selected' : ''}
            onClick={() => handleButtonClick('TIMEPERIOD')}
          >
            {selected === 'TIMEPERIOD' && <CheckIcon />} {t('LBLTimePeriod')}
          </StyledMenuButton>
        </ButtonGroup>

        {/* selection of time frame */}

        {selected === 'TIMEPERIOD' && (
          <>
            <StyledDiv>
              <Box display="flex" alignItems="center" marginTop={2}>
                <Formik
                  enableReinitialize={true}
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  validateOnMount={true}
                  onSubmit={handleSubmit}
                  validateOnChange={true}
                >
                  {({ errors, touched, values, setFieldValue }) => (
                    <Form noValidate>
                      <>
                        <Grid container spacing={18} alignItems="center">
                          <Grid item xs={12} sm={6} md={5} lg={4}>
                            <StyledLabel variant="h5">
                              {t('LBLStartDate')}
                            </StyledLabel>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                  adapterLocale="de"
                                >
                                  <DatePicker
                                    id={'startDate'}
                                    value={values.startDate}
                                    maxDate={dayjs()}
                                    onChange={(newValue) => {
                                      setFieldValue(
                                        'startDate',
                                        newValue
                                          ? format(
                                              newValue.toDate(),
                                              'yyyy-MM-dd'
                                            )
                                          : ''
                                      )
                                    }}
                                    renderInput={(params) => (
                                      <StyledTextField
                                        {...params}
                                        required
                                        name="startDate"
                                        margin="none"
                                        autoComplete="off"
                                        helperText={
                                          touched.startDate &&
                                          t(errors.startDate)
                                        }
                                        error={
                                          t(errors.startDate) &&
                                          touched.startDate
                                        }
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              </Grid>
                              <Grid item xs={6}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                  adapterLocale="de"
                                >
                                  <TimePicker
                                    PaperProps={{
                                      sx: {
                                        boxShadow: shadows[3],
                                      },
                                    }}
                                    value={values.startTime}
                                    id={'startTime'}
                                    minutesStep={15}
                                    onChange={(newValue) => {
                                      setFieldValue(
                                        'startTime',
                                        newValue.toDate()
                                      )
                                    }}
                                    renderInput={(params) => (
                                      <StyledTimePicker
                                        {...params}
                                        name={'startTime'}
                                        margin="none"
                                        autoComplete="off"
                                        helperText={
                                          touched.startTime &&
                                          t(errors.startTime)
                                        }
                                        error={
                                          t(errors.startTime) &&
                                          touched.startTime
                                        }
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <StyledLabel variant="h5">
                              {t('LBLEndDate')}
                            </StyledLabel>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                  adapterLocale="de"
                                >
                                  <DatePicker
                                    id={'endDate'}
                                    value={values.endDate}
                                    maxDate={dayjs()}
                                    onChange={(newValue) => {
                                      setFieldValue(
                                        'endDate',
                                        newValue
                                          ? format(
                                              newValue.toDate(),
                                              'yyyy-MM-dd'
                                            )
                                          : ''
                                      )
                                    }}
                                    renderInput={(params) => (
                                      <StyledTextField
                                        {...params}
                                        required
                                        name="endDate"
                                        margin="none"
                                        autoComplete="off"
                                        helperText={
                                          touched.endDate && t(errors.endDate)
                                        }
                                        error={
                                          t(errors.endDate) && touched.endDate
                                        }
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              </Grid>
                              <Grid item xs={6}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                  adapterLocale="de"
                                >
                                  <TimePicker
                                    value={values.endTime}
                                    id={'endTime'}
                                    minutesStep={15}
                                    onChange={(newValue) => {
                                      setFieldValue(
                                        'endTime',
                                        newValue.toDate()
                                      )
                                    }}
                                    renderInput={(params) => (
                                      <StyledTimePicker
                                        {...params}
                                        name={'endTime'}
                                        margin="none"
                                        autoComplete="off"
                                        helperText={
                                          touched.endTime && t(errors.endTime)
                                        }
                                        error={
                                          t(errors.endTime) && touched.endTime
                                        }
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            sm={12}
                            md={4}
                            spacing={2}
                            sx={{
                              marginTop: 8,
                              paddingTop: 8,
                            }}
                          >
                            <Grid item>
                              <StyledButton
                                variant="contained"
                                color="primary"
                                type="submit"
                              >
                                <StyledText variant="h5">
                                  {t('LBLSubmit')}
                                </StyledText>
                              </StyledButton>
                            </Grid>
                            <Grid item>
                              <StyledButton
                                sx={{
                                  display: 'none',
                                }}
                              >
                                <StyledText variant="h5">
                                  <FileDownloadIcon
                                    sx={{
                                      verticalAlign: 'middle',
                                    }}
                                  />{' '}
                                  Export to .xls
                                </StyledText>
                              </StyledButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    </Form>
                  )}
                </Formik>
              </Box>
            </StyledDiv>
          </>
        )}

        {/* ENERGY KPIs BOXES STARTS HERE */}
        <HeaderTypography variant="h7">
          {t('LBLEnergyInputOutput')}
        </HeaderTypography>
        <Container>
          <EnergySummaryBox>
            <Box>
              <BoxTypography variant="h5">
                {t('LBLTotalLocalProduction')}
              </BoxTypography>
              <BoxData variant="h1">
                {energyData !== null &&
                energyData?.data?.totalEnergyProduction > 0
                  ? formatDecimal(energyData?.data?.totalEnergyProduction)
                  : '0,00'}
              </BoxData>
            </Box>
          </EnergySummaryBox>
          <EnergySummaryBox>
            <Box>
              <BoxTypography variant="h5">{t('LBLTotalDemand')}</BoxTypography>
              <BoxData variant="h1">
                {energyData !== null && energyData?.data?.totalDemand > 0
                  ? formatDecimal(energyData?.data?.totalDemand)
                  : '0,00'}
              </BoxData>
            </Box>
          </EnergySummaryBox>
          <EnergySummaryBox>
            <Box>
              <BoxTypography variant="h5">
                {t('LBLTotalGridEnergy')}
              </BoxTypography>
              <BoxData variant="h1">
                {energyData !== null && energyData?.data?.totalGridEnergy > 0
                  ? formatDecimal(energyData?.data?.totalGridEnergy)
                  : '0,00'}
              </BoxData>
            </Box>
          </EnergySummaryBox>
        </Container>

        {/* The KPIs to show each energy source production share */}
        <HeaderTypography variant="h7">
          {t('LBLLocalEnergyShare')}
        </HeaderTypography>
        <Container>
          <BaseEnergyShareKpi backgroundColor={theme.tokens.tertiaryFixed}>
            <Box>
              <EnergyShareKpiTypography variant="h5">
                {t('LBLBiomassEnergy')}
              </EnergyShareKpiTypography>
              <EnergyShareKpiData variant="h1">
                {energyData !== null &&
                energyData?.data?.totalProductionByEnergySources?.BIO_MASS > 0
                  ? formatDecimal(
                      energyData?.data?.totalProductionByEnergySources?.BIO_MASS
                    )
                  : '0,00'}
              </EnergyShareKpiData>
            </Box>
            <IconWrapper>
              <BioMassIcon style={{ fontSize: 44 }} />
            </IconWrapper>
          </BaseEnergyShareKpi>

          <BaseEnergyShareKpi
            backgroundColor={theme.tokens.customColorSignalContainer}
          >
            <Box>
              <EnergyShareKpiTypography variant="h5">
                {t('LBLSolarEnergy')}
              </EnergyShareKpiTypography>
              <EnergyShareKpiData variant="h1">
                {energyData !== null &&
                energyData?.data?.totalProductionByEnergySources?.SOLAR_POWER >
                  0
                  ? formatDecimal(
                      energyData?.data?.totalProductionByEnergySources
                        ?.SOLAR_POWER
                    )
                  : '0,00'}
              </EnergyShareKpiData>
            </Box>
            <IconWrapper>
              <SolarEnergyIcon style={{ fontSize: 42 }} />
            </IconWrapper>
          </BaseEnergyShareKpi>

          <BaseEnergyShareKpi backgroundColor={theme.tokens.secondary}>
            <Box>
              <EnergyShareKpiTypography
                color={theme.tokens.surfaceBright}
                variant="h5"
              >
                {t('LBLWindEnergykwh')}
              </EnergyShareKpiTypography>
              <EnergyShareKpiData
                color={theme.tokens.surfaceBright}
                variant="h1"
              >
                {energyData !== null &&
                energyData?.data?.totalProductionByEnergySources?.WIND_ENERGY >
                  0
                  ? formatDecimal(
                      energyData?.data?.totalProductionByEnergySources
                        ?.WIND_ENERGY
                    )
                  : '0,00'}
              </EnergyShareKpiData>
            </Box>
            <IconWrapper>
              <WindEnergyIcon style={{ fontSize: 44 }} viewBox="0 0 32 32" />
            </IconWrapper>
          </BaseEnergyShareKpi>

          <BaseEnergyShareKpi backgroundColor={theme.tokens.secondaryFixed}>
            <Box>
              <EnergyShareKpiTypography variant="h5">
                {t('LBLHydroEnergy')}
              </EnergyShareKpiTypography>
              <EnergyShareKpiData variant="h1">
                {energyData !== null &&
                energyData?.data?.totalProductionByEnergySources?.HYDRO_POWER >
                  0
                  ? formatDecimal(
                      energyData?.data?.totalProductionByEnergySources
                        ?.HYDRO_POWER
                    )
                  : '0,00'}
              </EnergyShareKpiData>
            </Box>
            <IconWrapper>
              <HydroEnergyIcon style={{ fontSize: 44 }} viewBox="0 0 32 32" />
            </IconWrapper>
          </BaseEnergyShareKpi>
        </Container>
        {/* This is the button on dashboard to move directl to the Energy Mix page. */}
        <EnergyMixButton onClick={handleClick}>
          <Typography variant="h5" sx={{ color: theme.tokens.primary }}>
            Energie-Mix Details ansehen
          </Typography>
        </EnergyMixButton>

        {/* ENERGY GRAPH AND RELATED COMPONENTS */}
        <EnergyBarChart
          graphData={salePurchaseData}
          timePeriod={timePeriod}
          startDate={startDate}
          endDate={endDate}
        />

        {/* selection of YEAR or YESTERDAY  To show in table under the bar graph on dashboard page*/}

        {/* {(selected === 'YEAR' || selected === 'YESTERDAY') && (
                    <>
                    <StyledDiv>
                    
                        <Box display="flex" alignItems="center" marginTop={2}>
                        
                            <Grid container spacing={30} alignItems="center">
                            <Grid item xs={12} sm={6} md={4}>
                                <StyledButton fullWidth sx={{display:"none"}}>
                                <StyledText variant="h5" align="left">
                                    <FileDownloadIcon sx={{ verticalAlign: 'middle' }} /> Export to .xls
                                </StyledText>
                                </StyledButton>
                            </Grid>
                            </Grid>
                        </Box>
                    
                    </StyledDiv>
                    </>
                        )
                    } */}
      </OuterSurface>

      {/* Error messages */}
      <Feedback
        open={error}
        title={t('LBLDataInfo')}
        message={
          <>
            <Typography align="center">{t('LBLDataMsg')}</Typography>
          </>
        }
        handleClose={closeFeedback}
      />
    </React.Fragment>
  )
}
export default EnergyKpi
