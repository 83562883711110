import styled from 'styled-components/macro'
import {
  Button,
  Paper as MuiPaper,
  Box,
  Typography,
  TextField as MuiTextField,
} from '@mui/material'
import React from 'react'

/**
 * This file holds most of the design commponents used in the dashboard pages.
 */
export const HeaderTypography = styled(Typography)`
  && {
    color: ${(props) => props.theme.tokens.onSurfaceVariant};
    position: absolute;
    top: 30px;
    left: 30px;
  }
`

export const WelcomeTypography = styled(Typography)`
  && {
    color: ${(props) => props.theme.tokens.onSurfaceVariant};
    position: absolute;
    left: 30px;
  }
`
export const OuterSurface = styled(MuiPaper)`
  && {
    background-color: ${(props) => props.theme.tokens.surfaceBright};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin: 10px auto;
    padding: 50px 50px;
    max-width: 98%;
    display: flex;
    flex-direction: column;
    align-items: start;
  }
`

//styling for buttons
export const ButtonGroup = styled(Box)`
  && {
    display: flex;
    padding: 24px 0px;
    position: relative;
    top: 0;
    left: 0;
    button {
      border-radius: 0;
    }
    button:first-of-type {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }
    button:last-of-type {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }
    @media (max-width: 600px) {
      flex-direction: column;
      align-items: center;
      button {
        min-width: 100%;
        margin-bottom: 8px;
      }
      button:last-of-type {
        margin-bottom: 0;
      }
    }

    @media (min-width: 601px) and (max-width: 900px) {
      flex-direction: row;
      flex-wrap: wrap;
      button {
        flex: 1 0 45%; /* Adjust width of buttons for medium screens */
        margin: 4px;
      }
    }

    @media (min-width: 901px) {
      flex-direction: row;
      button {
        flex: 1;
        margin: 0;
      }
    }
  }
`

export const StyledMenuButton = styled(Button)`
  && {
    &.MuiButton-containedPrimary {
      background-color: ${(props) => props.theme.tokens.tertiaryFixed};
      border: 1px solid ${(props) => props.theme.tokens.primary};
    }
    &.MuiButton-outlined {
      border: 1px solid ${(props) => props.theme.tokens.primary};
    }
    &.selected {
      background-color: ${(props) => props.theme.tokens.tertiaryFixed};
      color: ${(props) => props.theme.tokens.onTertiaryFixedVariant};
      svg {
        margin-left: 8px;
      }
    }
    // Add size adjustments here
    min-width: 150px;
    height: 48px;
    padding: 4px 0px;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    flex: 1 0 auto;
    @media (max-width: 600px) {
      width: 100%; /* Full width for small screens */
    }

    @media (min-width: 601px) and (max-width: 900px) {
      flex: 1 0 45%; /* Adjust width for medium screens */
    }

    @media (min-width: 901px) {
      flex: 1;
    }
  }
`
export const StyledButton = styled(Button)`
  && {
    background-color: ${(props) => props.theme.tokens.primary};
    border-radius: 6px;
    // Add size adjustments here
    min-width: 150px;
    height: 48px;
    margin: 20px auto;
    padding: 4px 0px;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;

    & > .MuiSvgIcon-root {
      color: ${(props) => props.theme.tokens.onPrimaryFixed};
      margin-right: 8px;
    }
  }
`

export const StyledDiv = styled(Box)(({ theme }) => ({
  backgroundColor: theme.tokens.tertiary,
  display: 'flex',
  flexWrap: 'wrap',
  padding: '16px 194px 28px 24px',
  alignItems: 'flex-end',
  gap: '53px',
  [theme.breakpoints.up('xs')]: {
    width: '98%', // Adjust top value for extra-small screens
  },
  [theme.breakpoints.up('sm')]: {
    width: '98%', // Adjust top value for small screens
  },
  [theme.breakpoints.up('md')]: {
    width: '98%', // Adjust top value for medium screens
  },
  [theme.breakpoints.up('lg')]: {
    width: '98%', // Adjust top value for large screens
  },
  [theme.breakpoints.up('xl')]: {
    width: '98%', // Adjust top value for extra-large screens
  },
}))
export const StyledLabel = styled(Typography)`
  && {
    color: ${(props) => props.theme.tokens.secondary};
    font-weight: bold;
  }
`
export const StyledText = styled(Typography)`
  && {
    color: ${(props) => props.theme.tokens.onPrimaryFixed};
  }
`

export const StyledButtonText = styled(Typography)`
  && {
    color: ${(props) => props.theme.tokens.onTertiaryFixedVariant};
  }
`
export const StyledTextField = styled(MuiTextField)`
  && {
    border: 1px solid ${(props) => props.theme.tokens.outline};
    background-color: ${(props) => props.theme.tokens.surfaceBright};
    height: 40px;
    align-items: center;
    display: relative;
    top: 15px;
    input::placeholder {
      color: ${(props) => props.theme.tokens.secondary};
      font-weight: bold;
    }
    input {
      color: ${(props) => props.theme.tokens.secondary};
    }
  }
`

export const StyledTimePicker = styled(MuiTextField)`
  && {
    border: 1px solid ${(props) => props.theme.tokens.outline};
    background-color: ${(props) => props.theme.tokens.surfaceBright};
    height: 40px;
    width: 96px;
    align-items: center;
    display: relative;
    top: 15px;
    input::placeholder {
      color: ${(props) => props.theme.tokens.secondary};
      font-weight: bold;
    }
    input {
      color: ${(props) => props.theme.tokens.secondary};
    }
  }
`

export const BoxTypography = styled(Typography)`
  && {
    color: ${(props) => props.theme.tokens.secondary};
    padding: 10px 10px;
    display: inline;
  }
`
export const BoxData = styled(Typography)`
  && {
    color: ${(props) => props.theme.tokens.secondary};
    padding: 10px 10px;
    margin-left: 10px;
  }
`
export const BaseEnergyShareKpi = styled(Box)(({ theme, backgroundColor }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  flex: '1 0 20%',
  margin: '10px',
  padding: '10px',
  backgroundColor: backgroundColor,
  borderRadius: '20px',
  [theme.breakpoints.up('xs')]: {
    width: '100px',
    hight: '30px',
  },
  [theme.breakpoints.up('sm')]: {
    width: '150px',
    hight: '50px',
  },
  [theme.breakpoints.up('md')]: {
    width: '150px',
    hight: '70px',
  },
  [theme.breakpoints.up('lg')]: {
    width: '190px',
    hight: '60px',
  },
  [theme.breakpoints.up('xl')]: {
    width: '330px',
    hight: '90px',
  },
}))
const BaseEnergyShareKpiTypography = styled(Typography)`
  && {
    color: ${(props) => props.color || props.theme.tokens.onSecondaryFixed};
    padding: 0px 10px;
    display: inline;
  }
`
const BaseEnergyShareKpiData = styled(Typography)`
  && {
    color: ${(props) => props.color || props.theme.tokens.onSecondaryFixed};
    padding: 10px 10px;
    margin-left: 10px;
  }
`
export const IconWrapper = styled(Box)`
  && {
    padding-right: 20px; /* Adjust the padding value as needed */
  }
`

export const EnergyShareKpiTypography = (props) => (
  <BaseEnergyShareKpiTypography {...props} />
)

export const EnergyShareKpiData = (props) => (
  <BaseEnergyShareKpiData {...props} />
)

export const EnergyMixButton = styled(Button)`
  && {
    border: 1px solid ${(props) => props.theme.tokens.primary};
    border-radius: 14px;
    display: flex;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    margin: 10px;
  }
`
export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const EnergyKpiContainer = styled.div`
  && {
    display: flex;
    flex-wrap: wrap;
    margin: 12px 0px 12px 10px;
    gap: 10px;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`

export const EnergySummaryBox = styled(Box)(({ theme, borderColor }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '300px',
  hight: '90px',
  margin: '10px',
  borderLeft: '2px solid',
  borderColor: borderColor || theme.tokens.secondary,
  padding: '10px',
  backgroundColor: theme.tokens.surfaceBright,
  [theme.breakpoints.up('xs')]: {
    width: '100px',
    hight: '30px',
  },
  [theme.breakpoints.up('sm')]: {
    width: '150px',
    hight: '50px',
  },
  [theme.breakpoints.up('md')]: {
    width: '150px',
    hight: '70px',
  },
  [theme.breakpoints.up('lg')]: {
    width: '200px',
    hight: '60px',
  },
  [theme.breakpoints.up('xl')]: {
    width: '330px',
    hight: '90px',
  },
}))
export const EnergySoldPurchaseBox = styled(Box)(({ theme, borderColor }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  margin: '10px',
  borderLeft: '2px solid',
  borderColor: borderColor || theme.tokens.secondary,
  padding: '10px',
  backgroundColor: theme.tokens.surfaceBright,
  [theme.breakpoints.up('xs')]: {
    width: '100px',
    hight: '30px',
  },
  [theme.breakpoints.up('sm')]: {
    width: '150px',
    hight: '50px',
  },
  [theme.breakpoints.up('md')]: {
    width: '150px',
    hight: '70px',
  },
  [theme.breakpoints.up('lg')]: {
    width: '200px',
    hight: '60px',
  },
  [theme.breakpoints.up('xl')]: {
    width: '330px',
    hight: '90px',
  },
}))

export const GraphContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  marginTop: '40px',
  width: '100%',
  height: '450px',
  [theme.breakpoints.up('sm')]: {
    height: '150px',
  },
  [theme.breakpoints.up('md')]: {
    height: '250px',
  },
  [theme.breakpoints.up('lg')]: {
    height: '350px',
  },
  [theme.breakpoints.up('xl')]: {
    height: '450px',
  },
}))

export const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  flex: '1 0 20%',
  margin: '10px',
  padding: '10px',
  backgroundColor: theme.tokens.surfaceBright,
  borderRadius: '20px',
  [theme.breakpoints.up('xs')]: {
    width: '100px',
    hight: '30px',
  },
  [theme.breakpoints.up('sm')]: {
    width: '150px',
    hight: '50px',
  },
  [theme.breakpoints.up('md')]: {
    width: '150px',
    hight: '60px',
  },
  [theme.breakpoints.up('lg')]: {
    width: '180px',
    hight: '70px',
  },
  [theme.breakpoints.up('xl')]: {
    width: '250px',
    hight: '90px',
  },
}))
