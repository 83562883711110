import { lazy, Suspense } from 'react'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import { Container, Link as MuiLink, Stack, Typography } from '@mui/material'
import React from 'react'
import { userService } from '../../services/utils/axios'
import LoadingButton from '../core/LoadingButton'
import axios from 'axios'

const initialValues = {
  email: '',
  feedback: { title: '', detail: '' },
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Bitte geben Sie eine gültige E-Mail Adresse ein.')
    .max(255)
    .required('Bitte dieses Feld ausfüllen')
    .label('E-Mail'),
})

const Feedback = lazy(() => import('./Feedback'))

function ForgetPassword() {
  /**
   * @method
   * @param {typeof initialValues} values - form values from formik
   * @param {import("formik").FormikHelpers<typeof initialValues>} actions
   */
  const handleSubmit = async (
    values,
    { setStatus, setErrors, setSubmitting }
  ) => {
    try {
      await userService.get(`/forget-password/${values.email}`)

      setStatus({ success: true, completed: true })
    } catch (error) {
      const message = axios.isAxiosError(error)
        ? {
            title: error.response?.data.error[0].title.text,
            detail: error.response?.data.error[0].detail.text,
          }
        : {
            title: 'Falsche Eingabe',
            detail:
              error.message ||
              'Die Anmeldung war nicht erfolgreich. Bitte überprüfen Sie Ihre Eingaben und versuchen es erneut',
          }

      setStatus({ success: false, completed: true })
      setErrors({ feedback: message })
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Container maxWidth="xs">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, status, isSubmitting, setStatus }) => (
          <Form noValidate>
            <Suspense fallback={<div />}>
              <Feedback
                open={Boolean(status?.completed)}
                title={
                  status?.success
                    ? 'Anfrage Gesendet'
                    : errors.submit?.title || ''
                }
                severity={status?.success ? 'success' : 'warning'}
                message={
                  status?.success ? (
                    <>
                      <Typography>
                        Vielen Dank für Ihre Anfrage zum Zurücksetzen des
                        Passworts.
                      </Typography>
                      <Typography>
                        Wenn Sie bereits uns registriert sind, werden Sie in
                        Kürze eine E-Mail mit den erforderlichen Anweisungen
                        erhalten.
                      </Typography>
                    </>
                  ) : (
                    errors.submit?.detail ||
                    'Bitte geben Sie eine registrierte E-Mail Adresse ein.'
                  )
                }
                handleClose={() => setStatus({ ...status, completed: false })}
              />
            </Suspense>
            <Stack justifyContent="center" alignItems="center">
              <Field
                component={TextField}
                name="email"
                label="E-Mail"
                margin="normal"
                sx={{ my: 10, maxWidth: 300 }}
                fullWidth
                required
              />
              <LoadingButton
                type="submit"
                variant="contained"
                color="primary"
                sx={{ px: 3, my: 4, borderRadius: 1 }}
                loading={isSubmitting}
              >
                Passwort zurücksetzen
              </LoadingButton>
            </Stack>
          </Form>
        )}
      </Formik>
      <MuiLink
        component={Link}
        to="/login"
        color="text.primary"
        underline="always"
        fontWeight={500}
        align="center"
        sx={{ mt: 3, width: '100%', display: 'inline-block' }}
      >
        Zurück zum Login
      </MuiLink>
    </Container>
  )
}

export default ForgetPassword
