import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { WindIconWithoutBackground } from '../../components/icons/myIcons'
import { Typography } from '@mui/material'
import styled from 'styled-components/macro'
import gridImg from '../EnergyMix/Assets/netzstrom_oli.svg'
import biomassImg from '../EnergyMix/Assets/biomasse_oli.svg'
import solarImg from '../EnergyMix/Assets/solar_oli.svg'
import wasserImg from '../EnergyMix/Assets/wasserkraft_oli.svg'
import { formatDecimal } from '../../services/utils/format-numbers'
import { useTheme } from '@mui/material/styles'
import React from 'react'

const SubColumnHeading = styled(Typography)`
  && {
    color: ${(props) => props.theme.tokens.secondary};
  }
`
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '1px',
    transform: 'scaleY(1.2)',
    backgroundColor: theme.tokens.secondary,
  },
}))

const TableContainerStyled = styled(TableContainer)`
  width: 90%; // Adjust width as needed
  margin: 0 auto; // Center the table
`

const monthNames = {
  JANUARY: 'Januar',
  FEBRUARY: 'Februar',
  MARCH: 'März',
  APRIL: 'April',
  MAY: 'Mai',
  JUNE: 'Jun',
  JULY: 'Juli',
  AUGUST: 'August',
  SEPTEMBER: 'September',
  OCTOBER: 'Oktober',
  NOVEMBER: 'November',
  DECEMBER: 'Dezember',
}

const dataWithZeroValues = [
  {
    time: 'January',
    bioMasse: 0.0,
    solar: 0.0,
    windStrom: 0.0,
    wasserKraft: 0.0,
    restStrom: 0.0,
  },
  {
    time: 'February',
    bioMasse: 0.0,
    solar: 0.0,
    windStrom: 0.0,
    wasserKraft: 0.0,
    restStrom: 0.0,
  },
  {
    time: 'March',
    bioMasse: 0.0,
    solar: 0.0,
    windStrom: 0.0,
    wasserKraft: 0.0,
    restStrom: 0.0,
  },
  {
    time: 'April',
    bioMasse: 0.0,
    solar: 0.0,
    windStrom: 0.0,
    wasserKraft: 0.0,
    restStrom: 0.0,
  },
  {
    time: 'May',
    bioMasse: 0.0,
    solar: 0.0,
    windStrom: 0.0,
    wasserKraft: 0.0,
    restStrom: 0.0,
  },
  {
    time: 'June',
    bioMasse: 0.0,
    solar: 0.0,
    windStrom: 0.0,
    wasserKraft: 0.0,
    restStrom: 0.0,
  },
  {
    time: 'July',
    bioMasse: 0.0,
    solar: 0.0,
    windStrom: 0.0,
    wasserKraft: 0.0,
    restStrom: 0.0,
  },
  {
    time: 'August',
    bioMasse: 0.0,
    solar: 0.0,
    windStrom: 0.0,
    wasserKraft: 0.0,
    restStrom: 0.0,
  },
  {
    time: 'September',
    bioMasse: 0.0,
    solar: 0.0,
    windStrom: 0.0,
    wasserKraft: 0.0,
    restStrom: 0.0,
  },
  {
    time: 'October',
    bioMasse: 0.0,
    solar: 0.0,
    windStrom: 0.0,
    wasserKraft: 0.0,
    restStrom: 0.0,
  },
  {
    time: 'November',
    bioMasse: 0.0,
    solar: 0.0,
    windStrom: 0.0,
    wasserKraft: 0.0,
    restStrom: 0.0,
  },
  {
    time: 'December',
    bioMasse: 0.0,
    solar: 0.0,
    windStrom: 0.0,
    wasserKraft: 0.0,
    restStrom: 0.0,
  },
]
const PriceTable = ({ priceData, selectedType }) => {
  const theme = useTheme()
  const data = priceData ? priceData : dataWithZeroValues
  const transformedData = data.map((item) => ({
    ...item,
    time: monthNames[item.time],
  }))
  return (
    <>
      <TableContainerStyled component={Paper}>
        <Table sx={{ border: 'none' }} size="medium">
          <TableHead
            sx={{
              borderTop: '1px solid ',
              borderColor: (theme) => theme.tokens.outlineVariant,
              backgroundColor: (theme) => theme.tokens.surfaceContainerLow,
            }}
          >
            <TableRow
              sx={{
                borderBottom: 2.5,
                borderColor: (theme) => theme.tokens.secondary,
              }}
            >
              <TableCell>
                <SubColumnHeading variant="h5">Monat</SubColumnHeading>
              </TableCell>

              <TableCell>
                <img src={biomassImg} alt="Biomass" />
              </TableCell>
              <TableCell>
                <img src={solarImg} alt="SolarEnergy" />
              </TableCell>
              <TableCell>
                <img src={wasserImg} alt="HydroPower" />
              </TableCell>
              <TableCell>
                <WindIconWithoutBackground />
              </TableCell>
              {selectedType === 'CONSUMER' && (
                <TableCell>
                  <img src={gridImg} alt="GridEnergy" />
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody style={{ backgroundColor: theme.tokens.surfaceBright }}>
            {transformedData.map((data, dataIndex) => (
              <StyledTableRow>
                <TableCell>{data?.time}</TableCell>
                <TableCell>
                  {data?.bioMasse !== null
                    ? formatDecimal(data?.bioMasse)
                    : '--'}
                </TableCell>
                <TableCell>
                  {data?.solar !== null ? formatDecimal(data?.solar) : '--'}
                </TableCell>
                <TableCell>
                  {data?.windStrom !== null
                    ? formatDecimal(data?.windStrom)
                    : '--'}
                </TableCell>
                <TableCell>
                  {data?.wasserKraft !== null
                    ? formatDecimal(data?.wasserKraft)
                    : '--'}
                </TableCell>
                {selectedType === 'CONSUMER' && (
                  <TableCell>
                    {data?.restStrom !== null || 0
                      ? formatDecimal(data?.restStrom)
                      : '--'}
                  </TableCell>
                )}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainerStyled>
    </>
  )
}

export default PriceTable
