import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 'LIGHT',
}

export const themeSlice = createSlice({
  name: 'themeMode',
  initialState,
  reducers: {
    changeThemeMode: (state) => {
      if (state.value === 'DARK') state.value = 'LIGHT'
      else state.value = 'DARK'
    },
  },
})

export const { changeThemeMode } = themeSlice.actions

export default themeSlice.reducer
