import { useEffect, useState } from 'react'
import React from 'react'
import DetailsWrapper from '../../../layouts/DetailsWrapper'
import { TextField, MenuItem, Typography } from '@mui/material'
import { ArrowForward } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import EditUserDetails from './EditUserDetails'
import StepperPopup from '../../../components/StepperPopup'
import ConfirmUserDetails from './ConfirmUserDetails'
import PaymentDetails from './PaymentDetails'
import ProductionPlantDetails from './ProductionPlantDetails'
import OtherDataDetails from './OtherDataDetails'
import Feedback from '../../../components/Feedback'
import BillingAddressDetails from './BillingAddressDetails'
import LogoLoader from '../../../components/LogoLoader'
import PermMediaIcon from '@mui/icons-material/PermMedia'
import UserDetails from './UserDetails'
import EditIcon from '@mui/icons-material/Edit'
import api from '../../../Middleware/api/api'
import { useAuth } from '../../../services/contexts'
import ErrorFeedback from '../../../components/DataTable/Feedback'
import { useSelector } from 'react-redux'
import {
  PaperItem,
  PaperItemBody,
  StyledTypographyHeading,
  CustomizedButton,
} from './styles'
import RouteNotFound from '../../RouteNotFound'

/**
 * The main component that shows the user details after user clicks on the details in the table of Nutzerverwaltung page.
 * This also holds the button to edit the details of a contract.
 *
 * @returns {React.ReactNode} - Returns all the component that are show in user details page.
 */

export default function NutzerDetail() {
  const { token } = useAuth()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [selectedContractId, setSelectedContractId] = useState(null)
  const [refreshTheData, setRefreshTheData] = useState(false)
  const [contract] = useState()
  const [editUserDetails, setEditUserDetails] = useState(false)
  const [step, setStep] = useState(0)
  const [canNext, setCanNext] = useState(false)
  const [submit, setSubmit] = useState(0)
  const [confirmed, setConfirmed] = useState(0)
  const [canNextOne, setCanNextOne] = useState(false)
  const [canNextTwo, setCanNextTwo] = useState(false)
  const [canNextThree, setCanNextThree] = useState(false)
  const [canNextBilling, setCanNextBilling] = useState(false)
  const [submitOne, setSubmitOne] = useState(0)
  const [submitTwo, setSubmitTwo] = useState(0)
  const [submitThree, setSubmitThree] = useState(0)
  const [submitBilling, setSubmitBilling] = useState(0)
  const handleNext = () => setStep(step + 1)
  const handlePrev = () => setStep(step - 1)
  const [payload, setPayload] = useState()
  const [payloadOne, setPayloadOne] = useState()
  const [payloadTwo, setPayloadTwo] = useState()
  const [payloadThree, setPayloadThree] = useState()
  const [payloadBilling, setPayloadBilling] = useState()
  const [completed, setCompleted] = useState(false)
  const openFeedback = () => setCompleted(true)
  const [error, setError] = useState(false)
  const [showEditButton, setShowEditButton] = useState(false)
  const [editContractData, setEditContractData] = useState()
  const [consumer, setConsumer] = useState(false)
  const [refreshed, setRefreshed] = useState(false)
  const [updatedContract, setUpdatedContract] = useState()

  ////////////
  const location = useLocation()
  const { user, contractDetails } = location.state || {}
  const [contractDataDto, setContractDataDto] = useState(
    user?.contractDataDto || []
  )
  const isBillinbAddressSame = useSelector(
    (state) => state.address.isBillingAddressSame
  )

  useEffect(() => {
    if (contractDetails) {
      updatedContract && refreshed
        ? setEditContractData(updatedContract)
        : setEditContractData(contractDetails)
      setShowEditButton(true)

      // this portion of code is used to show the selected contract in the dropdown menu
      // If user has come over user details page by clicking on the Details in the table
      const contractIndex = user.contractDataDto.findIndex(
        (contract) => contract.contractKey === contractDetails.contractKey
      )
      if (contractIndex !== -1) {
        setSelectedContractId(contractDataDto[contractIndex].contractKey)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractDetails, contractDataDto, updatedContract])

  useEffect(() => {
    if (refreshTheData) {
      fetchContractDetails().then(() => {
        setRefreshTheData(false)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTheData])

  const handleSetPayload = (input) => {
    setPayload(input)
  }
  const handleSetPayloadOne = (input) => {
    setPayloadOne(input)
  }
  const handleSetPayloadTwo = (input) => {
    setPayloadTwo(input)
  }

  const handleSetPayloadThree = (input) => {
    setPayloadThree(input)
  }

  const handleSetPayloadBilling = (input) => {
    setPayloadBilling(input)
  }

  const handleContractChange = (event) => {
    setShowEditButton(true)
    const selectedContractId = event.target.value
    const selectedContract = contractDataDto.find(
      (contract) => contract.contractKey === selectedContractId
    )
    if (selectedContract) {
      setEditContractData(
        refreshed &&
          updatedContract?.contractKey === selectedContract.contractKey
          ? updatedContract
          : selectedContract
      )
    }

    setSelectedContractId(selectedContractId)
    setRefreshed(false)
  }
  /**
   * This api call is use to fetch the single contract data.
   * For detail response visit the following page in docs.
   * [API Respnse Documentation](./src/docs/apiResponse.md)
   *
   */
  const fetchContractDetails = async () => {
    try {
      const response = await api.fetchContractData(
        token,
        editContractData?.contractKey
      )
      if (response.data) {
        const updatedData = response?.data?.data
        if (updatedData) {
          setUpdatedContract(updatedData)
          setEditContractData(updatedData)

          setContractDataDto((prevContracts) =>
            prevContracts.map((contract) =>
              contract.contractKey === updatedData.contractKey
                ? updatedData
                : contract
            )
          )
        }
        setRefreshed(true)
      }
    } catch (error) {
      setRefreshed(false)
      setError(true)
    }
  }

  const handleClick = (value) => {
    if (value?.contractType === 'CONSUMER') setConsumer(true)

    setEditContractData(refreshed ? updatedContract : value)
    setEditUserDetails(true)
  }
  const setSubmittingConfirm = () => {
    setEditUserDetails(false)
  }
  const handleNextevent = (input) => {
    handleNext()
  }
  const onClose = (_event, reason) => {
    if (reason !== 'backdropClick') {
      setStep(0)
      setPayload(null)
    }
  }
  /** @type {import("@mui/material").DialogProps['onClose']} */
  const onCloseOne = (_event, reason) => {
    if (reason !== 'backdropClick') {
      setPayloadOne(null)

      setStep(1)
    }
  }
  /** @type {import("@mui/material").DialogProps['onClose']} */
  const onCloseTwo = (_event, reason) => {
    if (reason !== 'backdropClick') {
      setPayloadTwo(null)
      setStep(2)
    }
  }
  /** @type {import("@mui/material").DialogProps['onClose']} */
  const onCloseThree = (_event, reason) => {
    if (reason !== 'backdropClick') {
      setPayloadThree(null)
      setStep(3)
    }
  }
  const closeFeedback = () => {
    setCompleted(false)
    setPayload(null)
    setError(false)
  }
  if (!user) {
    return (
      <RouteNotFound
        title="Seite nicht gefunden"
        description="Bitte überprüfen Sie den Link oder gehen Sie zur Benutzerverwaltung"
        buttonText="zum Benutzerverwaltung"
        to="/nutzer"
      />
    )
  }
  return (
    <>
      <DetailsWrapper
        title={t('LBLUserDetails')}
        loading={loading}
        helmet="invoices"
        backButtonConfig={{ label: t('lblBacktoview'), url: '/nutzer' }}
        contract={''}
        fullName={user.fullName}
      >
        <PaperItem>
          <StyledTypographyHeading>{t('LBLContracts')}</StyledTypographyHeading>

          <div
            style={{
              width: '20%',
              height: '70px',
              textAlign: 'left',
              float: 'left',
            }}
          >
            <div
              style={{
                backgroundColor: 'transparent',
                marginLeft: '25px',
                marginTop: '-15px',
              }}
            >
              <TextField
                select
                id="contractDetails"
                label={t('LBLContractSelection')}
                onChange={handleContractChange}
                value={selectedContractId || ''}
                name="contractDetailSelection"
                style={{
                  width: '300px',
                  color: (theme) => theme.props.surfaceBright,
                }}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        width: '200px',
                        color: (theme) => theme.props.surfaceBright,
                      },
                    },
                  },
                }}
              >
                {contractDataDto && contractDataDto.length > 0 ? (
                  contractDataDto.map((contract, index) => (
                    <MenuItem
                      size="large"
                      key={index}
                      value={contract.contractKey}
                    >
                      {contract?.contractType === 'CONSUMER'
                        ? ` ${t('LBLConsumerContract')} ${index + 1}`
                        : `${t('LBLProducerContract')} ${index + 1}`}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">
                    <Typography fontWeight="bold" variant="h4" gutterBottom>
                      {t('LBLSelectContract')}
                    </Typography>
                  </MenuItem>
                )}
              </TextField>
            </div>
          </div>
          {showEditButton && (
            <CustomizedButton
              id="Edit-button"
              onClick={() => handleClick(editContractData)}
              variant="contained"
            >
              <EditIcon />
              {t('LBLEditUserDetail')}
            </CustomizedButton>
          )}
        </PaperItem>

        {selectedContractId && selectedContractId != null ? (
          !contractDataDto ? (
            <LogoLoader loading={true} />
          ) : (
            contractDataDto
              .map((contract) =>
                updatedContract &&
                contract.contractKey === updatedContract.contractKey
                  ? updatedContract
                  : contract
              )
              .map((contract) => {
                if (contract.contractKey === selectedContractId) {
                  return (
                    <>
                      <UserDetails
                        contract={refreshed ? updatedContract : contract}
                      />
                    </>
                  )
                } else {
                  return null
                }
              })
          )
        ) : contractDetails ? (
          <>
            <UserDetails
              contract={refreshed ? updatedContract : contractDetails}
            />
          </>
        ) : (
          <>
            <PaperItemBody>
              <PermMediaIcon
                sx={{
                  fontSize: 140,
                  padding: '20px',
                  color: (theme) => theme.tokens.secondary,
                }}
              />
              <Typography fontWeight="bold" variant="h2" gutterBottom>
                {t('LBLSelectContract')}
              </Typography>
            </PaperItemBody>
          </>
        )}
      </DetailsWrapper>

      {/* user details edit workflow starts here: */}
      <StepperPopup
        widthInput="1000"
        title={t('LBLEditUser')}
        isOpen={editUserDetails}
        setIsOpen={setEditUserDetails}
        skipBillingAddress={isBillinbAddressSame}
        steps={[
          {
            label: t('LBLUserInfo'),
            component: (
              <EditUserDetails
                setPayload={handleSetPayload}
                handleClose={onClose}
                confirmPayload={handleNext}
                payload={editContractData}
                setSubmit={setSubmit}
                setCanNext={setCanNext}
                submit={submit}
              />
            ),
            nextAction: {
              label: t('LBLFurther'),
              icon: <ArrowForward />,
              action: () => {
                setSubmit((prevState) => prevState + 1)
              },
              canNext: canNext,
            },
          },
          {
            label: t('LBLBillingAddressInfo'),
            component: (
              <BillingAddressDetails
                setPayload={handleSetPayloadBilling}
                handleClose={onClose}
                confirmPayload={handleNext}
                payload={editContractData}
                setSubmit={setSubmitBilling}
                setCanNext={setCanNextBilling}
                submit={submitBilling}
              />
            ),
            nextAction: {
              label: t('LBLFurther'),
              icon: <ArrowForward />,
              action: () => {
                setSubmitBilling((prevState) => prevState + 1)
              },
              canNext: canNextBilling,
            },
          },
          {
            label: t('LBLAccountData'),
            component: (
              <PaymentDetails
                setPayloadOne={handleSetPayloadOne}
                handleClose={onCloseOne}
                confirmPayload={handleNextevent}
                payload={editContractData}
                setSubmit={setSubmitOne}
                setCanNext={setCanNextOne}
                submit={submitOne}
              />
            ),
            nextAction: {
              label: t('LBLFurther'),
              icon: <ArrowForward />,
              action: () => {
                setSubmitOne((prevState) => prevState + 1)
              },
              canNext: canNextOne,
            },
          },
          {
            label: consumer
              ? t('LBLDeliveryPoint')
              : t('LBLGenerationPlantDetails'),
            component: (
              <ProductionPlantDetails
                setPayload={handleSetPayloadTwo}
                handleClose={onCloseTwo}
                confirmPayload={handleNext}
                payload={editContractData}
                setSubmit={setSubmitTwo}
                setCanNext={setCanNextTwo}
                submit={submitTwo}
              />
            ),
            nextAction: {
              label: t('LBLFurther'),
              icon: <ArrowForward />,
              action: () => {
                setSubmitTwo((prevState) => prevState + 1)
              },
              canNext: canNextTwo,
            },
          },
          {
            label: t('LBLOtherData'),
            component: (
              <OtherDataDetails
                setPayloadThree={handleSetPayloadThree}
                handleClose={onCloseThree}
                confirmPayload={handleNext}
                payload={editContractData}
                setSubmit={setSubmitThree}
                setCanNext={setCanNextThree}
                submit={submitThree}
              />
            ),
            nextAction: {
              label: t('LBLFurther'),
              icon: <ArrowForward />,
              action: () => {
                setSubmitThree((prevState) => prevState + 1)
              },
              canNext: canNextThree,
            },
          },
          {
            label: t('confirmInfo'),
            component: (
              <ConfirmUserDetails
                payload={{
                  salutation: payload?.salutation,
                  fullName: payload?.fullName,
                  address: payload?.address,
                  billingAddress: isBillinbAddressSame
                    ? {
                        addressOwner: payload?.fullName,
                        city: payload?.address?.city,
                        street: payload?.address?.street,
                        houseNr: payload?.address?.houseNr,
                        postalCode: payload?.address?.postalCode,
                      }
                    : {
                        addressOwner:
                          payloadBilling?.billingAddress?.addressOwner,
                        city: payloadBilling?.billingAddress?.city,
                        street: payloadBilling?.billingAddress?.street,
                        houseNr: payloadBilling?.billingAddress?.houseNr,
                        postalCode: payloadBilling?.billingAddress?.postalCode,
                      },
                  registeredCourt: payload?.registeredCourt,
                  registrationNumber: payload?.registrationNumber,
                  phoneNumber: payload?.phoneNumber,
                  dateOfBirth: payload?.dateOfBirth,
                  emailAddress: payload?.emailAddress,
                  contractNumber: payloadThree?.contractNumber,
                  contractStatus: payloadThree?.contractStatus,
                  contractType: payloadThree?.contractType,
                  preference: payloadTwo?.preference,
                  startOfDelivery: payloadTwo?.startOfDelivery,
                  nextPossibleDate: payloadTwo?.nextPossibleDate,
                  plantDetails: {
                    plantType: payloadTwo?.plantDetails?.plantType,
                    plantName: payloadTwo?.plantDetails?.plantName,
                    lastSupplier: payloadTwo?.plantDetails?.lastSupplier,
                    costumerNumber: payloadTwo?.plantDetails?.costumerNumber,
                    plantAddress: payloadTwo?.plantDetails?.plantAddress,
                    installedPowerKw:
                      payloadTwo?.plantDetails?.installedPowerKw,
                    commisioningDate:
                      payloadTwo?.plantDetails?.commisioningDate,
                    marketLocation: payloadTwo?.plantDetails?.marketLocation,
                    meterNumber: payloadTwo?.plantDetails?.meterNumber,
                    netOperator: payloadTwo?.plantDetails?.netOperator,
                    plantKey: payloadTwo?.plantDetails?.plantKey,
                  },
                  salesTaxLiability: payloadThree?.salesTaxLiability,
                  paymentDetails: {
                    bankName: payloadOne?.bankName,
                    bic: payloadOne?.bic,
                    iban: payloadOne?.iban,
                    accountHolder: payloadOne?.accountHolder,
                    accept: payloadOne?.accept,
                  },
                  stadtWerkeKunden: payloadTwo?.stadtWerkeKunden,
                  marketingInformation: payloadThree?.marketingInformation,
                  agb: payloadThree?.agb,
                  contractKey: editContractData?.contractKey,
                  addressSame: payload?.addressSame,
                  userId: editContractData?.userId,
                }}
                handleClose={onClose}
                openFeedback={openFeedback}
                backToForm={handlePrev}
                setError={setError}
                setSubmittingConfirm={setSubmittingConfirm}
                submit={confirmed}
                setSubmit={setConfirmed}
                contract={contract}
                setRefreshTheData={setRefreshTheData}
              />
            ),
            nextAction: {
              label: t('LBLSave'),
              action: () => {
                setConfirmed((prevState) => prevState + 1)
                setEditUserDetails(false)
                setLoading(false)
              },
            },
          },
        ]}
      ></StepperPopup>
      <Feedback
        open={completed}
        title={t('LBLContractUpdated')}
        message={
          <>
            <Typography align="center">
              {t('LBLUserContractUpdated')}
            </Typography>
          </>
        }
        handleClose={closeFeedback}
      />
      <ErrorFeedback
        open={error}
        title={t('LBLErrorTitle')}
        severity="warning"
        message={
          <Typography variant="subtitle1" sx={{ width: 400, mx: 10 }}>
            {t('LBLContractUpdateEror')}
          </Typography>
        }
        handleClose={closeFeedback}
      />
    </>
  )
}
