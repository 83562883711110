import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { Outlet } from 'react-router-dom'

import {
  Box,
  CssBaseline,
  Paper as MuiPaper,
  Divider as MuiDivider,
} from '@mui/material'
import { spacing } from '@mui/system'

import GlobalStyle from '../components/GlobalStyle'
import Navbar from '../components/navbar/Navbar'
import dashboardItems from '../components/sidebar/dashboardItems'
import Sidebar from '../components/sidebar/Sidebar'
import Footer from '../components/Footer'

const drawerWidth = 280

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`

//this is the side div on the left side of screen
const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up('md')} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
    background-color: ${(props) => props.theme.tokens.surfaceDim};
  }
`

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 90%;
  background-color: ${(props) => props.theme.tokens.surfaceDim};
  z-index: 10;
`

const Paper = styled(MuiPaper)(spacing)

const MainContent = styled(Paper)`
  flex: 1;
  background-color: transparent;
  position: relative;
  zindex: 0;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`

const Divider = styled(MuiDivider)`
  && {
    width: 100vw;
    height: 1px;
    background-color: ${(props) => props.theme.tokens.outline};
    position: relative;
    z-index: 100;
  }
`

const Dashboard = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  return (
    <>
      <Root>
        <CssBaseline />
        <GlobalStyle />
        <Drawer>
          <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
            <Sidebar
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              items={dashboardItems}
            />
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <Sidebar
              PaperProps={{
                style: {
                  width: 244,
                  left: 30,
                  top: 30,
                  height: '72vh',
                  borderRadius: 24,
                  border: '1px solid rgba(0, 0, 0, 0.1)',
                  boxShadow: '0px 5px 14px 0px rgba(0, 0, 0, 0.05)',
                },
              }}
              items={dashboardItems}
            />
          </Box>
        </Drawer>

        <AppContent>
          <Navbar onDrawerToggle={handleDrawerToggle} />
          <Box sx={{ height: 100 }}></Box>
          <MainContent
            style={{
              marginLeft: 50,
              marginTop: '20px',
              marginRight: 30,
            }}
          >
            {children}
            <Outlet />
          </MainContent>
        </AppContent>
      </Root>
      <Divider />
      <Footer />
    </>
  )
}

export default Dashboard
