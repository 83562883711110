import React from 'react'
import styled from 'styled-components/macro'
import { NavLink } from 'react-router-dom'
import { Drawer as MuiDrawer, ListItemButton } from '@mui/material'
import Logo from '../../vendor/OLI_Community_Logo.png'
import Footer from './SidebarFooter'
import SidebarNav from './SidebarNav'

//this drawer is the compartment for sidebar
const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
    background-color: ${(props) => props.theme.tokens.surfaceBright};
  }
`

const Brand = styled(ListItemButton)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.tokens.surfaceBright};
  background-color: ${(props) => props.theme.tokens.surfaceBright};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-top: ${(props) => props.theme.spacing(8)};
  padding-left: ${(props) => props.theme.spacing(6)};
  padding-right: ${(props) => props.theme.spacing(6)};
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;

  ${(props) => props.theme.breakpoints.up('sm')} {
    min-height: 64px;
  }
`
const SeparatorLine = styled.div`
  width: calc(90% - 20px);
  height: 2px;
  color: ${(props) => props.theme.tokens.surfaceBright};
  background-color: ${(props) => props.theme.tokens.outline};
  margin: 10px auto;
`

const Sidebar = ({ items, showFooter = true, ...rest }) => {
  return (
    <Drawer variant="permanent" {...rest}>
      <Brand component={NavLink} to="/">
        <img src={Logo} alt="logo" width={150} height={35} />
      </Brand>
      <SeparatorLine />
      <SidebarNav items={items} />
      <SeparatorLine />
      {!!showFooter && <Footer />}
    </Drawer>
  )
}

export default Sidebar
