import { CardContent, Typography } from '@mui/material'
import { View, Spacer, Item } from './styles'
import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'
import { styled } from '@mui/material/styles'
import React from 'react'

const StyledView = styled(View)`
  && {
    border-radius: 28px;
    overflow: hidden;
    background-color: ${(props) => props.theme.tokens.surfaceBright};
  }
`
const StyledTypography = styled(Typography)`
  && {
    color: ${(props) => props.theme.tokens.secondary};
  }
`
/**
 * This component is used to update the information related to bank in edit workflow.
 */
function BankInformation({ contract }) {
  const { t } = useTranslation()

  const paymentDetails = {
    BankName: contract?.paymentDetails?.bankName,
    Bic: contract?.paymentDetails?.bic,
    Iban: contract?.paymentDetails?.iban,
    BankAccept: contract?.paymentDetails?.accept,
    AccountHolder: contract?.paymentDetails?.accountHolder,
  }

  const renderKey = useCallback(
    (key) => {
      switch (key) {
        case 'BankName':
          return t('LBLBankName')
        case 'Bic':
          return t('LBLBic')
        case 'Iban':
          return t('LBLIban')
        case 'AccountHolder':
          return t('LBLAccountHolder')
        case 'BankAccept':
          return t('LBLAcceptCondition')
        default:
          return key
      }
    },
    [t]
  )
  return (
    <>
      <StyledView>
        <CardContent>
          <StyledTypography fontWeight="bold" variant="h4" gutterBottom>
            {t('LBLAccountData')}
          </StyledTypography>
          <Spacer mb={4} />
          <StyledTypography variant="body2" component="div" gutterBottom>
            {paymentDetails && (
              <>
                {Object.entries(paymentDetails).map(([key, value]) => {
                  return (
                    <>
                      <Item key={key}>
                        <StyledTypography fontWeight="fontWeightMedium">
                          {renderKey(key)}
                        </StyledTypography>
                        {
                          <StyledTypography
                            fontWeight="fontWeightRegular"
                            align="right"
                          >
                            {typeof value === 'boolean'
                              ? value
                                ? 'True'
                                : 'False'
                              : ['string', 'number'].includes(typeof value)
                              ? value
                              : Array.isArray(value)
                              ? value?.join(', ')
                              : null}
                          </StyledTypography>
                        }
                      </Item>
                    </>
                  )
                })}
              </>
            )}
          </StyledTypography>
        </CardContent>
      </StyledView>
    </>
  )
}

export default BankInformation
