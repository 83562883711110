/**
 * This file is holding all the details about the text used in the dashboard, for example the text sizes, bold , spacing in letters.
 * @type {import("@mui/material").ThemeOptions['typography']}
 */
const typography = {
  // fontFamily: "sans-serif",
  fontFamily: 'Encode Sans Condensed',
  fontSize: 13,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  h1: {
    fontSize: '2.0rem',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '2.25rem',
    lineSpacing: '0.03rem',
  },
  h2: {
    fontSize: '2.75rem',
    fontWeight: 600,
    lineHeight: 1.5,
    lineSpacing: '0.055rem',
  },
  h3: {
    fontSize: '1.375rem',
    fontWeight: 400,
    lineHeight: 1.0,
    letterSpacing: '0.0275rem',
  },
  h4: {
    fontSize: '1.125rem',
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: '0.045rem',
  },
  h5: {
    fontSize: '0.875rem',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '1.4rem',
    letterSpacing: '0.02188rem',
  },
  h6: {
    fontSize: '1rem',
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '1.5rem',
    letterSpacing: '0.03rem',
  },
  h7: {
    fontSize: '1.5rem',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '1.5rem',
    letterSpacing: '0.03rem',
  },
  h8: {
    fontSize: '1rem',
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '1.5rem',
    letterSpacing: '0.04rem',
  },
  body1: {
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.4rem',
    letterSpacing: '0.035rem',
  },
  navBarItems: {
    fontSize: 15,
    fontWeight: 600,
  },
  button: {
    textTransform: 'none',
  },
}

export default typography
