import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useAuth } from '../../services/contexts/AuthContext'
import { useTranslation } from 'react-i18next'
import { Grid, ThemeProvider } from '@mui/material'
import useSWR from 'swr'
import LogoLoader from '../../components/LogoLoader'
import UserTable from './UserTable'
import {
  dataTableTheme,
  HeaderTypography,
  OuterSurface,
  TableContainer,
} from './components'

function Users() {
  const { t } = useTranslation()
  const { token } = useAuth()

  const { data } = useSWR(
    token ? ['/users', token, 'contractManagement'] : null,
    {
      revalidateOnMount: true,
    }
  )

  return (
    <>
      <Helmet title="Nutzers" />

      <Grid
        justifyContent="space-between"
        container
        spacing={10}
        sx={{ visibility: 'transparent' }}
      >
        <Grid item>
          <HeaderTypography variant="h2" gutterBottom display="inline">
            {t('LBLUserMgmt')}
          </HeaderTypography>
        </Grid>
        {/* <Grid item mb={6}>
          <div>
            <CustomizedButton variant="outlined">
              <AddIcon />
              {t('LBLCreateUser')}
            </CustomizedButton>
          </div>
        </Grid> */}
      </Grid>
      <OuterSurface>
        <ThemeProvider
          theme={(appTheme) => ({
            ...appTheme,
            components: {
              ...appTheme.components,
              ...dataTableTheme(appTheme).components,
            },
          })}
        >
          <TableContainer variant="outlined">
            {!data ? (
              <LogoLoader loading={true} />
            ) : (
              <UserTable contract={data} />
            )}
          </TableContainer>
        </ThemeProvider>
      </OuterSurface>
    </>
  )
}

export default Users
