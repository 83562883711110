import PropTypes from 'prop-types'
import React from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../../../services/contexts'
import { formatDecimal } from '../../../services/utils/format-numbers'
import api from '../../../Middleware/api/api'
/**
 * This component is the final part of the edit workflow.
 * It shows all the details that user has updated.
 * In this component the API call is made to update the contract details of user.
 *
 */
function ConfirmUserDetails({
  setRefreshTheData,
  payload,
  openFeedback,
  submit,
  setError,
  setSubmit,
  contract,
}) {
  const { token } = useAuth()
  const { t } = useTranslation()
  useEffect(() => {
    if (submit > 0) updateContractDetails()
    setSubmit(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit])
  /**
   * This api call is use to update the single contract data.
   * It just return in response 'true' if data is updated successfully.
   */
  const updateContractDetails = async () => {
    try {
      const response = await api.updateContractDetails(
        token,
        payload?.contractKey,
        payload
      )
      if (response.data?.error) {
        // If there is an error in updating the contract then error will popup
        setError(true)
        return
      }
      setRefreshTheData(true)
      openFeedback()
    } catch (error) {
      setError(true)
    }
  }

  const renderData = {
    ContractNumber: payload?.contractNumber,
    Name: payload?.salutation + ' ' + payload?.fullName,
    Address: [
      payload?.address?.street + ' ' + payload?.address?.houseNr + ',',
      payload?.address?.postalCode + ' ' + payload?.address?.city,
    ],
    'E-Mail': payload?.emailAddress,
    PhoneNumber: payload?.phoneNumber,
    DateOfBirth: payload?.dateOfBirth,
    BillingOwner: payload?.billingAddress?.addressOwner,
    BillingAddress: [
      payload?.billingAddress?.street +
        ' ' +
        payload?.billingAddress?.houseNr +
        ',',
      payload?.billingAddress?.postalCode + ' ' + payload?.billingAddress?.city,
    ],
    BankName: payload?.paymentDetails?.bankName,
    Bic: payload?.paymentDetails?.bic,
    Iban: payload?.paymentDetails?.iban,
    AccountHolder: payload?.paymentDetails?.accountHolder,
    BankAccept: payload?.paymentDetails?.accept,
    RegistrationNumber: payload?.paymentDetails?.registrationNumber,
    PlantName: payload?.plantDetails?.plantName,
    PowerPlantType: payload?.plantDetails?.plantType,
    PowerPlantAddress: [
      payload?.plantDetails?.plantAddress?.postalCode +
        ' ' +
        payload?.plantDetails?.plantAddress?.city,
    ],
    InstalledPowerKw: formatDecimal(payload?.plantDetails?.installedPowerKw),
    // If "CommisioningDate" exist it will be added otherwise not
    ...(payload?.plantDetails?.commisioningDate &&
      payload?.plantDetails?.commisioningDate !== '1970-01-01' && {
        CommisioningDate: payload?.plantDetails?.commisioningDate,
      }),
    MarketLocation: payload?.plantDetails?.marketLocation,
    MeterNumber: payload?.plantDetails?.meterNumber,
    PlantKey: payload?.plantDetails?.plantKey,
    Operator: payload?.plantDetails?.netOperator,
    ContractType: payload?.contractType,
    ContractStatus: payload?.contractStatus,
    StartofDelivery: payload?.startOfDelivery,
    SalesTaxLiability: payload?.salesTaxLiability,
    Preference: payload?.preference,
    LastSupplier: payload?.plantDetails?.lastSupplier,
    LastCustomerNumber: payload?.plantDetails?.customerNumber,
    Stadtwerke: payload?.stadtWerkeKunden,
    Agb: payload?.agb,
    MarketingInformation: payload?.marketingInformation,
    nextPossibleDate: payload?.nextPossibleDate,
    registeredCourt: payload?.registeredCourt,
    registrationNumber: payload?.registrationNumber,
  }
  const renderKey = useCallback(
    (key) => {
      switch (key) {
        case 'EMail':
          return 'E-Mail'
        case 'ContractNumber':
          return t('LBLContractsNumber')
        case 'Addresse':
          return t('LBLAddress')
        case 'PhoneNumber':
          return t('LBLHandy')
        case 'DateOfBirth':
          return t('LBLBirthDate')
        case 'registeredCourt':
          return t('LBLRegisterCourt')
        case 'registrationNumber':
          return t('LBLRegistrationNumber')
        case 'BillingOwner':
          return t('LBLBillingAddressOwner')
        case 'BillingAddress':
          return t('LBLBillingAddress')
        case 'BankName':
          return t('LBLBankName')
        case 'Bic':
          return t('LBLBic')
        case 'Iban':
          return t('LBLIban')
        case 'AccountHolder':
          return t('LBLAccountHolder')
        case 'RegistrationNumber':
          return t('LBLRegistrationNumber')
        case 'PlantName':
          return t('LBLPlantName')
        case 'PowerPlantType':
          return t('LBLPlantType')
        case 'PowerPlantAddress':
          return t('LBLPlantAdress')
        case 'InstalledPowerKw':
          return t('LBLInstalledOutput')
        case 'CommisioningDate':
          return t('LBLCommissioningDate')
        case 'MarketLocation':
          return t('LBLMarketLocation')
        case 'MeterNumber':
          return t('LBLMeterNumber')
        case 'PlantKey':
          return t('LBLPlantKey')
        case 'Operator':
          return t('LBLOperator')
        case 'ContractType':
          return t('LBLContractType')
        case 'ContractStatus':
          return t('LBLContaractStatus')
        case 'StartofDelivery':
          return t('LBLStartOfDeliveryDate')
        case 'SalesTaxLiability':
          return t('LBLSalesTaxLiability')
        case 'Preference':
          return t('LBLPreference')
        case 'LastSupplier':
          return t('LBLLastSupplier')
        case 'LastCustomerNumber':
          return t('LBLLastCustomerNum')
        case 'Stadtwerke':
          return t('LBLStadtWerkeKunden')
        case 'BankAccept':
          return t('LBLAcceptCondition')
        case 'Agb':
          return t('LBLAgb')
        case 'MarketingInformation':
          return t('LBLMarketing')
        case 'nextPossibleDate':
          return t('LBLNextPossibleDate')
        default:
          return key
      }
    },
    [t]
  )

  return (
    <>
      {Object.entries(renderData).map(([key, value]) => (
        <Stack
          key={key}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          my={4}
        >
          {value ? (
            <>
              <Typography variant="body1">{renderKey(key)}</Typography>
              {typeof value === 'string' ? (
                <Typography variant="body1" align="right" id={key}>
                  {value}
                </Typography>
              ) : Array.isArray(value) ? (
                <Stack my={2}>
                  {value.map((v) => (
                    <Typography variant="body1" align="right" id={v}>
                      {v}
                    </Typography>
                  ))}
                </Stack>
              ) : typeof value === 'boolean' ? (
                <Typography variant="body1" align="right" id={key}>
                  {value ? 'True' : 'False'}
                </Typography>
              ) : null}
            </>
          ) : null}
        </Stack>
      ))}
    </>
  )
}
ConfirmUserDetails.propTypes = {
  payload: PropTypes.objectOf(PropTypes.any).isRequired,
  openFeedback: PropTypes.func.isRequired,
}

export default ConfirmUserDetails
